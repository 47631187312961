import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface SortListRequestParams {
  /** 排序号 */
  order_num: number
  /** 展示分类id */
  mat_show_class_id: number
}

@Injectable()
export class TreeClassifyService {
  constructor(private http: Http) {}

  deleteNode (id: number) {
    return this.http.post('/matshowclass/delete', { id })
  }

  updateTreeSort (ids: SortListRequestParams[]) {
    return this.http.post('/matshowclass/sort', { sort_list: ids })
  }

  updateStatus (params: {id: number, status: number}) {
    return this.http.post('/matshowclass/update', params)
  }
}
