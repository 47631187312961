
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive, ref } from 'vue'
import { NewSortItemDialogService, StatusType, DefaultType } from './newSortItemDialog.service'
import { ElMessage } from 'element-plus'

const clientList = Object.freeze([
  { label: 'web端', value: 'web' },
  { label: '客户端', value: 'duba' },
  { label: '侧边栏', value: 'ppt' },
  { label: '小程序', value: 'applets' }
])

const defaultList = Object.freeze([
  { label: '非默认', value: DefaultType.is_no_default },
  { label: '默认', value: DefaultType.is_default },
])

export default defineComponent({
  props: {
    newSortItemDialogVisible: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: null
    },

    sortData: {
      type: Object as any,
      default: null
    }
  },

  emits: ['clickNewSortItemDialogClose', 'clickNewSortItemDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([NewSortItemDialogService])
    const newSortItemDialogService = injector.get(NewSortItemDialogService)

    const formRef = ref(null)

    function tipFunc (msg?: string, type?: string) {
      ElMessage[type || 'warning'](msg || '请填写完整信息')
    }

    /** 表单句柄 */
    const dialogHandle = reactive({
      visible: props.newSortItemDialogVisible,
      loading: false,
      disable: false,
      formData: {
        name: '',
        product_code: '',
        is_default: DefaultType.is_default,
        status: StatusType.close
      },
      rules: {
        priority: [
          {required: true, message: '请输入排序id', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入排序名称', trigger: 'blur'},
        ],
        product_code: [
          {required: true, message: '请输入投放渠道', trigger: 'blur'},
        ]
      },
      close: () => {
        ctx.emit('clickNewSortItemDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            const params = {
              ...dialogHandle.formData,
              product_code: dialogHandle.formData.product_code,
              is_default: dialogHandle.formData.is_default,
              status: StatusType.close,
            }
            if (props.id) {
              const id = props.id as string
              newSortItemDialogService.updateSortMain({...params, id: id }).then(() => {
                ElMessage.success('更新成功!')
                ctx.emit('clickNewSortItemDialogSubmit')
              })
            } else {
              newSortItemDialogService.addSortMain(params).then(() => {
                ElMessage.success('新建成功!')
                ctx.emit('clickNewSortItemDialogSubmit')
              })
            }
          } else {
            tipFunc()
            return false
          }
        })
      }
    })

    if (props.id) {
      dialogHandle.formData = props.sortData
      dialogHandle.formData.product_code = props.sortData.product_code?.join('')
      console.log('e:', props.sortData.is_default)
      dialogHandle.formData.is_default = props.sortData.is_default
    }

    return {
      dialogHandle,
      clientList,
      defaultList,
      formRef
    }
  }
})

