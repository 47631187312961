import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import {strictExtend} from '@shared/utils/base';

export interface AdListSearchParams {
  /** 广告类型：0-全部，1-图文，2-文字，3-轮播 */
  ad_type?: number
  /** 产品形态：web: web端, client: 客户端, officeside: 侧边栏 */
  client_type?: string
  /** 广告位置	*/
  pos_code?: string
  /** 广告位名称 */
  ad_name?: string
  /** 页码 */
  page?: number
  /** 每页条数，最大100 */
  page_size?: number
}

export interface UpdateAdParams {
  /** id */
  id: number
  /** 广告类型：0-全部，1-图文，2-文字，3-轮播 */
  ad_type: number
  /** 产品形态	*/
  client_type: string
  /** 广告位置	*/
  pos_code: string
  /** 广告位名称 */
  ad_name: string
  /** 方案代号	*/
  plan: string
  /** 权重 */
  weight: number
  /** 状态：默认为禁用 */
  status: number
}

export interface AdDeleteParams {
  /** ID */
  id: number
}

export interface AdItem {
  /** ID */
  id: number,
  /** 广告类型：1-图文，2-文字，3-轮播 */
  ad_type: number,
  /** 产品形态	*/
  client_type: string,
  /** 广告位置	*/
  pos_code: string,
  /** 广告位名称 */
  ad_name: string,
  /** 方案代号，如A，B */
  plan: string
  /** 权重 */
  weight: number
  /** 可用状态：-1-禁用，1-可用 */
  status: number
  /** 创建时间	*/
  create_time: string
  /** 最后更新时间	*/
  update_time: string
}

export interface AdListResponseData {
  list: AdItem[],
  total: number
}

/** 广告类型 */
export enum AdTypeMap {
  image_text = 1,
  text = 2,
  carousel = 3
}

/** 广告类型对应的中文名 */
export const AdTypeByLabelMap = {
  [AdTypeMap.image_text]: '图文',
  [AdTypeMap.text]: '文字',
  [AdTypeMap.carousel]: '轮播'
}

/** 产品形态 */
export enum ClientTypeMap {
  web = 'web',
  client = 'client',
  officeside = 'officeside',
  liebao = 'liebao',
  pdf = 'pdf',
  applets = 'applets'
}

/** 产品形态对应的中文名 */
export const ClientTypeByLabelMap = {
  [ClientTypeMap.web]: 'web端',
  [ClientTypeMap.client]: '客户端',
  [ClientTypeMap.officeside]: '侧边栏',
  [ClientTypeMap.liebao]: '猎豹浏览器',
  [ClientTypeMap.pdf]: '可牛PDF',
  [ClientTypeMap.applets]: '小程序端'
}

/** 可用状态 */
export enum StatusMap {
  forbid = -1,
  allow = 1,
}

/** 可用对应的中文名 */
export const StatusByLabelMap = {
  [StatusMap.forbid]: '禁用',
  [StatusMap.allow]: '可用'
}

@Injectable()
export class AdvertisementService {
  adList: AdItem[] = []

  constructor(private http: Http) {}

  // 获取广告列表
  getAdList (params: AdListSearchParams) {
    return this.http.post<AdListResponseData>('/ad/list', params).then((response: AdListResponseData) => {
      this.adList = strictExtend(response.list) as AdItem[]

      return { list: response.list, total: response.total }
    })
  }

  // 更新广告
  updateAd (params: UpdateAdParams) {
    return this.http.post('/ad/update', params)
  }

  // 删除广告
  deleteAd (params: AdDeleteParams) {
    return this.http.post('/ad/delete', params)
  }
}
