
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent } from 'vue'
import { UserService } from './services/user.service'
import locale from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
  setup() {
    const injector = useReflectiveInjector()
    injector.get(UserService)
    return {
      locale
    }
  },
})
