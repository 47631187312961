
import { defineComponent, onUnmounted, reactive, defineAsyncComponent } from 'vue'
import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { MatsResponseData, MatsList, VerifyService } from './verify.service'
import { CheckStatusMap, ConstPagination } from '@/common/const'
import { formatDate, bytesToSize } from '@shared/utils/base/index'
import { Channel, ConstService } from '@/services/const.service'
import { downloadByUrlAndName } from '@/common/utils'
import { useRoute } from 'vue-router'
const VerifyDialog = defineAsyncComponent(() => import('./_components/verifyDialog/verifyDialog.vue'))

export default defineComponent({
  components: { MainLayout, TableLayout, VerifyDialog },

  setup() {
    const injector = useReflectiveInjector([VerifyService])
    const verifyService = injector.get(VerifyService)
    const constService = injector.get(ConstService)

    const route = useRoute()

    function getMatsList() {
      tableHandle.loading = true

      const params = {
        page: paginationHandle.page,
        size: paginationHandle.size,
        id: Number(searchHandle.id),
        designer_id: Number(searchHandle.designer_id),
        status_list: [route.name === 'first_instance' ? CheckStatusMap.first_instance : CheckStatusMap.second_instance],
        format_list: [] as string[]
      }

      if (searchHandle.format) {
        params.format_list = [searchHandle.format]
      }

      return verifyService.getVerifyMats(params).then((data: MatsResponseData) => {
        tableHandle.mats = data.list
        paginationHandle.total = data.total
      }).finally(() => {
        searchHandle.loading = false
        tableHandle.loading = false
      })
    }

    function getMatsListReset () {
      searchHandle.id = ''
      searchHandle.designer_id = ''
      searchHandle.format = ''
      searchHandle.loading = true
      getMatsList()
    }

    function getTagsArr(tags: string) {
      return tags?.split(',') || []
    }

    function formatSizeHandle(size: number) {
      if (!size) { return '' }
      const sizeObj = bytesToSize(size) as {unit: string, size: number}
      return String(sizeObj.size) + sizeObj.unit
    }

    function formatScene(scene: number[]) {
      return scene.map((it:number) => {
        const channelItem = channelsOptions.find((channel: Channel) => channel?.value === it)
        return channelItem?.label
      }).join(',')
    }

    function objectSpanMethod(scope: any) {
      if (scope.columnIndex < 8 || scope.columnIndex === 12) {
        if (scope.row.isMerge) {
          return { rowspan: scope.row.mergeNum, colspan: 1 }
        }
        return { rowspan: 0, colspan: 0 }
      }
    }

    function downLoadByUrl(row: any) {
      downloadByUrlAndName(row.file_url, `${row.title}.${String(row.format).toLocaleLowerCase()}`)
    }

    const searchHandle = reactive({
      id: '',
      designer_id: '',
      format: '',
      formatList: [],
      loading: false,
      value: '',
      search: () => {
        searchHandle.loading = true
        getMatsList()
      },
      reset: getMatsListReset
    })

    verifyService.getFormats().then(formats => {
      const formatList = formats.map(format => {
        return { label: format, value: format}
      })
      searchHandle.formatList = formatList
    })

    const tableHandle = reactive({
      mats: [],
      loading: false,
      matVerifyDialog: false,
      matVerifyNextDialog: false,
      matCur: null,
      indexCur: null,
      matNext: null,
      textNext: '',
      clickVerifyMatNext() {
        tableHandle.matVerifyNextDialog = false
        tableHandle.openMatVerify(tableHandle.matNext)
      },
      clickMatNextClose() {
        tableHandle.matVerifyNextDialog = false
      },
      clickVerify: (row: MatsList) => {
        tableHandle.openMatVerify(row)
      },
      openMatVerify (row: MatsList) {
        tableHandle.indexCur = tableHandle.mats.findIndex(e => e.id === row.id)
        tableHandle.matCur = row
        tableHandle.matVerifyDialog = true
      },
      handleSumbit: (status: CheckStatusMap) => {
        tableHandle.matVerifyDialog = false
        getMatsList().then(() => {
          const indexNext = tableHandle.indexCur
          tableHandle.matNext = tableHandle.mats[indexNext]
          if (status === CheckStatusMap.reject) {
            tableHandle.textNext = '驳回成功'
          } else if (status === CheckStatusMap.up || status === CheckStatusMap.second_instance) {
            tableHandle.textNext = '审核通过'
          }
          if (!tableHandle.matNext) {
            tableHandle.textNext += '，没下一条了'
          }
          tableHandle.matVerifyNextDialog = true
        })
      },
      handleClose: () => {
        tableHandle.matVerifyDialog = false
      }
    })

    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getMatsList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getMatsList()
      }
    })

    function sortByDesignerId (scope: any) {
      tableHandle.mats = verifyService.orderMats(scope.prop, scope.order)
    }

    function getFileTitle(fileName: string) {
      return fileName.substring(fileName.lastIndexOf('\/') + 1, fileName.length)
    }

    let channelsOptions: Channel[] = []
    const channelSub = constService.channelsData.subscribe(list => {
      channelsOptions = list
      list.length && getMatsList()
    })

    onUnmounted(() => { channelSub.unsubscribe() })

    return {
      CheckStatusMap,
      searchHandle,
      tableHandle,
      paginationHandle,

      formatDate,
      sortByDesignerId,
      getTagsArr,
      formatSizeHandle,
      formatScene,
      objectSpanMethod,
      downLoadByUrl,
      getFileTitle
    }
  },
})
