import { Injectable } from '@tanbo/di';
import { Http } from '@zhfe/common-library';

export interface ListRequestParams {
  /** 页容量，默认是10，最大为100 */
  page_size: number
  /** 起始页，默认是1 */
  page: number
}

export interface List {
  /** 配置id */
  id: number
  /** 配置key */
  key: string
  /** 配置备注 */
  remarks: string
  /** 创建时间 */
  create_time: string
  /** 更改时间 */
  update_time: string
  /** 配置项的启用状态,-1为关闭，1为启用，默认关闭 */
  status: number
  /** 配置value */
  value: string
}

export interface ListResponseData {
  list: List[]
  /** 配置总数 */
  total: number
}

export interface AddRequestParams {
  /** 配置项的key名称 */
  key: string
  /** 该配置的备注信息 */
  remarks: string
  /** 配置项的启用状态,-1为关闭，1为启用，默认关闭 */
  status: number
  /** 配置项的value值 */
  value: string
}

export interface UpdateRequestParams {
  /** 该配置的备注信息 */
  remarks: string
  /** 配置项的启用状态,-1为关闭，1为启用，默认关闭 */
  status: number
  /** 配置项的value值 */
  value: string
  /** 配置id */
  id: number
}

export interface BaseData extends AddRequestParams, UpdateRequestParams {
  valueObj: object
}

@Injectable()
export class ContingencyService {
  constructor(private http: Http) {}

  getList(data: ListRequestParams) {
    return this.http.post<ListResponseData>('/config/search', data)
  }

  addConfig(data: AddRequestParams) {
    return this.http.post<void>('/config/add', data)
  }

  updateConfig(data: UpdateRequestParams) {
    return this.http.post<void>('/config/update', data)
  }
}
