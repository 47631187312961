import { Inject, Injectable, InjectionToken } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpInterceptorService } from './http-interceptor.service';

export interface UserInfo {
  uid: number
  token: string
  username: string
}

export const UserInfoByLogin = new InjectionToken<UserInfo>('userInfo')

@Injectable()
export class UserService {
  userInfo = new BehaviorSubject<UserInfo>({ uid: null, token: null, username: '' })

  constructor (private http: Http, private httpInerceptor: HttpInterceptorService,
    @Inject(UserInfoByLogin) private userinfo: UserInfo) {
    this.updateUserinfo(userinfo)
  }

  updateUserinfo (userinfo: UserInfo) {
    this.httpInerceptor.addCommonRequestFields({
      uid: userinfo.uid + '',
      token: userinfo.token
    })

    this.userInfo.next(userinfo)
  }

  logout () {
    return this.http.axiosInstance.get('/oa/login_out', { params: {}}).then(() => {
      return Promise.resolve()
    })
  }
}
