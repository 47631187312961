
import { UserInfo, UserService } from '@/services/user.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { UserFilled } from '@element-plus/icons-vue'

export default defineComponent({
  setup() {
    const injector = useReflectiveInjector()
    const userService = injector.get(UserService)

    const userInfo = reactive<Partial<UserInfo>>({})

    const userSub = userService.userInfo.subscribe(info => {
      Object.assign(userInfo, info)
    })

    onUnmounted(() => { userSub.unsubscribe() })

    const router = useRouter()
    const clickLogout = () => {
      userService.logout().then(() => {
        location.reload()
      })
    }

    return {
      userInfo,
      UserFilled,
      clickLogout
    }
  },
})
