import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface ClassifyList {
  /** 分类id */
  classify_id: number
  /** 分类名称	*/
  classify_name: string
  /** 分类等级	*/
  classify_level: number
  /** 1级分类名称 */
  cat1_name: string
  /** 2级分类名称 */
  cat2_name: string
  /** 0 禁用 1启用 */
  status: number
  /** 排序值	*/
  order_num: number
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string
}

export interface ClassifySearchRequestParams {
  /**	起始页数 */
  page: number
  /** 页容量	*/
  page_size: number
  /** 分类id,搜索筛选项 */
  classify_id?: number
  /** 分类名,搜索筛选项 */
  classify_name?: string
  /** 上下线状态，0 禁用 1启用,搜索筛选项	*/
  status?: number
  /** 1级分类名称	*/
  cat1_name?: string
  /** 2级分类名称	*/
  cat2_name?: string
}

export interface ClassifyUpdateRequestParams {
  /** 分类id */
  classify_id?: number
  /** 分类名称 */
  classify_name?: string
  /** 上下线状态，0 禁用 1启用 */
  status?: number
  /** 分类级数 */
  classify_level?: number
  /** 一级分类值 */
  cat1?: number
  /** 二级分类值 */
  cat2?: number
  /** 排序数字	*/
  order_num?: number
}

export interface ClassifySearchResponseData {
  list: ClassifyList[],
  total: number
}

/** 上下线状态 0 禁用 1启用,搜索筛选项 */
export enum OperateTypeMap {
  forbid = 0,
  start = 1
}

/** 审核状态对应的中文名新版 */
export const OperateTypeByLabelMap = {
  [OperateTypeMap.forbid]: '已下线',
  [OperateTypeMap.start]: '使用中'
}

@Injectable()
export class ClassifyService {
  constructor(private http: Http) {}

  getClassifyList(params: ClassifySearchRequestParams) {
    return this.http.post<ClassifySearchResponseData>('/classify/search', params).then((response: ClassifySearchResponseData) => {
      return { list: response.list, total: response.total }
    })
  }

  deleteClassify(params: { classify_id: number}) {
    return this.http.post('/classify/delete', params)
  }

  updateClassify(params: ClassifyUpdateRequestParams) {
    return this.http.post('/classify/update', params)
  }
}
