
import { defineComponent, onUnmounted, reactive, watch, defineAsyncComponent, ref, computed } from 'vue'
import { UserInfo, UserService } from '@/services/user.service'
import { formatDate, bytesToSize } from '@shared/utils/base/index'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { Channel, ConstService, Tags, List } from '@/services/const.service'
import { VerifyDialogService } from './verifyDialog.service'
import { downloadByUrlAndName } from '@/common/utils'
import {
  CheckStatusMap,
  OperateResultByLabelMap,
  OperateResultMap,
  OperateTypeMap,
  OperateTypeByLabelMap
} from '@/common/const'
const VerifyLogs = defineAsyncComponent(() => import('@/components/verifyLogs/index.vue'))
export default defineComponent({
  components: { VerifyLogs },

  props: {
    mat: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['click-close', 'click-submit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([VerifyDialogService, UserService])
    const verifyDialogService = injector.get(VerifyDialogService)
    const userService = injector.get(UserService)
    const constService = injector.get(ConstService)

    const userInfo = reactive<Partial<UserInfo>>({})

    const userSub = userService.userInfo.subscribe((info) => {
      Object.assign(userInfo, info)
    })

    function getFileTitle(file: any) {
      const fileName = file.relative_file_path
      return fileName.substring(fileName.lastIndexOf('\/') + 1, fileName.length)
    }

    const modifyHandle = reactive({
      tags: [],
      class_id: []
    })

    function formatImgData (url: string) {
      return url.substring(url.lastIndexOf('\/') + 1, url.length)
    }

    const dialogHandle = reactive({
      formData: {
        mat_id: 0,
        operate_type: 0,
        operate_result: 0,
        operate_reason: '',
        status: 0,
      },
      checkData: {
        confirm_check_font: true,
        confirm_check_img: true,
        mat_source_remark: '',
        confirm_check_file: true
      },
      submit: () => {
        const audit = dialogHandle.formData
        const modifReq = {
          id: audit.mat_id,
          tag: modifyHandle.tags.join(','),
          class_id: modifyHandle.class_id[modifyHandle.class_id.length - 1],
          big_imgs: props.mat.big_imgs.map((e: string) => formatImgData(e)),
          preview_video: formatImgData(props.mat.preview_video)
        }
        // 审核通过
        if (audit.operate_result === OperateResultMap.pass) {
          // 当前为一审，设置审核状态为二审
          if (audit.operate_type === OperateTypeMap.first_instance) {
            audit.status = CheckStatusMap.second_instance
          } else if (audit.operate_type === OperateTypeMap.second_instance) {
            // 当前为二审，设置审核状态为上架
            audit.status = CheckStatusMap.up
          }
        } else {
          audit.status = CheckStatusMap.reject
        }
        verifyDialogService.modifyManageMat(modifReq)
          .then(() => {
            return verifyDialogService.auditMat(audit)
          })
          .then(() => {
              ctx.emit('click-submit', audit.status)
          })
      },
      close: () => {
        ctx.emit('click-close')
      },
    })

    const imgList = computed(() => {
      if (props.mat.big_img) {
        return [props.mat.big_img]
      }
      if (props.mat.big_imgs) {
        return props.mat.big_imgs
      }
      return ''
    })

    watch(
      () => props.mat,
      async (mat) => {
        dialogHandle.formData = {
          mat_id: mat.id,
          operate_type:
            mat.status === CheckStatusMap.second_instance
              ? OperateTypeMap.second_instance
              : OperateTypeMap.first_instance,
          operate_result: OperateResultMap.pass,
          operate_reason: '',
          status: 0,
        }
        const checkData = await verifyDialogService.getSelfCheckLog(mat.id)
        dialogHandle.checkData.mat_source_remark = checkData?.log?.mat_source_remark || ''
        modifyHandle.tags = mat?.tag?.split(',') || []
        modifyHandle.class_id = [mat.class_1, mat.class_2, mat.class_3]
      }
    )

    let channelsOptions: Channel[] = []
    const channelSub = constService.channelsData.subscribe((list) => {
      channelsOptions = list
    })

    const tagsOptions = ref<Tags[]>([])
    const tagSub = constService.tagsData.subscribe(tags => {
      tagsOptions.value = tags
    })

    const classifyOptions = ref<List[]>([])
    const classifySub = constService.classifyData.subscribe(list => {
      classifyOptions.value = list
    })

    onUnmounted(() => {
      channelSub.unsubscribe()
      userSub.unsubscribe()
      tagSub.unsubscribe()
      classifySub.unsubscribe()
    })

    return {
      modifyHandle,
      classifyOptions,
      tagsOptions,
      userInfo,
      OperateResultMap,
      dialogHandle,
      formatDate,
      getTagsArr(tags: string) {
        return tags?.split(',') || []
      },
      getAuditPassText(operateType: OperateTypeMap) {
        return operateType === OperateTypeMap.second_instance
          ? '通过审核并发布'
          : '通过审核'
      },
      getStringAuditOperateType(operateType: OperateTypeMap) {
        return OperateTypeByLabelMap[operateType] || '未知'
      },
      getStringAuditOperateResult(operateResult: OperateResultMap) {
        return OperateResultByLabelMap[operateResult] || '未知'
      },
      formatSizeHandle(size: number) {
        if (!size) {
          return ''
        }
        const sizeObj = bytesToSize(size) as { unit: string; size: number }
        return String(sizeObj.size) + sizeObj.unit
      },
      formatScene(scene: number[]) {
        return scene
          .map((it: number) => {
            const channelItem = channelsOptions.find(
              (channel: Channel) => channel?.value === it
            )
            return channelItem?.label
          })
          .join(',')
      },
      downLoadByUrl(file: any) {
        downloadByUrlAndName(
          file.file_url,
          getFileTitle(file)
        )
      },
      getFileTitle,
      removeHtmlStyle(html: string) {
        const rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/
        let newHtml = ''
        if (html) {
          newHtml = html.replace(rel, '')
        }
        return newHtml
      },
      imgList
    }
  }
})
