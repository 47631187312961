/** 常量 */

/** elementUI 常量 */
export const ElementUIConst = {
  paginationSizes: [20, 30, 50, 100],
  paginationLayout: 'total, sizes, prev, pager, next, jumper',
  inputMaxLen: 2
}

/** 分页的常量数据 */
export const ConstPagination = {
  defaultPageSize: 20
}

/** 上传文件类型，传给后端的type */
export enum UploadFileType {
  img = 1,
  file = 2,
  video = 3
}

/** 审核类型 */
export enum CheckStatusMap {
  delete = -1,
  down = 0,
  up = 1,
  first_instance = 2,
  reject = 3,
  second_instance = 4
}

/** 审核类型对应的中文名 */
export const CheckStatusByLabelMap = {
  [CheckStatusMap.delete]: '已删除',
  [CheckStatusMap.down]: '已下架',
  [CheckStatusMap.up]: '已上架',
  [CheckStatusMap.first_instance]: '一审',
  [CheckStatusMap.reject]: '已驳回',
  [CheckStatusMap.second_instance]: '二审'
}

/** 审核类型对应的类型 */
export const CheckStatusByTypeMap = {
  [CheckStatusMap.delete]: 'danger',
  [CheckStatusMap.down]: 'danger',
  [CheckStatusMap.up]: 'success',
  [CheckStatusMap.first_instance]: 'primary',
  [CheckStatusMap.reject]: 'danger',
  [CheckStatusMap.second_instance]: 'primary',
}

/** 审核状态 */
export enum OperateTypeMap {
  first_instance = 1,
  second_instance = 2,
  retry = 11
}

/** 审核状态对应的中文名新版 */
export const OperateTypeByLabelMap = {
  [OperateTypeMap.first_instance]: '一审',
  [OperateTypeMap.second_instance]: '二审',
  [OperateTypeMap.retry]: '重新上传',
}

/** 审核结果状态 */
export enum OperateResultMap {
  pass = 1,
  reject = 2,
  retry = 3
}

/** 审核结果对应的中文名 */
export const OperateResultByLabelMap = {
  [OperateResultMap.pass]: '审核通过',
  [OperateResultMap.reject]: '驳回',
  [OperateResultMap.retry]: '重新上传',
}

/** 付费类型 */
export enum PayTypeMap {
  pay = 1,
  free = 0
}

/** 付费类型对应的中文名称 */
export const PayTypeByLabelMap = {
  [PayTypeMap.pay]: '付费',
  [PayTypeMap.free]: '免费'
}

/** 客户端类型 */
export enum ClientTypeMap {
  web = 1,
  duba = 2,
  ppt = 3,
  liebao = 4,
  pdf = 5,
  applets = 6,
}

/** 客户端类型对应的中文名称 */
export const ClientTypeByLabelMap = {
  [ClientTypeMap.web]: 'Web端',
  [ClientTypeMap.duba]: '客户端',
  [ClientTypeMap.ppt]: '侧边栏',
  [ClientTypeMap.liebao]: '猎豹浏览器',
  [ClientTypeMap.pdf]: '可牛PDF',
  [ClientTypeMap.applets]: '小程序端',
}

export enum ActivityTemplateTypeMap {
  wxAppletsTopic = '专题打包零售',
  webTopic = 'web专题页面',
  webPay = 'web支付活动页面'
}

/** 活动模板 */
export const ActivityTemplateTypeByLabelMap = {
  [ActivityTemplateTypeMap.wxAppletsTopic]: '专题打包零售',
  [ActivityTemplateTypeMap.webTopic]: 'web专题页面',
  [ActivityTemplateTypeMap.webPay]: 'web支付活动页面'
}
