
import imgUploader from '@/components/uploader/imgUploader/index.vue'
import fileUploader from '@/components/uploader/fileUploader/index.vue'
import puzzleImgUploader from '@/components/uploader/puzzleImgUploader/index.vue'
import videoUploader from '@/components/uploader/videoUploader/videoUploader.vue'
import verifyLogs from '@/components/verifyLogs/index.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import {defineAsyncComponent, defineComponent, onUnmounted, reactive, ref} from 'vue'
import { ContentManageDialogService } from '@/views/manage/_components/contentManageDialog/contentManageDialog.service';
import { Channel, ConstService, Tags, PayTypes, List } from '@/services/const.service';
import { UploadReturnInfo } from '@/components/uploader/uploader.service';
import { ElMessage } from 'element-plus';
import { bytesToSize } from '@shared/utils/base';
import { PayTypeByLabelMap, PayTypeMap } from '@/common/const';

export default defineComponent({
  components: { imgUploader, fileUploader, videoUploader, verifyLogs, puzzleImgUploader },

  props: {
    contentManageDialogVisible: {
      type: Boolean,
      default: false
    },

    id: {
      type: Number,
      default: null
    }
  },

  emits: ['clickContentManageDialogClose', 'clickContentManageDialogModify'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([ContentManageDialogService])
    const contentManageDialogService = injector.get(ContentManageDialogService)
    const constService = injector.get(ConstService)

    const formRef = ref(null)

    const getMatFiles = () => {
      return {
        id: Math.random(),
        format: '',
        size: 0,
        page_count: 1,
        file_url: '',
        isShow: true
      }
    }
    let sceneDefault: number[] = []

    const dialogHandle = reactive({
      isEditedBigFile: false,
      visible: props.contentManageDialogVisible,
      loading: false,
      disable: false,
      formData: {
        title: '',
        tag: [],
        pay_type: '',
        class_id: [], // 预期是最后一个id
        status: '',
        scene: [],
        small_img: '',
        big_img: '',
        big_imgs: [],
        mat_files: [getMatFiles()],
        big_img_layout: '',
        big_img_type: '',
        preview_video: '',
        is_delete_preview_video: false,
      },
      checkData: {
        confirm_check_font: true,
        confirm_check_img: true,
        mat_source_remark: '',
        confirm_check_file: true
      },
      rules: {
        title: [
          { required: true, message: '请输入模板标题', trigger: 'blur' },
          { min: 1, max: 25, message: '请勿超过25个字', trigger: 'blur' }
        ]
      },
      pushItem: () => {
        dialogHandle.formData.mat_files.push(getMatFiles())
      },
      delItem: (index: number) => {
        dialogHandle.formData.mat_files.splice(index, 1)
      },
      handleSmallFileInfo: (info: UploadReturnInfo) => {
        dialogHandle.formData.small_img = info.name
      },
      handleBigFileInfo: (info: UploadReturnInfo | UploadReturnInfo[]) => {
        if (!dialogHandle.isEditedBigFile) {
          dialogHandle.isEditedBigFile = true
          dialogHandle.formData.big_imgs = []
          dialogHandle.formData.big_img = ''
        }
        if (dialogHandle.formData.big_img_type === '1') {
          if (Array.isArray(info)) {
            const l = info.map(e => e.name)
            dialogHandle.formData.big_imgs = l
          } else {
            dialogHandle.formData.big_imgs = [info.name]
          }
        } else if (dialogHandle.formData.big_img_type === '2') {
          if (Array.isArray(info)) {
            const l = info.map(e => e.name)
            dialogHandle.formData.big_imgs.push(...l)
          } else {
            dialogHandle.formData.big_imgs.push(info.name)
          }
        }
      },
      handleFileInfo: (info: any, index: number) => {
        dialogHandle.formData.mat_files[index].file_url = info.name
        dialogHandle.formData.mat_files[index].size = info.size
        dialogHandle.formData.mat_files[index].isShow = false
      },
      handleVideoInfo: (info: any) => {
        dialogHandle.formData.preview_video = info.name
        dialogHandle.formData.is_delete_preview_video = false
      },
      handleVideoDelete () {
        dialogHandle.formData.is_delete_preview_video = true
      },
      close: () => {
        ctx.emit('clickContentManageDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            const needTip = dialogHandle.formData.mat_files.some(item => !item.format || !item.file_url)
            if (needTip) {
              tipFunc()
              return
            }
            dialogHandle.loading = true
            contentManageDialogService.modifyManageMat({id: props.id as number, ...dialogHandle.formData})
            .then(() => {
              dialogHandle.visible = false
              ctx.emit('clickContentManageDialogModify')
            }).finally(() => { dialogHandle.loading = false })
          } else {
            tipFunc()
            return false
          }
        })
      }
    })

    contentManageDialogService.getDataById(props.id as number).then(data => {
      dialogHandle.formData = data as any
    })

    contentManageDialogService.getSelfCheckLog(props.id as number).then(res => {
      dialogHandle.checkData.mat_source_remark = res?.log?.mat_source_remark || ''
    })

    const tagsOptions = ref<Tags[]>([])
    const tagSub = constService.tagsData.subscribe(tags => {
      tagsOptions.value = tags
    })

    const classifyOptions = ref<List[]>([])
    const classifySub = constService.classifyData.subscribe(list => {
      classifyOptions.value = list
    })

    const imageLayoutOptions = Object.freeze([{ label: '横版', value: '1' }, { label: '纵版', value: '2' }])

    const imageTypeOptions = Object.freeze([{ label: '拼图上传', value: '1' }, { label: '多图上传', value: '2'}])

    const channelsOptions = ref<Channel[]>([])
    const channelSub = constService.channelsData.subscribe(list => {
      channelsOptions.value = list
      sceneDefault = list.map((channel) => channel.value)
      dialogHandle.formData.scene = Object.assign([], sceneDefault)
    })

    const formatsOptions = ref<string[]>([])
    const formatsSub = constService.formatsData.subscribe(list => {
      formatsOptions.value = list
    })

    const payTypesOptions = ref<PayTypes[]>([{label: PayTypeByLabelMap[PayTypeMap.pay], value: String(PayTypeMap.pay)},
     {label: PayTypeByLabelMap[PayTypeMap.free], value: String(PayTypeMap.free)}])

    onUnmounted(() => {
      tagSub.unsubscribe()
      classifySub.unsubscribe()
      channelSub.unsubscribe()
      formatsSub.unsubscribe()
    })

    function tipFunc (msg?: string, type?: string) {
      ElMessage[type || 'warning'](msg || '请填写完整信息')
    }

    return {
      formRef,
      dialogHandle,
      tagsOptions,
      channelsOptions,
      classifyOptions,
      formatsOptions,
      imageLayoutOptions,
      imageTypeOptions,
      payTypesOptions,
      PayTypeByLabelMap,

      formatSizeHandle(size: number) {
        const sizeObj = bytesToSize(size) as {unit: string, size: number}
        return String(sizeObj.size) + sizeObj.unit
      },
      removeHtmlStyle(html: string) {
        const rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/
        let newHtml = ''
        if (html) {
          newHtml = html.replace(rel, '')
        }
        return newHtml
      }
    }
  }
})

