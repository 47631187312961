import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';

export interface AuditMatRequestParams {
  /** 模板id */
  mat_id: number
  /** 需要转变的状态 0下架 1上架 2一审 3驳回 4二审 */
  status: number
  /** 当前状态 1一审 2二审 */
  operate_type: number
  /** 审核理由 */
  operate_reason: string
  /** 审核结果 	1审核通过2审核不通过3重新上传 */
  operate_result: number
}

export interface ModifyMatRequestParams {
  id: number
  tag: string,
  class_id: number
}

@Injectable()
export class VerifyDialogService {
  constructor(private http: Http) {}

  modifyManageMat(params: ModifyMatRequestParams) {
    return this.http.post('/manager/modify', params)
  }

  auditMat (params: AuditMatRequestParams) {
    return this.http.post('/mat/audit', params)
  }

  getSelfCheckLog (id: number) {
    return this.http.post('/selfcheck', { mat_id: id })
  }
}
