import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface RequestActivityAdd {
  /** 活动名称 */
  name: string
  /** 产品形态 */
  client_type: string
  /** 活动标识 */
  code: string
  /** 活动模板 */
  template: string
  /** 开始时间，格式：2022-02-10 11:11:34 */
  start_time: string
  /** 结束时间，格式：2022-02-10 11:11:34 */
  end_time: string
  /** 状态：默认为禁用 */
  status: number
}

export interface RequestActivityEdit {
  /** 活动名称 */
  name: string
  /** 产品形态 */
  client_type: string
  /** 活动标识 */
  code: string
  /** 活动模板 */
  template: string
  /** 开始时间，格式：2022-02-10 11:11:34 */
  start_time: string
  /** 结束时间，格式：2022-02-10 11:11:34 */
  end_time: string
  /** 状态：默认为禁用 */
  status: number
  id?: number
}

@Injectable()
export class ActivityAddService {
  constructor (private http: Http) {}

  activityAdd (data: RequestActivityAdd) {
    return this.http.post<void>('/activity/add', data)
  }

  activityEdit (data: RequestActivityEdit) {
    return this.http.post<void>('/activity/update', data)
  }
}
