
import { defineComponent, toRefs } from 'vue'
import { UploadReturnInfo } from '../uploader.service'
import uploader from './uploader.vue'

export default defineComponent({
  components: { uploader },

  props: {
    fileData: {
      type: Object,
      default: null
    },

    showImgList: {
      type: Array,
      default: (): string[] => []
    },

    imgType: {
      type: String,
      default: ''
    }
  },

  emits: ['fileInfo'],

  setup(props, ctx) {
    const propData = toRefs(props)
    function fileInfo(info: UploadReturnInfo) {
      ctx.emit('fileInfo', info)
    }

    return {
      defaultImgList: propData.showImgList,

      fileInfo
    }
  }
})
