import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fb56660"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common_img_uploader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uploader = _resolveComponent("uploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_uploader, {
      "default-img": _ctx.defaultImg,
      "show-img-list": _ctx.showImgList,
      multiple: _ctx.multiple,
      onFileInfo: _ctx.fileInfo
    }, null, 8, ["default-img", "show-img-list", "multiple", "onFileInfo"])
  ]))
}