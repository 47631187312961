import { ResponseChannelData } from '@/services/const.service';
import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
// import { ManageService } from '@/views/manage/manage.service';
import { API_URL } from '@/common/config';

export interface MatFiles {
  /** 格式 */
  format: string
  /** 模板大小 单位B */
  size: number
  /** 页数 */
  page_count: number
  /** 文件名 */
  file_url: string
}

export interface ModifyMatRequestParams {
  /** 主键 */
  id: number
  /** 标题 */
  title: string
  /** 子分类id */
  sub_category_id: number
  tag_ids: string
  keywords: string
  payment_type: number
  price: number
  source: string
  tag_names?: string
}
export interface PageImages {
  /** 图片url */
  image_url: string
  /** 顺序 */
  priority: number
}
export interface DetailData {
  /** id */
  id: number
  /** 标题 */
  title: string
  /** 一级分类id */
  category_id: number
  /** 二级分类id */
  sub_category_id: number
  /** 标签id */
  tag_ids: string
  /** 标签名称 */
  tag_names: string
  /** 关键词 */
  keywords: string
  /** 分辨率 */
  resolution: string
  /** 页数 */
  page_number: string
  /** 付费类型，1-付费，0-免费 */
  payment_type: number
  /** 价格(元) */
  price: number
  /** 供应商来源 */
  source: string
  /** 状态 */
  status: number
  /** 封面图url */
  cover_image_url: string
  /** 页面截图 */
  page_images: PageImages[]
  create_time?: string
  /** 更新时间 */
  update_time?: string
}

@Injectable()
export class ContentManageDialogService {
  constructor(private http: Http) {}

  getDataById(id: number) {
    return this.http.post<DetailData>(`/officeplus/mat/detail`, {id})
  }

  modifyManageMat(params: ModifyMatRequestParams) {
    const modifyData = this.formatInsertData(params)
    return this.http.post('/officeplus/mat/update-base-info', modifyData)
  }

  getDownloadUrl(params: { id: number }) {
    return this.http.post<{ url: string }>('/officeplus/mat/get-download-url', params)
  }

  formatInsertData (data: any) {
    return {
      id: data.id,
      title: data.title,
      sub_category_id: data.sub_category_id,
      keywords: data.keywords,
      payment_type: data.payment_type,
      price: Number(data.price || 0),
      tag_ids: data.tag_ids,
      tag_names: data.tag_names,
    }
  }

  formatImgData (url: string) {
    return url.substring(url.lastIndexOf('\/') + 1, url.length)
  }
}
