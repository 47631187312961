import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { strictExtend } from '@shared/utils/base/index.js';

export interface MatFiles {
  /** 格式 **/
  format: string
  /** 大小 **/
  size: number
  /** 页数 **/
  page_count: number
  /** 文件链接 **/
  file_url: string
}
export interface PageImages {
  image_url?: string
}
export interface OfficePlusListItem {
  /** 模板id */
  id: string
  /** 标题 */
  title: string
  /** 标签id */
  tag_ids: number[]
  /** 标签名称 */
  tag_names: number[]
  /** 关键词 */
  keywords: string
  /** 分辨率 */
  resolution: string
  /** 页数 */
  page_number: string
  payment_type: number
  price: number
  source: string
  status: OfficePlusStatus
  cover_image_url: string
  page_images: PageImages
  create_time: string
  /** 更新时间 */
  update_time: string
}

/** officePlus状态 */
export enum OfficePlusStatus {
  /** 待制作 */
  PENDING_PRODUCTION = 11,
  /** 制作中 */
  IN_PRODUCTION = 12,
  /** 待确认 */
  PENDING_CONFIRMATION = 21,
  /** 待同步 */
  PENDING_SYNC = 30,
  /** 同步中 */
  SYNCING = 31,
  /** 同步成功 */
  SYNC_SUCCESS = 32,
  /** 同步失败 */
  SYNC_FAILED = 33
}

/** officePlus状态对应的中文名 */
export const OfficePlusStatusLabelMap = {
  [OfficePlusStatus.PENDING_PRODUCTION]: '待制作',
  [OfficePlusStatus.IN_PRODUCTION]: '制作中',
  [OfficePlusStatus.PENDING_CONFIRMATION]: '待确认',
  [OfficePlusStatus.PENDING_SYNC]: '待同步',
  [OfficePlusStatus.SYNCING]: '同步中',
  [OfficePlusStatus.SYNC_SUCCESS]: '同步成功',
  [OfficePlusStatus.SYNC_FAILED]: '同步失败'
}

export interface MatsRequestParams {
  /** 每页条数 */
  page: number
  /** 页码 */
  size: number
  /** 模板id，精确匹配 */
  id?: number
  /** 状态：11-待制作，12-制作中，21-待确认，30-待同步，31-同步中，32-同步成功，33-同步失败 */
  status?: number
  /** 标题，用于模糊搜索 */
  title?: string
  /** 分类id：1–PPT，2–WORD，3–EXCEL */
  category_id?: string
}

export interface ModifyMatRequestParams {
  /** 模板id */
  id: number
  /** 标题 */
  title?: string
  /** 标签 */
  tag?: string
  /** 支付类型 0：免费 1：付费 */
  pay_type?: string
  /** 模板状态 0：已下架 1：已上架 2：待审核 3：已驳回 */
  status?: string
  /** 场景数据 1：web 2：duba 3：ppt 4：liebao 5：pdf */
  scene?: number[]
  /** 小图文件名 */
  small_img?: string
  /** 大图文件名 */
  big_img?: string
  /** 多个格式 */
  max_files?: MatFiles[]
}

export interface MatsManageResponseData {
  list: OfficePlusListItem[],
  total: number
}

@Injectable()
export class OfficeplusService {
  matList: OfficePlusListItem[] = []

  constructor(private http: Http) {}

  getList(params: MatsRequestParams) {
    return this.http.post<MatsManageResponseData>('/officeplus/mat/list', params).then((response: MatsManageResponseData) => {
      const list = response.list.map(item => {
        item.tag_ids = item.tag_ids ? (item.tag_ids as any).split(',') : []
        item.tag_names = item.tag_names ? (item.tag_names as any).split(',') : []
        return item
      })
      this.matList = strictExtend(list) as OfficePlusListItem[]

      return { list: list, total: response.total }
    })
  }

  sysncStatus(params: { ids: number[] }) {
    return this.http.post('/officeplus/mat/confirm', params)
  }
}
