
import { defineComponent, reactive, onMounted } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ConstPagination } from '@/common/const'
import { formatDate } from '@shared/utils/base'
import { OrderService, OrderResponseData, CheckStatusByLabelMap } from '@/views/order/order.service'

const statusList = Object.freeze([
  { label: '全部', value: 0 },
  { label: '未支付', value: 1 },
  { label: '已支付', value: 2 },
  { label: '已退款', value: 3 }
])

export default defineComponent({
  components: { MainLayout, TableLayout },

  setup() {
    const injector = useReflectiveInjector([OrderService])
    const orderService = injector.get(OrderService)

    // 获取订单列表
    function getOrderList() {
      tableHandle.loading = true

      const params = {
        s_order_id: searchHandle.s_order_id,
        s_uid: searchHandle.s_uid ? Number(searchHandle.s_uid) : null,
        s_order_status: searchHandle.s_order_status,
        page: paginationHandle.page,
        page_size: paginationHandle.size
      }
      orderService.getOrderList(params).then((data: OrderResponseData) => {
        tableHandle.list = data.list
        paginationHandle.total = data.total
      }).finally(() => {
        searchHandle.loading = false
        tableHandle.loading = false
      })
    }

    // 退款
    function updateRefund(id: number) {
      orderService.updateRefundOrder({order_id: id}).then(() => {
        getOrderList()
      })
    }

    // 更新订单
    function updateDetail(id: number) {
      orderService.fetchOrderDetail({order_id: id}).then((result) => {
        orderDetailDialog.orderData = result.data
      })
    }

    // 搜索句柄
    const searchHandle = reactive({
      loading: false,
      s_order_id: '',
      s_uid: '',
      s_order_status: 0,
      statusList: statusList,
      search: () => {
        searchHandle.loading = true
        getOrderList()
      },
      reset: () => {
        searchHandle.loading = true
        searchHandle.s_order_id = ''
        searchHandle.s_uid = ''
        searchHandle.s_order_status = 0
        getOrderList()
      }
    })

    // 表单句柄
    const tableHandle = reactive({
      list: [],
      loading: false,
      configDetail: (id: number) => {
        orderDetailDialog.visible = true
        updateDetail(id)
      },
      refund: (id: number) => {
        updateRefund(id)
      }
    })

    // 分页句柄
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getOrderList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getOrderList()
      }
    })

    // 订单详情表单
    const orderDetailDialog = reactive({
      visible: false,
      orderData: {
        order_id: '',
        summary: '',
        money: '',
        uid: '',
        status: '',
        create_time: '',
        update_time: ''
      },
      clickOrderDetailDialogClose: () => {
        orderDetailDialog.visible = false
      }
    })

    onMounted(() => { getOrderList() })

    return {
      searchHandle,
      tableHandle,
      paginationHandle,
      orderDetailDialog,

      formatDate,
      CheckStatusByLabelMap
    }
  }
})
