import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { fileToMd5 } from './uploadHelper'
import { API_URL } from '@/common/config';

export interface UploadParams {
  /** 内容md5 */
  content_md5: string
  /** 文件名 */
  file_name: string
  /** 上传文件类型，1:图片类型 2.文件类型 3.预览视频 */
  type: number
}

export interface UploadResponseData {
  /** 上传链接 */
  upload_url: string,
  /** 文件名 */
  file_name: string
}

export interface UploadReturnInfo {
  /** 上传名称 */
  name: string,
  /** 文件大小 */
  size: number
}

@Injectable()
export class Uploader {
  constructor (private http: Http) {}

  async uploadFileResultFileName (options: any, type: number) {
    try {
      const { md5, bytes } = await fileToMd5(options.file ? options.file : options)
      const { upload_url, file_name } = await this.getUrlByH5uploadPromise({ content_md5: md5, file_name: options.file ? options.file.name : options.name, type: type })

      // 上传文件
      await this.uploadFile(upload_url, md5, bytes)

      return { name: file_name, size: options.file ? options.file.size : options.size }
    } catch (e) {
      console.log(e)
    }
  }

  getUrlByH5uploadPromise (data: UploadParams) {
    return this.http.post<UploadResponseData>(`${API_URL}/content/getUploadUrl`, data)
  }

  uploadFile (url: string, hmacDigest: string, bytes: any) {
    /** http://apix.kisops.com/project/1065/interface/api/43836 */
    const headers = {
      'Content-MD5': hmacDigest,
      'Content-Type': ''
    }
    return this.http.axiosInstance.put(url, bytes, { headers })
  }
}
