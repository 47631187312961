// @ts-nocheck
import Ajax from '@shared/utils/ajax'
import { b64Md5 } from './md5.js'

/**
 * 文件转 MD5 文本
 * @param {File} file 文件
 */
export function fileToMd5 (file) {
  // eslint-disable-next-line consistent-return
  return new Promise<{
    md5: string,
    bytes: any[]
  }>(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const content = reader.result
      const bytes = new Uint8Array(content.length)

      for (let i = 0; i < content.length; i++) {
        bytes[i] = content.charCodeAt(i)
      }

      resolve({
        md5: b64Md5(content) as string,
        bytes
      })
    }
    reader.readAsBinaryString(file)
  })
}
