
import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

import { useRouter } from 'vue-router'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { SortService, ProductCodeMap, CheckStatusByLabelMap, CheckStatusMap } from '@/views/sort/mat/sort.service'
import { ConfigService } from '@/views/sort/mat/sort-config/sort-config.service'
import { ConstPagination } from '@/common/const'
import { formatDate } from '@shared/utils/base'
import { ElMessage, ElMessageBox } from 'element-plus'

const NewSortItemDialog = defineAsyncComponent(() => import('./_components/newSortItemDialog/newSortItemDialog.vue'))

export default defineComponent({
  components: { MainLayout, TableLayout, NewSortItemDialog },

  setup() {
    // todo: 总结 di inject root 重新 创建的问题
    const injector = useReflectiveInjector([SortService])
    const sortService = injector.get(SortService)
    const router = useRouter()

    const toSortConfig = function (id: number) {
      router.push({
        name: 'sort-mat-config',
        query: {
          id: id
        }
      })
    }

    function getSortList() {
      tableHandle.loading = true
      const priorityNum = Number(searchHandle.priority)
      const params = {
        name: searchHandle.name,
        priority: searchHandle.priority === '' ? null : isNaN(priorityNum) ? null : priorityNum
      }
      sortService.getSortItem(params).then((res) => {
        tableHandle.list = res.list
        paginationHandle.total = res.total
      }).finally(() => {
        tableHandle.loading = false
        searchHandle.loading = false
      })
    }

    function getSortDataById(id: number) {
      const updateItem = tableHandle.list.find(item => item.id === id)
      return {
        name: updateItem.name,
        priority: updateItem.priority,
        status: updateItem.status,
        product_code: updateItem.product_code.split(',')
      }
    }

    /** 搜索句柄 */
    const searchHandle = reactive({
      loading: false,
      priority: '',
      name: '',
      search: () => {
        searchHandle.loading = true
        getSortList()
      },
      reset: () => {
        searchHandle.name = ''
        searchHandle.priority = ''
        searchHandle.search()
      },
      refresh: () => {
        Promise.all([sortService.rankingMat(), sortService.rankingMatCalculate()]).then(() => {
          ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        })
      }
    })

    /** 表单句柄 */
    const tableHandle = reactive({
      loading: false,
      list: [],
      id: null,
      updateItem: (row: any) => {
        newSortItemHandle.visible = true
        newSortItemHandle.id = row.id
        newSortItemHandle.sortData = getSortDataById(row.id as number)
      },
      configItem: (row: any) => {
        toSortConfig(row.id)
      },
      refreshItem: async (row: any) => {
        const sortListRes = await sortService.queryCatSortList({ custom_mat_sort_id: row.id })
        const cats = sortListRes.list.map(item => item.cat)
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        Promise.all(cats.map(cat => {
          return  sortService.cdnAndCacheRefresh({
            product: row.product_code,
            classId: String(cat)
          })
        }))
      },
      deleteItem: (row: any) => {
        const params = {
          id: row.id
        }
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            sortService.deleteSortItem(params).then(() => {
              ElMessage.success('删除成功!')
              getSortList()
            })
          }).catch((err: any) => {
          if (err === 'cancel') {
            console.log(err)
          }
        })
      },
      onStatus: (row: any) => {
        sortService.updateSortItem({ ...row, status: 1 }).then(() => {
          ElMessage.success('启用成功')
          getSortList()
        })
      },
      offStatus: (row: any) => {
        sortService.updateSortItem({ ...row, status: -1 }).then(() => {
          ElMessage.success('禁用成功')
          getSortList()
        })
      }
    })

    /** 新建排序句柄 */
    const newSortItemHandle = reactive({
      id: null,
      visible: false,
      sortData: null,
      newSortItem: () => {
        newSortItemHandle.visible = true
      },
      clickNewSortItemDialogClose: () => {
        newSortItemHandle.visible = false
        newSortItemHandle.id = null
        newSortItemHandle.sortData = null
      },
      clickNewSortItemDialogSubmit: () => {
        newSortItemHandle.clickNewSortItemDialogClose()
        getSortList()
      }
    })

    /** 分页句柄 */
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getSortList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getSortList()
      }
    })

    onMounted(() => { getSortList() })

    return {
      searchHandle,
      tableHandle,
      newSortItemHandle,
      paginationHandle,

      formatDate,
      ProductCodeMap,
      CheckStatusByLabelMap,
      CheckStatusMap
    }
  }
})
