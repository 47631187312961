
import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ConstPagination } from '@/common/const'
import { ClassifyService, OperateTypeByLabelMap, OperateTypeMap } from '@/views/classify/admin/admin.service'
import { formatDate } from '@shared/utils/base'
import { ElMessage, ElMessageBox } from 'element-plus'

const ClassifyItemDialog = defineAsyncComponent(() => import('./_components/classifyItemDialog/classifyItemDialog.vue'))

const statusList = Object.freeze([
  { label: '全部', value: 2 },
  { label: OperateTypeByLabelMap[OperateTypeMap.forbid], value: OperateTypeMap.forbid },
  { label: OperateTypeByLabelMap[OperateTypeMap.start], value: OperateTypeMap.start }
])

const levelList = Object.freeze([
  { label: '全部', value: 0 },
  { label: '一级', value: 1 },
  { label: '二级', value: 2 },
  { label: '三级', value: 3 }
])

export default defineComponent({
  components: { MainLayout, TableLayout, ClassifyItemDialog },

  setup() {
    const injector = useReflectiveInjector([ClassifyService])
    const classifyService = injector.get(ClassifyService)

    function getClassifyList() {
      tableHandle.loading = true
      const classify_id = Number(searchHandle.classify_id)
      const params = {
        classify_name: searchHandle.classify_name,
        classify_id: searchHandle.classify_id === '' ? null : isNaN(classify_id) ? null : classify_id,
        classify_level: searchHandle.level,
        page: paginationHandle.page,
        page_size: paginationHandle.size,
        status: Number(searchHandle.status),
        cat1_name: searchHandle.cat1_name,
        cat2_name: searchHandle.cat2_name
      }
      classifyService.getClassifyList(params).then((res) => {
        tableHandle.list = res.list
        paginationHandle.total = res.total
      }).finally(() => {
        tableHandle.loading = false
        searchHandle.loading = false
      })
    }

    const searchHandle = reactive({
      classify_id: '',
      classify_name: '',
      cat1_name: '',
      cat2_name: '',
      loading: false,
      statusList: statusList,
      levelList: levelList,
      status: 2,
      level: 0,
      search: () => {
        searchHandle.loading = true
        getClassifyList()
      },
      reset: () => {
        searchHandle.classify_id = ''
        searchHandle.classify_name = ''
        searchHandle.cat1_name = ''
        searchHandle.cat2_name = ''
        searchHandle.status = 2
        searchHandle.level = 0
        searchHandle.search()
      },
      create: () => {
        classifyItemDialogHandle.visible = true
      }
    })

    const tableHandle = reactive({
      loading: false,
      list: [],
      updateDetail: (id: number) => {
        classifyItemDialogHandle.id = id
        classifyItemDialogHandle.visible = true
        classifyItemDialogHandle.classifyData = tableHandle.list.find(item => item.classify_id === id)
      },
      clickDetail: (id: number) => {
        classifyItemDialogHandle.id = id
        classifyItemDialogHandle.visible = true
        classifyItemDialogHandle.disabled = true
        classifyItemDialogHandle.classifyData = tableHandle.list.find(item => item.classify_id === id)
      },
      deleteClassifyItem: (id: number) => {
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            classifyService.deleteClassify({classify_id: id}).then(() => {
              ElMessage.success('删除成功!')
              getClassifyList()
            })
          }).catch((err: any) => {
          if (err === 'cancel') {
            console.log(err)
          }
        })
      },
      clickOffShelf: (row: any) => {
        classifyService.updateClassify({...row, status: 0}).then(() => {
          ElMessage.success('下架成功')
          getClassifyList()
        })
      },
      clickOnShelf: (row: any) => {
        classifyService.updateClassify({...row, status: 1}).then(() => {
          ElMessage.success('上架成功')
          getClassifyList()
        })
      }
    })

    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getClassifyList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getClassifyList()
      }
    })

    const classifyItemDialogHandle = reactive({
      visible: false,
      id: null,
      classifyData: null,
      disabled: false,
      clickClassifyItemDialogClose: () => {
        classifyItemDialogHandle.visible = false
        classifyItemDialogHandle.id = null
        classifyItemDialogHandle.classifyData = null
        classifyItemDialogHandle.disabled = false
      },
      clickClassifyItemDialogSubmit: () => {
        classifyItemDialogHandle.clickClassifyItemDialogClose()
        getClassifyList()
      }
    })

    onMounted(() => getClassifyList())

    return {
      searchHandle,
      tableHandle,
      paginationHandle,
      classifyItemDialogHandle,

      formatDate,
      OperateTypeByLabelMap
    }
  }
})
