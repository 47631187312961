
import { defineComponent, toRefs } from 'vue'
import { UploadReturnInfo } from '../uploader.service'
import uploader from './uploader.vue'

export default defineComponent({
  components: { uploader },

  props: {
    showImg: {
      type: String,
      default: ''
    },

    isShow: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    showImgList: {
      type: Array,
      default: () => [] as any[]
    }
  },

  emits: ['fileInfo'],

  setup(props, ctx) {
    const propData = toRefs(props)
    function fileInfo (info: UploadReturnInfo) {
      ctx.emit('fileInfo', info)
    }

    return {
      defaultImg: propData.showImg,
      defaultShow: propData.isShow,

      fileInfo
    }
  },
})
