import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { strictExtend } from '@shared/utils/base/index.js'
import { CheckStatusMap } from '@/common/const'

export interface MatsRequestParams {
  /** 每页条数 */
  page: number
  /** 页码 */
  size: number
  /** 设计师id */
  designer_id?: number
  /** 模板id */
  id?: number
  /** 标题 */
  title?: string
  /** 状态列表 */
  status_list?: CheckStatusMap[]
  /** 格式列表 */
  format_list?: string[]
}

export interface OperatelogRequestParams {
  /** 模板id */
  mat_id: number
}

export interface OperateList {
  /** 模板id */
  id: number
  /** 设计师id */
  mat_id: number
  /** 上传时间 */
  operator: string
  /** 标题 */
  operate_type: number
  /** 标签 */
  operate_result: number
  /** 1：web，2：duba,3:ppt,4:liebao,5:pdf */
  operate_reason: string
  /** 大图地址 */
  status_before: number
  /** 小图地址 */
  status_after: number
  /** 多格式 */
  created_time: string
}

export interface OperatelogResponseData {
  logs: OperateList[]
}

export interface MatFiles {
  /** 格式 */
  format: string
  /** 模板大小 */
  size: string
  /** 页数 */
  page_count: string
  /** 素材下载链接 */
  file_url: string
}

export interface MatsList {
  /** 模板id */
  id: string
  /** 设计师id */
  designer_id: string
  /** 上传时间 */
  up_load_time: string
  /** 标题 */
  title: string
  /** 标签 */
  tag: string
  /** 1：web，2：duba,3:ppt,4:liebao,5:pdf */
  scene: number[]
  /** 大图地址 */
  big_img: string
  /** 小图地址 */
  small_img: string
  /** 多格式 */
  mat_files: MatFiles[]
  /** 模板状态 0：下架 1：上架 2：一审 3：驳回 4：二审 */
  status: number
}

export interface MatsResponseData {
  list: MatsList[],
  total: number
}

export interface LastMatsResponse extends MatsList, MatFiles {
  isMerge: boolean,
  mergeNum: number,
  newId: number
}

export interface CheckMatRequestParams {
  /** 素材id */
  id: number
  /** 1.通过3.驳回 */
  operator: number
  /** 通过理由 */
  pass_reason?: string
  /** 拒绝理由 */
  reject_reason?: string
}

export interface ResponseDataFormats {
  formats: string[]
}

@Injectable()
export class VerifyService {
  private matList: MatsList[] = []

  constructor(private http: Http) {}

  getVerifyMats(params: MatsRequestParams) {
    return this.http.post<MatsResponseData>('/check/mats', params).then((response: MatsResponseData) => {
      const list = response.list
      this.matList = strictExtend(list) as MatsList[]

      const formatList = this.formatData(list)

      return { list: formatList, total: response.total }
    })
  }

  getFormats() {
    return this.http.post('/content/formats', {}).then((response: ResponseDataFormats) => response.formats)
  }

  formatData (list: MatsList[]) {
    const newList: LastMatsResponse[] = []

    // 数据改造，以符合展示预期
    list?.forEach(mat => {
      mat.mat_files.forEach((file, fileIndex) => {
        newList.push({
          newId: Math.random(),
          isMerge: fileIndex === 0, // 是否数据合并
          mergeNum: mat.mat_files.length, // 如果合并，则是合并多少
          ...mat,
          ...file,
        })
      })
    })

    return newList
  }

  /**
   * prop: 根据什么键进行排序
   * orderType: 排序的规则（ascending 表示升序，descending 表示降序，null 表示还原为原始顺序）
   */
  orderMats (prop: string, orderType: string) {
    const newList = strictExtend(this.matList) as MatsList[]

    if (!orderType) {
      return this.formatData(this.matList)
    }

    const orderFunc = {
      ascending: (a: any, b: any) => a[prop] - b[prop],
      descending: (a: any, b: any) => b[prop] - a[prop]
    }

    const orderList = newList.sort(orderFunc[orderType])
    return this.formatData(orderList)
  }

  updateMatStatus(params: CheckMatRequestParams) {
    return this.http.post('/check/operator', params)
  }
}
