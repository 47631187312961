import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sort',
    component: () => import(/* webpackChunkName: "verify" */ '../../views/sort/index.vue'),
    children: [
      {
        path: 'mat',
        name: 'sort-mat',
        component: () => import(/* webpackChunkName: "verify" */ '../../views/sort/mat/sort.vue')
      },
      {
        path: 'sort-mat-config',
        name: 'sort-mat-config',
        component: () => import(/* webpackChunkName: "order" */ '../../views/sort/mat/sort-config/sort-config.vue')
      },
      {
        path: 'sort-mat-order',
        name: 'sort-mat-order',
        component: () => import(/* webpackChunkName: "order" */ '../../views/sort/mat/sort-order/sort-order.vue')
      },
      {
        path: 'search',
        name: 'sort-search',
        component: () => import(/* webpackChunkName: "verify" */ '../../views/sort/search/sort.vue')
      },
      {
        path: 'search-config',
        name: 'sort-search-config',
        component: () => import(/* webpackChunkName: "order" */ '../../views/sort/search/sort-config/sort-config.vue')
      },
      {
        path: 'search-order',
        name: 'sort-search-order',
        component: () => import(/* webpackChunkName: "order" */ '../../views/sort/search/sort-order/sort-order.vue')
      },
    ]
  }
]

export default routes
