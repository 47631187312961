
import { defineComponent, reactive, onMounted, defineAsyncComponent, ref } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ConstPagination } from '@/common/const'
import { formatDate } from '@shared/utils/base'
import { AdvertisementService, AdTypeByLabelMap, ClientTypeByLabelMap, StatusByLabelMap } from '@/views/marketing/advertisement/advertisement.service'
import { ElMessageBox, ElMessage } from 'element-plus'

const AdvertisementInformationDialog = defineAsyncComponent(() => import('./_components/advertisementInformationDialog/advertisementInformationDialog.vue'))
const AdvertisementItemDialog = defineAsyncComponent(() => import('./_components/advertisementItemDialog/advertisementItemDialog.vue'))
const ReadmeDialog = defineAsyncComponent(() => import('./_components/readmeDialog/readmeDialog.vue'))

const typeList = Object.freeze([
  { label: '全部', value: 0 },
  { label: '图文广告', value: 1 },
  { label: '文字广告', value: 2 },
  { label: '轮播图', value: 3 }
])

const clientList = Object.freeze([
  { label: '全部', value: '' },
  { label: 'web端', value: 'web' },
  { label: '客户端', value: 'client' },
  { label: '侧边栏', value: 'officeside' },
  { label: '小程序', value: 'applets' },
  { label: '猎豹浏览器', value: 'liebao' },
  { label: '可牛PDF', value: 'pdf' },
])

export default defineComponent({
  components: { MainLayout, TableLayout, AdvertisementInformationDialog, AdvertisementItemDialog, ReadmeDialog },

  setup() {
    const injector = useReflectiveInjector([AdvertisementService])
    const advertisementService = injector.get(AdvertisementService)

    const readmeDialogVisible = ref(false)
    const clickReadmeDialogClose = (flag: boolean = false) => {
      readmeDialogVisible.value = flag
    }

    // 获取广告列表
    function getAdvertisementList() {
      tableHandle.loading = true
      const params = {
        ad_type: searchHandle.type,
        client_type: searchHandle.client,
        pos_code: searchHandle.position,
        ad_name: searchHandle.ad_name,
        page: paginationHandle.page,
        page_size: paginationHandle.size
      }
      advertisementService.getAdList(params).then((res) => {
        tableHandle.list = res.list
        paginationHandle.total = res.total
      }).finally(() => {
        tableHandle.loading = false
        searchHandle.loading = false
      })
    }

    // 搜索句柄
    const searchHandle = reactive({
      loading: false,
      typeList: typeList,
      type: 0,
      position: '',
      ad_name: '',
      clientList: clientList,
      client: '',
      search: () => {
        searchHandle.loading = true
        getAdvertisementList()
      },
      reset: () => {
        searchHandle.type = 0
        searchHandle.position = ''
        searchHandle.client = ''
        searchHandle.ad_name = ''
        searchHandle.search()
      }
    })

    // 新建广告句柄
    const newAdvertiseHandle = reactive({
      newAdvertisement: () => {
        advertisementInformationDialogHandle.visible = true
      }
    })

    // 表单句柄
    const tableHandle = reactive({
      list: [],
      loading: false,
      edit: (row: any) => {
        advertisementInformationDialogHandle.id = row.id
        advertisementInformationDialogHandle.visible = true
      },
      dispose: (row: any) => {
        advertisementItemDialogHandle.adId = row.id
        advertisementItemDialogHandle.visible = true
        advertisementItemDialogHandle.type = row.ad_type
      },
      start: (row: any) => {
        advertisementService.updateAd({
          id: row.id,
          ad_type: row.ad_type,
          status: 1,
          client_type: row.client_type,
          pos_code: row.pos_code,
          ad_name: row.ad_name,
          plan: row.plan,
          weight: row.weight
        }).then(() => {
          getAdvertisementList()
        })
      },
      forbid: (row: any) => {
        advertisementService.updateAd({
          id: row.id,
          ad_type: row.ad_type,
          status: -1,
          client_type: row.client_type,
          pos_code: row.pos_code,
          ad_name: row.ad_name,
          plan: row.plan,
          weight: row.weight
        }).then(() => {
          getAdvertisementList()
        })
      },
      delete: (row: any) => {
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            advertisementService.deleteAd({id: row.id}).then(() => {
              ElMessage.success('删除成功!')
              getAdvertisementList()
            })
          }).catch((err: any) => {
          if (err === 'cancel') {
            console.log(err)
          }
        })
      }
    })

    // 分页句柄
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getAdvertisementList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getAdvertisementList()
      }
    })

    // 新建/编辑广告句柄
    const advertisementInformationDialogHandle = reactive({
      visible: false,
      id: null,
      clickAdvertisementInformationDialogClose: () => {
        advertisementInformationDialogHandle.resetAdvertisementInformationDialog()
      },
      clickAdvertisementInformationDialogSubmit: () => {
        advertisementInformationDialogHandle.resetAdvertisementInformationDialog()
        getAdvertisementList()
      },
      resetAdvertisementInformationDialog: () => {
        advertisementInformationDialogHandle.id = null
        advertisementInformationDialogHandle.visible = false
      }
    })

    // 配置广告项句柄
    const advertisementItemDialogHandle = reactive({
      visible: false,
      adId: null,
      type: null,
      clickAdvertisementItemDialogClose: () => {
        advertisementItemDialogHandle.visible = false
      }
    })

    onMounted(() => getAdvertisementList())

    return {
      readmeDialogVisible,
      clickReadmeDialogClose,
      searchHandle,
      newAdvertiseHandle,
      tableHandle,
      paginationHandle,
      advertisementInformationDialogHandle,
      advertisementItemDialogHandle,

      formatDate,
      AdTypeByLabelMap,
      ClientTypeByLabelMap,
      StatusByLabelMap
    }
  }
})
