
import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { ConstPagination } from '@/common/const';
import { formatDate } from '@shared/utils/base';
import { RightsService } from '@/views/rights/rights.service';

const NewVipItemDialog = defineAsyncComponent(() => import('./_components/newVipItemDialog/newVipItemDialog.vue'))

export default defineComponent({
  components: { MainLayout, TableLayout, NewVipItemDialog },

  setup() {
    const injector = useReflectiveInjector([RightsService])
    const rightsService = injector.get(RightsService)

    // 获取权限列表
    function getItemList() {
      tableHandle.loading = true

      const params = {
        page_params: {
          page: paginationHandle.page,
          page_size: paginationHandle.size
        },
        uid: !searchHandle.uid ? null : Number(searchHandle.uid),
        source_id: searchHandle.source_id
      }

      rightsService.getVipItemSearch(params).then((res) => {
        tableHandle.list = res.list
        paginationHandle.total = res.total
      }).finally(() => {
        tableHandle.loading = false
        searchHandle.loading = false
      })
    }

    // 删除用户权益
    function deleteVipItem(row: any) {
      rightsService.deleteVipItem({ uid: row.uid, delete_id: row.id, operation_code: 2 }).then(() => {
        getItemList()
      })
    }

    // 搜索句柄
    const searchHandle = reactive({
      loading: false,
      uid: '',
      source_id: '',
      search: () => {
        searchHandle.loading = true
        getItemList()
      },
      reset: () => {
        searchHandle.uid = ''
        searchHandle.source_id = ''
        searchHandle.search()
      }
    })

    // 表单句柄
    const tableHandle = reactive({
      list: [],
      loading: false,
      newVipItem: () => {
        newVipDialog.visible = true
      },
      showDetail: (row: any) => {
        vipDetailDialog.visible = true
        vipDetailDialog.detailMessage = row
      },
      deleteItem: (row: any) => {
        deleteVipItem(row)
      }
    })

    // 分页句柄
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getItemList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getItemList()
      }
    })

    // 新建会员权益句柄
    const newVipDialog = reactive({
      visible: false,
      clickNewVipDialogClose() {
        newVipDialog.visible = false
      },
      clickNewVipDialogSubmit() {
        newVipDialog.visible = false
        getItemList()
      }
    })

    // 会员权益详情句柄
    const vipDetailDialog = reactive({
      visible: false,
      detailMessage: null,
      clickVipDetailDialogClose() {
        vipDetailDialog.visible = false
        vipDetailDialog.detailMessage = null
      }
    })

    onMounted(() => getItemList())

    return {
      searchHandle,
      tableHandle,
      paginationHandle,
      newVipDialog,
      vipDetailDialog,

      formatDate
    }
  }
})
