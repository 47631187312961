import { isDevOrTest } from '@/common/env';
import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { BehaviorSubject } from 'rxjs';

export interface List {
  /** 分类id */
  id: number
  /** 名称 */
  name: string
  /** 排序号，升序 */
  order_num: number
  /** 详细介绍 */
  detail: string
  /** 图标 */
  img_url: string
  /** 二级分类列表 */
  list?: List[]
}

export interface SelectOption extends List {
  label: string
  value: number
  children?: SelectOption[]
}

export interface ResponseClassifyData {
  /** 一级分类列表 */
  list: List[]
}

export interface Channel {
  label: string,
  value: number
}

export interface Tags {
  label: string,
  value: string
}

export interface PayTypes {
  label: string,
  value: string|number
}

interface _channels {
  value: string,
  key: number
}
export interface ResponseChannelData {
  /** map 接口 key:value */
  channels: _channels[]
}

export interface ResponseFormatsData {
  formats: string[]
}

@Injectable()
export class ConstService {
  host = isDevOrTest ? 'https://test-loveoffice.zhhainiao.com/api' : 'https://api.777ppt.com/api'
  interfaceLink = {
    tags: `${this.host}/content/tags`,
    category: `${this.host}/category/list`,
    channels: `${this.host}/content/channels`,
    formats: `${this.host}/content/formats`,
  }

  tagsData = new BehaviorSubject<Array<Tags>>([])
  channelsData = new BehaviorSubject<Array<Channel>>([])
  classifyData = new BehaviorSubject<Array<List>>([])
  formatsData = new BehaviorSubject<string[]>([])

  constructor(private http: Http) {
    console.log('host->:', this.host)
    this.updateTags()
    this.updateClassify()
    this.updateChannels()
    this.updateFormats()
  }

  private updateTags() {
    this.http.post<{ tags: string[] }>(this.interfaceLink.tags, {}).then(response => {
      const tips = response.tags.map(tip => {
        return { label: tip, value: tip }
      })
      this.tagsData.next(tips)
    })
  }

  private updateClassify() {
    this.http.post<ResponseClassifyData>(this.interfaceLink.category, {}).then(response => {
      const list = iterator(response.list)

      function iterator (childList: List[]): SelectOption[] {
        return childList.map(item => {
          return {
            label: item.name,
            value: item.id,
            children: item.list ? iterator(item.list) : null,
            ...item
          }
        })
      }

      this.classifyData.next(list)
    })
  }

  private updateChannels() {
    this.http.post<ResponseChannelData>(this.interfaceLink.channels, {}).then(response => {
      const channels = response.channels.map(channel => {
        return { label: channel.value, value: channel.key }
      })
      this.channelsData.next(channels)
    })
  }

  private updateFormats() {
    this.http.post<ResponseFormatsData>(this.interfaceLink.formats, {}).then(response => {
      this.formatsData.next(response.formats)
    })
  }
}
