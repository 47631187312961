import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26a5924e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  ref: "uploadCanvas",
  class: "upload_canvas"
}
const _hoisted_3 = { ref: "showCanvas" }
const _hoisted_4 = { class: "img-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_ShowImagesDialog = _resolveComponent("ShowImagesDialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("section", null, [
    _withDirectives((_openBlock(), _createBlock(_component_el_upload, {
      ref: "picUpload",
      action: "#",
      accept: "image/*",
      class: "comp",
      drag: "",
      multiple: "",
      "show-file-list": false,
      "http-request": _ctx.httpImgUpload,
      limit: _ctx.limitPics,
      "on-exceed": _ctx.exceed
    }, {
      default: _withCtx(() => [
        (_ctx.showImg)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.showImg,
              class: "img"
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createBlock(_component_PlusIcon, {
              key: 1,
              width: "20",
              height: "20"
            }))
      ]),
      _: 1
    }, 8, ["http-request", "limit", "on-exceed"])), [
      [_directive_loading, _ctx.uploadHandle.loading]
    ]),
    _withDirectives(_createElementVNode("canvas", _hoisted_2, null, 512), [
      [_vShow, false]
    ]),
    _withDirectives(_createElementVNode("canvas", _hoisted_3, null, 512), [
      [_vShow, false]
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgList, (img) => {
        return (_openBlock(), _createBlock(_component_el_image, {
          key: img,
          class: "img",
          src: img,
          "hide-on-click-modal": true,
          "preview-src-list": _ctx.imgList,
          fit: "contain"
        }, null, 8, ["src", "preview-src-list"]))
      }), 128))
    ]),
    (_ctx.showImagesDialogHandle.visible)
      ? (_openBlock(), _createBlock(_component_ShowImagesDialog, {
          key: 0,
          modelValue: _ctx.showImagesDialogHandle.visible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showImagesDialogHandle.visible) = $event)),
          "images-files": _ctx.showImagesDialogHandle.images,
          onClickShowImagesDialogClose: _ctx.showImagesDialogHandle.clickShowImagesDialogClose,
          onClickShowImagesDialogSubmit: _ctx.showImagesDialogHandle.clickShowImagesDialogSubmit
        }, null, 8, ["modelValue", "images-files", "onClickShowImagesDialogClose", "onClickShowImagesDialogSubmit"]))
      : _createCommentVNode("", true)
  ]))
}