import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface RequestActivityId {
  /** 活动id */
  activity_id: number
}

export interface Topic {
  id: number
  /** 活动id */
  activity_id: number
  /** banner图地址 */
  banner_img: string
  /** 素材id集合，英文逗号分隔 */
  mat_ids: string
}

export interface ResponseGetTopic {
  data: Topic
}

@Injectable()
export class TopicService {
  constructor (private http: Http) {}

  getTopic (data: RequestActivityId) {
    return this.http.post<ResponseGetTopic>('/web-topic/get', data)
  }

  addTopic (data: Topic) {
    return this.http.post<void>('/web-topic/add', data)
  }

  updateTopic (data: Topic) {
    return this.http.post<void>('/web-topic/update', data)
  }
}
