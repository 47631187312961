import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { strictExtend } from '@shared/utils/base/index.js';

export interface MatFiles {
  /** 格式 **/
  format: string
  /** 大小 **/
  size: number
  /** 页数 **/
  page_count: number
  /** 文件链接 **/
  file_url: string
}

export interface MatsList {
  /** 模板id **/
  id: number
  /** 设计师id **/
  designer_id: number
  /** 上传时间 **/
  upload_time: string
  /** 标题 **/
  title: string
  /** 标签 **/
  tag: string
  /** 场景数据 1：web 2：duba 3：ppt 4：liebao 5：pdf **/
  scene?: number[]
  /** 模板状态 0：已下架 1：已上架 2：待审核 3：已驳回**/
  status?: string
  /** 支付类型 0：免费 1：付费 **/
  pay_type?: string
  /** 小图地址 **/
  small_img: string
  /** 大图地址 **/
  big_img: string
  /** 大图地址列表 **/
  big_imgs: string[]
  /** 大图类型：0未知，1拼图，2单图 */
  big_img_type: number
  /** 大图排版方式：0未知，1横向，2纵向 */
  big_img_layout: number
  /** 一级分类id **/
  class_1?: number
  /** 二级分类id **/
  class_2?: number
  /** 三级分类id **/
  class_3?: number
  /** 多个格式 **/
  mat_files?: MatFiles[],
  preview_video?: string
}

export interface MatsRequestParams {
  /** 每页条数 */
  page: number
  /** 页码 */
  size: number
  /** 设计师id */
  designer_id?: string
  /** 模板id */
  id?: number
  /** 标题 */
  title?: string
}

export interface ModifyMatRequestParams {
  /** 模板id */
  id: number
  /** 标题 */
  title?: string
  /** 标签 */
  tag?: string
  /** 支付类型 0：免费 1：付费 */
  pay_type?: string
  /** 模板状态 0：已下架 1：已上架 2：待审核 3：已驳回 */
  status?: string
  /** 场景数据 1：web 2：duba 3：ppt 4：liebao 5：pdf */
  scene?: number[]
  /** 小图文件名 */
  small_img?: string
  /** 大图文件名 */
  big_img?: string
  /** 多个格式 */
  max_files?: MatFiles[]
}

export interface MatsManageResponseData {
  list: MatsList[],
  total: number
}

@Injectable()
export class ManageService {
  matList: MatsList[] = []

  constructor(private http: Http) {}

  getManagerMats(params: MatsRequestParams) {
    return this.http.post<MatsManageResponseData>('/manager/mats', params).then((response: MatsManageResponseData) => {
      const list = response.list
      this.matList = strictExtend(list) as MatsList[]

      return { list: list, total: response.total }
    })
  }

  /**
   * prop: 根据什么键进行排序
   * orderType: 排序的规则（ascending 表示升序，descending 表示降序，null 表示还原为原始顺序）
   */
  orderMats (prop: string, orderType: string) {
    const newList = strictExtend(this.matList) as MatsList[]

    if (!orderType) {
      return this.matList
    }

    const orderFunc = {
      ascending: (a: any, b: any) => a[prop] - b[prop],
      descending: (a: any, b: any) => b[prop] - a[prop]
    }

    return newList.sort(orderFunc[orderType])
  }

  modifyManageMat(params: ModifyMatRequestParams) {
    return this.http.post('/manager/modify', params)
  }
}
