import { RouteRecordRaw } from 'vue-router'
import members from './members'
import marketing from './marketing'
import verify from './verify'
import classify from './classify'
import mp from './mp'
import sort from './sort'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/', // 预留一个报表页面
    redirect: '/manage'
  },
  {
    path: '/contingency',
    component: () => import(/* webpackChunkName: "contingency" */ '../views/contingency/contingency.vue')
  },
  ...verify,
  {
    path: '/manage',
    name: 'manage',
    component: () => import(/* webpackChunkName: "manage" */ '../views/manage/manage.vue')
  },
  {
    path: '/officeplus',
    name: 'officeplus',
    component: () => import(/* webpackChunkName: "officeplus" */ '../views/officeplus/officeplus.vue')
  },
  ...classify,
  ...members,
  {
    path: '/rights',
    name: 'rights',
    component: () => import(/* webpackChunkName: "rights" */ '../views/rights/rights.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/order/order.vue')
  },
  ...marketing,
  ...mp,
  ...sort,
]
