
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { PermissionDialogService } from '@/views/members/permission/_components/permissionDialog/permissionDialog.service';

export default defineComponent({
  props: {
    permissionDialog: {
      type: Boolean,
      default: false
    },

    id: {
      type: Number,
      default: null
    }
  },

  emits: ['clickPermissionDialogClose', 'clickPermissionDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([PermissionDialogService])
    const permissionDialogService = injector.get(PermissionDialogService)

    const propData = toRefs(props)
    const formRef = ref(null)

    const dialogHandle = reactive({
      visible: props.permissionDialog,
      loading: false,
      formData: {
        name: '',
        duration: '',
        limit_num: '',
        level: '',
        status: 1,
        limit_class: '',
        type: 0
      },
      rules: {
        name: [
          { required: true, message: '请输入会员名称', trigger: 'blur' },
        ],
        duration: [
          { required: true, message: '请输入会员有效期', trigger: 'blur' },
        ],
        limit_num: [
          { required: true, message: '请输入每日最大下载次数', trigger: 'blur' },
        ],
        level: [
          { required: true, message: '请输入会员等级', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择权限范围', trigger: 'blur' }
        ]
      },
      close: () => {
        ctx.emit('clickPermissionDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          const params = {
            name: dialogHandle.formData.name,
            duration: Number(dialogHandle.formData.duration),
            limit_num: Number(dialogHandle.formData.limit_num),
            level: Number(dialogHandle.formData.level),
            status: dialogHandle.formData.status,
            type: Number(dialogHandle.formData.type)
          }
          if (props.id) {
            permissionDialogService.updateVipType({
              vip_item_type: {...params, limit_class: dialogHandle.formData.limit_class, aid: props.id as number}
            }).then(() => {
              ctx.emit('clickPermissionDialogSubmit')
            })
          } else {
            permissionDialogService.addVipType({vip_item_type: params}).then(() => {
              ctx.emit('clickPermissionDialogSubmit')
            })
          }
        })
      }
    })

    if (props.id) {
      dialogHandle.formData = permissionDialogService.getDataById(props.id as number)
    }

    function tipFunc (msg?: string, type?: string) {
      ElMessage[type || 'warning'](msg || '请填写完整信息')
    }

    return {
      formRef,
      dialogHandle
    }
  }
})
