
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ClassifyItemDialogService } from './classifyItemDialog.service';
import { ElMessage } from 'element-plus'

const floorList = Object.freeze([
  { label: '一级',value: 'first'},
  { label: '二级', value: 'second' },
  { label: '三级', value: 'third' }
])

const floorMap = {
  1: 'first',
  2: 'second',
  3: 'third'
}

/** 因为要一次取多个数据，因此目前设置10000为最大值 */
const pageSize = 10000

export default defineComponent({
  props: {
    classifyItemDialogVisible: {
      type: Boolean,
      default: false
    },

    id: {
      type: Number,
      default: null
    },

    classifyData: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['clickClassifyItemDialogClose', 'clickClassifyItemDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([ClassifyItemDialogService])
    const classifyItemDialogService = injector.get(ClassifyItemDialogService)
    const formRef = ref(null)
    const classifyTitle = computed(() => {
      if (props.id && props.disabled) {
        return '查看分类'
      }
      if (props.id) {
        return '编辑分类'
      }
      return '新建分类'
    })

    function getCat1Array() {
      const params = {
        page: 1,
        page_size: pageSize,
        /** 取一级分类时需要传入0 */
        classify_parent_id: 0,
        /** status取全部时需要传入非0和1的任意数值 */
        status: 2
      }
      classifyItemDialogService.getClassifyList(params).then((res) => {
        dialogHandle.formData.cat1Array = (res?.list || []).map(item => {
          return { label: item.classify_name, value: item.classify_id }
        })
      })
    }

    function getCat2Array(id: number) {
      const params = {
        page: 1,
        page_size: pageSize,
        classify_parent_id: id,
        /** status取全部时需要传入非0和1的任意数值 */
        status: 2
      }
      classifyItemDialogService.getClassifyList(params).then((res) => {
        dialogHandle.formData.cat2Array = (res?.list || []).map(item => {
          return { label: item.classify_name, value: item.classify_id }
        })
      })
    }

    function resetCat() {
      dialogHandle.formData.cat1 = ''
      dialogHandle.formData.cat2 = ''
      dialogHandle.formData.cat1Array = []
      dialogHandle.formData.cat2Array = []
    }

    const dialogHandle = reactive({
      formData: {
        classify_name: '',
        cat1: '',
        cat2: '',
        order_num: '',
        floor: '',
        cat1Array: [],
        cat2Array: []
      },
      close: () => {
        ctx.emit('clickClassifyItemDialogClose')
      },
      submit: () => {
        if (!dialogHandle.formData.classify_name) {
          ElMessage.warning('请填写分类名称')
          return
        }
        if (dialogHandle.formData.order_num === '') {
          ElMessage.warning('请输入所属分类排序')
          return
        }
        if (!dialogHandle.formData.floor) {
          ElMessage.warning('请选择分类等级')
          return
        }
        let params = {
          classify_name: dialogHandle.formData.classify_name,
          order_num: Number(dialogHandle.formData.order_num)
        }
        if (dialogHandle.formData.floor === 'second') {
          params = Object.assign({}, params, { cat1: dialogHandle.formData.cat1 })
        }
        if (dialogHandle.formData.floor === 'third') {
          params = Object.assign({}, params, { cat1: dialogHandle.formData.cat1, cat2: dialogHandle.formData.cat2 })
        }
        if (!props.id) {
          classifyItemDialogService.addClassifyItem(params).then(() => {
            ElMessage.success('新建成功')
            ctx.emit('clickClassifyItemDialogSubmit')
          })
        } else {
          classifyItemDialogService.updateClassify({ ...params, classify_id: props.id as number, status: Number(props.classifyData?.status) }).then(() => {
            ElMessage.success('更新成功')
            ctx.emit('clickClassifyItemDialogSubmit')
          })
        }
      }
    })

    if (props.id) {
      dialogHandle.formData.floor = floorMap[props.classifyData?.classify_level]
      dialogHandle.formData.classify_name = props.classifyData?.classify_name
      dialogHandle.formData.order_num = props.classifyData?.order_num
      if (props.classifyData?.cat1) {
        getCat1Array()
        dialogHandle.formData.cat1 = props.classifyData?.cat1
        if (props.classifyData?.cat2) {
          getCat2Array(Number(props.classifyData?.cat1))
          dialogHandle.formData.cat2 = props.classifyData?.cat2
        }
      }
    }

    watch(() => dialogHandle.formData.floor, (value) => {
      resetCat()
      if (value === 'second' || value === 'third') {
        getCat1Array()
      }
    })

    watch(() => dialogHandle.formData.cat1, (value) => {
      if (dialogHandle.formData.floor === 'third') {
        getCat2Array(Number(value))
      }
    })

    return {
      formRef,
      dialogHandle,
      classifyTitle,

      floorList
    }
  }
})

