
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive } from 'vue'
import { Uploader } from '../uploader.service'
import { UploadFileType } from '@/common/const';
import { ElMessage } from 'element-plus'

// 阿里图床压缩支持的最大size
const MaxSizeUpload = 200000000

export default defineComponent({
  props: {
    video: String
  },
  emits: ['fileInfo', 'delete'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([Uploader])
    const uploaderService = injector.get(Uploader)

    const uploadHandle = reactive({
      videoUrl: '',
      loading: false,
      isDeleted: false,
    })

    function httpVideoUpload (options: any) {
      uploadHandle.loading = true
      uploaderService.uploadFileResultFileName(options, UploadFileType.video).then(info => {
        ctx.emit('fileInfo', info)
      }).finally(() => { uploadHandle.loading = false })
    }

    function beforeUpload (file: File) {
      if (file.size > MaxSizeUpload) {
        ElMessage('上传的视频大小超过200MB，为避免渲染失败，请压缩后重新上传')
        return false
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        uploadHandle.videoUrl = reader.result as string
        uploadHandle.isDeleted = false
      }
      reader.readAsDataURL(file)
    }

    return {
      httpVideoUpload,
      uploadHandle,
      beforeUpload,
      handleRemoveVideo () {
        uploadHandle.isDeleted = true
        ctx.emit('delete')
      }
    }
  }
})
