
import { defineComponent, reactive } from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import {
  ConfigService,
  CatMatSortItem,
  ClassifyLableValue
} from './sort-config.service'
import { SortService } from '@/views/sort/mat/sort.service'
import { formatDate } from '@shared/utils/base'
import { allowedNodeEnvironmentFlags } from 'process'

const log = console.log.bind(console)

export default defineComponent({
  components: {
    MainLayout,
    TableLayout
  },
  setup() {
    const injector = useReflectiveInjector([ConfigService, SortService])
    const configService = injector.get(ConfigService)
    const sortService = injector.get(SortService)

    // 获取路由信息
    const route = useRoute()
    const router = useRouter()
    const catSortId = route.query.id ? Number(route.query.id) : 0

    // 排序分类配置相关逻辑
    const catSortHandle = {
      catSortDetail: {} as any,
      classifyList: [] as ClassifyLableValue[],
      getCatSortDetailById(id: number) {
        const req = {
          id: id
        }
        return sortService.getSortItem(req)
          .then(res => res.list[0])
      },
      getSortListById(id: number) {
        const req = {
            custom_mat_sort_id: id
        }
        return configService.queryCatSortList(req)
          .then<CatMatSortItem[]>((res) => {
              return res.list.map((item: CatMatSortItem) => {
                return {
                  ...item,
                  isEdit: false
                }
              })
          })
      },
      addCatMatSort(sortItem: CatMatSortItem) {
        const req = {
          ...sortItem,
          cat: Number(sortItem.cat)
        }
        configService.addClassifySort(req)
          .then(() => {
            tableHandle.loadCatSortList()
          })
      },
      toSortOrderConfigRoute(sortItem: CatMatSortItem) {
        router.push({
          name: 'sort-mat-order',
          query: {
            product: catSortHandle.catSortDetail.product_code,
            custom_mat_sort_id: sortItem.custom_mat_sort_id,
            id: sortItem.id
          }
        })
      },
      loadClassifyList() {
        return configService.getAllClassifyList()
          .then((list: ClassifyLableValue[]) => {
            catSortHandle.classifyList = list.map<ClassifyLableValue>(e => {
              return {
                label: `${e.label} id:${e.value}`,
                value: e.value,
              }
            })
          })
      },
      getCatNameById(catId: number | string) {
        const idSearch = Number(catId)
        const o = catSortHandle.classifyList.find(classify => classify.value === idSearch)
        return o ? o.label : ''
      },
      loadCatSortDetail() {
        catSortHandle.getCatSortDetailById(catSortId)
          .then(res => {
            catSortHandle.catSortDetail = res
          })
      },
    }

    // 搜索相关逻辑
    const searchHandle = reactive({
      id: '',
      name: '',
      clickSearch: () => {
        const l = tableHandle.catSortList
          .filter(item => {
            const isSameId = String(item.id) === searchHandle.id
            const catName = catSortHandle.getCatNameById(item.cat)
            const isIncludesCat = searchHandle.name && catName.includes(searchHandle.name)
            return isSameId || isIncludesCat
          })
        tableHandle.catSortList = l
      },
      clickReset: () => {
        tableHandle.loadCatSortList()
      }
    })

    // 列表展示操作相关逻辑
    const tableHandle = reactive({
      catSortList: [] as CatMatSortItem[],
      loading: false,
      clickConfig(sortItem: CatMatSortItem) {
        if (sortItem.isEdit || sortItem.isNew) {
          ElMessageBox.alert('请保存后再进行配置')
        } else {
          catSortHandle.toSortOrderConfigRoute(sortItem)
        }
      },
      clickNew() {
        tableHandle.catSortList.unshift({
          custom_mat_sort_id: catSortId,
          mat_sort: '{}',
          cat: '',
          isNew: true
        })
      },
      clickEdit(sortItem: CatMatSortItem) {
        sortItem.isEdit = true
      },
      clickSave(sortItem: CatMatSortItem) {
        if (sortItem.cat === '') {
          ElMessageBox.alert('请选择分类再保存')
          return
        }
        if (sortItem.mat_sort === '') {
          sortItem.mat_sort  = '{}'
        }
        ElMessageBox.confirm('是否确认保存', '提示', {
          confirmButtonText: '确认保存',
          cancelButtonText: '暂不保存',
          type: 'info',
        })
          .then(() => {
            if (sortItem.isNew) {
              catSortHandle.addCatMatSort(sortItem)
            } else {
              sortItem.isEdit = false
              const req = {
                ...sortItem,
                cat: Number(sortItem.cat)
              }
              configService.updateClassifySort(req)
            }
          })
      },
      refreshItem (sortItem: CatMatSortItem) {
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        sortService.cdnAndCacheRefresh({
          product: catSortHandle.catSortDetail.product_code,
          classId: String(sortItem.cat)
        })
      },
      clickDelete(index: number) {
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            if (tableHandle.catSortList[index].id) {
              configService.deleteClassifySort({id: Number(tableHandle.catSortList[index].id)}).then(() => {
                ElMessage.success('删除成功')
                tableHandle.catSortList.splice(index, 1)
              })
            } else {
              tableHandle.catSortList.splice(index, 1)
              ElMessage.success('删除成功')
            }
          })
      },
      loadCatSortList() {
        tableHandle.loading = true
        catSortHandle.getSortListById(catSortId)
          .then(list => {
            tableHandle.catSortList = list
            tableHandle.loading = false
          })
      },
      clickRefresh: async () => {
        // Promise.all([sortService.rankingMat(), sortService.rankingMatCalculate()]).then(() => {
        //   ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        // })
        const cats = tableHandle.catSortList.map(item => item.cat)
        const product = catSortHandle.catSortDetail.product_code
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        Promise.all(cats.map(cat => {
          return  sortService.cdnAndCacheRefresh({
            product,
            classId: String(cat)
          })
        }))
      }
    })

    // create加载数据
    catSortHandle.loadClassifyList()
      .then(() => {
        catSortHandle.loadCatSortDetail()
      })
      .then(() => {
        tableHandle.loadCatSortList()
      })

    return {
      catSortHandle,
      tableHandle,
      searchHandle,
      formatDate,
    }
  }
})
