import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { BehaviorSubject } from 'rxjs';
import { PlanService } from '@/views/members/plan/plan.service';

export interface Types {
  label: string,
  value: string
}

export interface TypeList {
  /** 主键id */
  aid: number,
  /** 会员权限 */
  name: string,
  /** 会员等级 */
  level: number,
  /** 有效期 */
  duration: number,
  /** 每天最大下载次数 */
  limit_num: number,
  /** 分类权限：逗号分隔；*表示有全部分类 */
  limit_class: string,
  /** 启用状态：-1=禁用，1=启用 */
  status: number,
  /** 创建时间 */
  create_time: string,
  /** 更新时间 */
  update_time: string,
  /** 最后一次修改人 */
  last_operator: string
}

export interface VipPackageItem {
  /** 套餐备注 */
  inner_name: string
  /** 套餐名称	*/
  name: string
  /** 产品原价	*/
  price: number
  /** 优惠价格 */
  discount_price: number
  /** 实际定价	*/
  real_price: number
  /** 套餐介绍	*/
  detail: string
  /** 套餐标签	*/
  tips: string
  /** 拓展字段 */
  ext_arr: string
  /** 套餐排序	*/
  order_num: number
  /** 启用状态：0-禁用，1-启用，默认启用 */
  status: number
  /** 渠道名称	*/
  channel: string
}

export interface UpdatePackageItem {
  /** 主键id */
  id: number
  /** 套餐备注 */
  inner_name: string
  /** 套餐名称	*/
  name: string
  /** 产品原价	*/
  price: number
  /** 优惠价格 */
  discount_price: number
  /** 实际定价	*/
  real_price: number
  /** 套餐介绍	*/
  detail: string
  /** 套餐标签	*/
  tips: string
  /** 拓展字段 */
  ext_arr: string
  /** 套餐排序	*/
  order_num: number
  /** 启用状态：0-禁用，1-启用，默认启用 */
  status: number
  /** 渠道名称	*/
  channel: string
}

export interface PackageAddParams {
  vip_type_id: number,
  vip_package: VipPackageItem
}

export interface PackageUpdateParams {
  vip_package: UpdatePackageItem
}

export interface ResponseTypesData {
  /** map 接口 key:value */
  data: {
    list: TypeList[],
    total: number
  }
}

@Injectable()
export class PlanDialogService {
  typesData = new BehaviorSubject<Array<Types>>([])

  constructor(private http: Http, private planService: PlanService) {
    this.updateTypes()
  }

  private updateTypes() {
    this.http.post<ResponseTypesData>('/vip/item/type/search', {page_params: {page: 1, page_size: 1000}}).then(response => {
      const types = response.data.list.map(type => {
        return { label: `${type.name + '(id:' + type.aid + ')'}`, value: String(type.aid) }
      })
      this.typesData.next(types)
    })
  }

  addVipPackage (params: PackageAddParams) {
    return this.http.post('/vip/package/add', params)
  }

  updateVipPackage (params: PackageUpdateParams) {
    return this.http.post('/vip/package/update', params)
  }

  getDataById (id: number) {
    const packageData = this.planService.packageVipList.find(item => item.id === id)
    return {
      name: packageData.name,
      inner_name: packageData.inner_name,
      tips: packageData.tips,
      detail: packageData.detail,
      real_price: String(packageData.real_price),
      price: String(packageData.price),
      discount_price: String(packageData.discount_price),
      ext_arr: packageData.ext_arr,
      order_num: String(packageData.order_num),
      status: packageData.status,
      channel: packageData.channel,
      type_id: String(packageData.vip_item_id),
      type: packageData.type
    }
  }
}
