import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { RANK_URL } from '@/common/config'

export interface Classify {
  /** 分类id */
  classify_id: number
  /** 分类名称	*/
  classify_name: string
  /** 分类等级	*/
  classify_level: number
  /** 1级分类名称 */
  cat1_name: string
  /** 2级分类名称 */
  cat2_name: string
  /** 0 禁用 1启用 */
  status: number
  /** 排序值	*/
  order_num: number
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string
}

export interface ClassifyList {
  list: Classify[]
}
export interface QuerySortParams {
  /** 分类排序唯一标识	*/
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number
}

export interface QueryClassifyParams {
  /** 起始页数	*/
  page: number
  /** 页容量	*/
  page_size: number
  /** 上下线状态，0 禁用 1启用,搜索筛选项 */
  status: number
}

export interface AddCatSortParams {
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识	*/
  cat?: number
  /** 素材排序	*/
  mat_sort?: string
}

export interface DeleteCatSortParams {
  /** 分类排序标识	*/
  id: number
}

export interface UpdateCatSortParams {
  /** 分类排序标识	*/
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识	*/
  cat?: number
  /** 素材排序	*/
  mat_sort?: string
}

export interface CatMatSortItem {
  /** 分类排序唯一标识 */
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number | string
  /** 分类列表 */
  mat_sort?: string
  /** 创建时间 */
  create_time?: string
  /** 更新时间	*/
  update_time?: string

  // 以下是前端自定义字段
  isEdit?: boolean
  isNew?: boolean
}

export interface CatSortList {
  list: CatMatSortItem[]
  total: number
}

export interface ClassifyLableValue {
  value: number
  label: string
}

@Injectable()
export class ConfigService {
  constructor(private http: Http) {}

  /** 查询分类素材排序 */
  queryCatSortList (params: QuerySortParams) {
    return this.http.post<CatSortList>('/cat_mat_sort/list', params).then((response: CatSortList) => {
      return { list: response.list }
    })
  }

  /** 查询分类列表 */
  queryClassifyList (params: QueryClassifyParams) {
    return this.http.post('/classify/search', params).then((res) => {
      return { list: res.list }
    })
  }

  getAllClassifyList() {
    const params = {
      page: 1,
      page_size: 1000,
      status: 1
    }
    return this.queryClassifyList(params)
      .then<ClassifyLableValue[]>((res: ClassifyList) => {
        return res.list.map((item: Classify) => {
          return {
            value: item.classify_id,
            label: item.classify_name
          }
        })
      })
  }

  /** 添加分类素材排序 */
  addClassifySort (params: AddCatSortParams) {
    return this.http.post('/cat_mat_sort/add', params)
  }

  /** 更新分类素材排序 */
  updateClassifySort (params: UpdateCatSortParams) {
    return this.http.post('/cat_mat_sort/update', params).then((res) => {
      return res
    })
  }

  /** 删除分类素材排序 */
  deleteClassifySort (params: DeleteCatSortParams) {
    return this.http.post('/cat_mat_sort/delete', params)
  }

  cdnAndCacheRefresh ({ product, classId }: { product: string, classId: string }) {
    return this.http.get(`${RANK_URL}/mat/ranking/one?product=${product}&class=${classId}`, {})
  }
}
