import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/members',
    component: () => import(/* webpackChunkName: "members" */ '../../views/members/members.vue'),
    children: [
      {
        path: 'permission',
        name: 'permission',
        component: () => import(/* webpackChunkName: "members" */ '../../views/members/permission/permission.vue')
      },
      {
        path: 'plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "member" */ '../../views/members/plan/plan.vue')
      }
    ]
  }
]

export default routes
