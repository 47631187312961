import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface AdItemListSearchParams {
  /** 广告id */
  ad_id: number
}

export interface AddAdItemParams {
  /** 广告id */
  ad_id: number
  /** 图片 */
  img: string
  /** 文字 */
  title: string
  /** 链接 */
  url: string
  /** 排序号 */
  order_num: number
  /** 状态：默认为禁用 */
  status: number
}

export interface UpdateAdItemParams {
  /** 广告id */
  ad_id: number
  /** id */
  id: number
  /** 图片url */
  img: string
  /** 文字 */
  title: string
  /** 链接 */
  url: string
  /** 排序号	*/
  order_num: number
  /** 状态：默认为禁用 */
  status: number
}

export interface DeleteAdItemParams {
  /** id */
  id: number
}

export interface AdItemData {
  /** id */
  id: number
  /** 图片url */
  img: string
  /** 文字 */
  title: string
  /** 链接 */
  url: string
  /** 排序号	*/
  order_num: number
  /** 状态：默认为禁用 */
  status: number
  /** 创建时间	*/
  create_time: string
  /** 最后更新时间	*/
  update_time: string
}

export interface AdItemListData {
  list: AdItemData[]
}

export interface AddAdItemResponse {
  id: number
}

@Injectable()
export class AdvertisementItemDialogService {
  constructor(private http: Http) {}

  // 获取广告项列表
  getAdItemList (params: AdItemListSearchParams) {
    return this.http.post<AdItemListData>('/ad/item/list', params).then((response: AdItemListData) => {
      const list = response.list.map((item) => { return {...item, rid: Math.random(), id: String(item.id)} })
      return { list: list }
    })
  }

  // 增加广告项
  addAdItem (params: AddAdItemParams) {
    return this.http.post<AddAdItemResponse>('/ad/item/add', params).then((response: AddAdItemResponse) => {
      return { id: response.id }
    })
  }

  // 更新广告项
  updateAdItem (params: UpdateAdItemParams) {
    return this.http.post('/ad/item/update', params)
  }

  // 删除广告项
  deleteAdItem (params: DeleteAdItemParams) {
    return this.http.post('/ad/item/delete', params)
  }
}
