
import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { PlanService, CheckStatusByLabelMap } from '@/views/members/plan/plan.service'
import { ConstPagination } from '@/common/const'
import { formatDate } from '@shared/utils/base'
import { ElMessage, ElMessageBox } from 'element-plus'

const PlanDialog = defineAsyncComponent(() => import('./_components/planDialog/planDialog.vue'))

export default defineComponent({
  components: { MainLayout, TableLayout, PlanDialog },

  setup() {
    const injector = useReflectiveInjector([PlanService])
    const planService = injector.get(PlanService)

    // 获取套餐列表
    function getPlansList() {
      tableHandle.loading = true

      const params = {
        page_params: {
          page: paginationHandle.page,
          page_size: paginationHandle.size
        },
        package_id: !searchHandle.package_id ? null : Number(searchHandle.package_id),
        package_name: searchHandle.package_name,
        price: !searchHandle.price ? null : Number(searchHandle.price)
      }

      planService.getVipPackageList(params).then((res) => {
        tableHandle.list = res.list
        paginationHandle.total = res.total
      }).finally(() => {
        tableHandle.loading = false
        searchHandle.loading = false
      })
    }

    // 新建套餐句柄
    const newPlanHandle = reactive({
      newMemberPlan: () => {
        planDialogHandle.visible = true
      }
    })

    // 搜索句柄
    const searchHandle = reactive({
      package_id: '',
      package_name: '',
      price: '',
      loading: false,
      search: () => {
        searchHandle.loading = true
        getPlansList()
      },
      reset: () => {
        searchHandle.loading = true
        searchHandle.package_id = ''
        searchHandle.price = ''
        searchHandle.package_name = ''
        getPlansList()
      }
    })

    // 表单句柄
    const tableHandle = reactive({
      loading: false,
      list: [],
      id: null,
      configDetail: (row: any) => {
        planDialogHandle.id = row.id
        planDialogHandle.visible = true
      },
      deletePackage: (row: any) => {
        const params = {
          package_id: row.id
        }
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            planService.deleteVipPackage(params).then(() => {
              ElMessage.success('删除成功!')
              getPlansList()
            })
          }).catch((err: any) => {
          if (err === 'cancel') {
            console.log(err)
          }
        })
      },
      start: (row: any) => {
        planService.updateVipPackage({vip_package: {...row, status: 1}}).then(() => {
          getPlansList()
        })
      },
      forbid: (row: any) => {
        planService.updateVipPackage({vip_package: {...row, status: 0}}).then(() => {
          getPlansList()
        })
      }
    })

    // 分页句柄
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getPlansList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getPlansList()
      }
    })

    // 新建/编辑套餐句柄
    const planDialogHandle = reactive({
      visible: false,
      id: null,
      clickPlanDialogClose() {
        planDialogHandle.visible = false
        planDialogHandle.id = null
      },
      clickPlanDialogSubmit() {
        planDialogHandle.visible = false
        planDialogHandle.id = null
        getPlansList()
      }
    })

    onMounted(() => {
      getPlansList()
    })

    return {
      searchHandle,
      newPlanHandle,
      tableHandle,
      paginationHandle,
      planDialogHandle,

      formatDate,
      CheckStatusByLabelMap
    }
  }
})

