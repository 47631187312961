
import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'
import addDialog from './_components/editDialog/addDialog.vue'
import disposeDialog from './_components/disposeDialog/index.vue'
import { ConstPagination, ClientTypeByLabelMap, ActivityTemplateTypeMap, ActivityTemplateTypeByLabelMap } from '@/common/const'
import { copyToClipboard, objectToArray } from '@/common/utils'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, reactive } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { Activity, StatusActivityLabelMap, StatusActivityMap, TopicActivity } from './topic_activity.service'
import { isDevOrTest } from '@/common/env'

const clientList = Object.freeze([
  { text: '全部', value: '' },
  ...objectToArray(ClientTypeByLabelMap)
])

const statusList = Object.freeze([
  { text: '全部', value: StatusActivityMap.all },
  { text: '开启', value: StatusActivityMap.open },
  { text: '关闭', value: StatusActivityMap.close },
])

const templateList = Object.freeze([
  { text: '全部', value: '' },
  ...objectToArray(ActivityTemplateTypeByLabelMap)
])

export default defineComponent({
  components: { MainLayout, TableLayout, addDialog, disposeDialog },
  setup() {
    const injector = useReflectiveInjector([TopicActivity])
    const topicActivity = injector.get(TopicActivity)

    const searchHandle = reactive({
      clientList,
      statusList,
      templateList,
      client: '',
      name: '',
      status: StatusActivityMap.all,
      template: '',
      resetParams: () => {
        searchHandle.client = ''
        searchHandle.name = ''
        searchHandle.status = StatusActivityMap.all
        searchHandle.template = ''
        paginationHandle.page = 1
        paginationHandle.size = ConstPagination.defaultPageSize
      },
      reset: () => {
        searchHandle.resetParams()
        tableHandle.loadList()
      }
    })

    const tableHandle = reactive({
      activitySelect: {},
      list: [],
      loading: false,
      loadList: () => {
        const req = {
          client_type: searchHandle.client,
          name: searchHandle.name,
          page: paginationHandle.page,
          page_size: paginationHandle.size,
          status: searchHandle.status,
          template: searchHandle.template
        }
        topicActivity.getActivityList(req).then(res => {
          tableHandle.list = res.list
          paginationHandle.total = res.total
        })
      },
      edit: (activity: Activity) => {
        tableHandle.activitySelect = activity
        dialogHandle.visible = true
      },
      dispose: (activity: Activity) => {
        tableHandle.activitySelect = activity
        dialogHandle.showDispose()
      },
      reload: () => {
        dialogHandle.close()
        searchHandle.resetParams()
        tableHandle.loadList()
      },
      delete: (activity: Activity) => {
        topicActivity.deleteActivity({ id: activity.id }).then(() => {
          ElMessage.success('删除成功')
          tableHandle.loadList()
        })
      },
      copyLink: (activity: Activity) => {
        const code = activity.code
        const linkMap = {
          [ActivityTemplateTypeMap.webPay]: isDevOrTest ? `http://ppt-material-web-keniu-test-fe.aix-test-k8s.iweikan.cn/activity/pay618?code=${code}` : `https://o.keniu.com/activity/pay618?code=${code}`,
          [ActivityTemplateTypeMap.wxAppletsTopic]: `/pages/activity/activity?code=${code}`,
          [ActivityTemplateTypeMap.webTopic]: isDevOrTest ? `http://ppt-material-web-keniu-test-fe.aix-test-k8s.iweikan.cn/featuredTopics?code=${code}` : `https://o.keniu.com/featuredTopics?code=${code}`
        }
        copyToClipboard(linkMap[activity.template] || '')
        ElMessage.success('复制成功')
      }
    })

    const dialogHandle = reactive({
      visible: false,
      visibleDispose: false,
      showAddActivity: () => {
        tableHandle.activitySelect = {}
        dialogHandle.visible = true
      },
      showDispose: () => {
        dialogHandle.visibleDispose = true
      },
      close: () => {
        dialogHandle.visible = false
        dialogHandle.visibleDispose = false
      }
    })

    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        tableHandle.loadList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        tableHandle.loadList()
      }
    })

    onMounted(() => {
      tableHandle.loadList()
    })

    return {
      tableHandle,
      searchHandle,
      dialogHandle,
      paginationHandle,
      StatusActivityLabelMap,
      ClientTypeByLabelMap
    }
  }
})
