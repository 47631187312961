import { ResponseChannelData } from '@/services/const.service';
import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { ManageService } from '@/views/manage/manage.service';
import { API_URL } from '@/common/config';
import { unique } from '@/common/utils'

export interface MatFiles {
  /** 格式 */
  format: string
  /** 模板大小 单位B */
  size: number
  /** 页数 */
  page_count: number
  /** 文件名 */
  file_url: string
}

export interface ModifyMatRequestParams {
  /** 模板id */
  id: number
  /** 标题 */
  title?: string
  /** 标签 */
  tag?: string[]
  /** 支付类型 0：免费 1：付费 */
  pay_type?: string
  /** 模板状态 0：已下架 1：已上架 2：待审核 3：已驳回 */
  status?: string
  /** 场景数据 1：web 2：duba 3：ppt 4：liebao 5：pdf */
  scene?: number[]
  /** 小图文件名 */
  small_img?: string
  /** 大图文件名 */
  big_img?: string
  /** 多个格式 */
  max_files?: MatFiles[]
}

@Injectable()
export class ContentManageDialogService {
  constructor(private http: Http, private manageService: ManageService) {}

  // 将big_img字段放到big_imgs里面，并去除big_imgs里面的重复内容
  handleBigImgAndBigImgs (data: any) {
    data.big_imgs.push(data.big_img)
    data.big_imgs = unique(data.big_imgs)
    data.big_imgs = data.big_imgs.filter((e: string) => !!e)
    data.big_img = ''
    return data
  }

  getDataById (id: number) {
    const getMats = this.manageService.matList.find((item) => item.id === id)
    return this.getChannelById(id).then((channel) => {
      return {
        title: getMats.title,
        pay_type: String(getMats.pay_type),
        small_img: getMats.small_img,
        big_img: getMats.big_img,
        big_imgs: getMats.big_imgs,
        class_id: [getMats.class_1, getMats.class_2, getMats.class_3],
        status: getMats.status,
        scene: channel.channels,
        tag: getMats.tag.split(','),
        mat_files: getMats.mat_files.map(file => {
          return { id: Math.random(), ...file, isShow: true }
        }),
        big_img_type: getMats.big_img_type === 0 ? '' : String(getMats.big_img_type),
        big_img_layout: getMats.big_img_layout === 0 ? '' : String(getMats.big_img_layout),
        preview_video: getMats.preview_video
      }
    })
  }

  getChannelById(id: number) {
    return this.http.post<ResponseChannelData>(`${API_URL}/content/matchannels`, {id})
  }

  modifyManageMat(params: ModifyMatRequestParams) {
    const modifyData = this.formatInsertData(params)
    return this.http.post('/manager/modify', modifyData)
  }

  formatInsertData (data: any) {
    return this.handleBigImgAndBigImgs({
      ...data,
      status: String(data.status),
      pay_type: String(data.pay_type),
      small_img: this.formatImgData(data.small_img),
      big_img: this.formatImgData(data.big_img),
      big_imgs: data.big_imgs.map((e: string) => this.formatImgData(e)),
      tag: data.tag.join(','), // 预期是字符串
      class_id: data.class_id[data.class_id.length - 1], // 预期是最后一个id
      big_img_type: Number(data.big_img_type),
      big_img_layout: Number(data.big_img_layout),
      mat_files: data.mat_files.map((item: MatFiles) => {
        Reflect.deleteProperty(item, 'id')
        Reflect.deleteProperty(item, 'isShow')
        return {...item, file_url: this.formatImgData(item.file_url)}
      }),
      preview_video: this.formatImgData(data.preview_video)
    })
  }

  formatImgData (url: string) {
    return url.substring(url.lastIndexOf('\/') + 1, url.length)
  }

  getSelfCheckLog (id: number) {
    return this.http.post('/selfcheck', { mat_id: id })
  }
}
