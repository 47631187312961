
import { defineComponent, reactive, ref, onMounted } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import ClassifyItemDialog from './_components/classifyItemDialog/classifyItemDialog.vue'
import TreeClassifyComponent from './_components/treeClassify/treeClassify.vue'

import { ClientTypeByLabelMap } from '@/common/const'
import { objectToArray } from '@/common/utils'
import { FrontMessageService, TreeListItem } from './frontMessage.service'
import { frontScope } from './di-scope'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

const tabs = objectToArray(ClientTypeByLabelMap)

export default defineComponent({
  components: { MainLayout, TreeClassifyComponent, ClassifyItemDialog },

  setup() {
    const tabsWrapperRef = ref<Element>(null)
    const injector = useReflectiveInjector([], frontScope)
    const frontMessageService = injector.get(FrontMessageService)

    const tabsHandle = reactive({
      tabs,
      panelHeight: 500,
      activeName: tabs[0].value
    })
    const classifyItemDialogHandle = reactive({
      visible: false,
      id: null,
      pid: null,
      detailsData: null,
      adminClassifyData: [],
      disabled: false,
      orderNum: 0,
      level: 1,
      productId: Number(tabs[0].value)
    })

    onMounted(() => {
      const tabNavHeight = 80
      tabsHandle.panelHeight = tabsWrapperRef.value.clientHeight - tabNavHeight
    })

    frontMessageService.setCurrentTab(tabsHandle.activeName)

    const handleTabsClick = (tab: any) => {
      classifyItemDialogHandle.productId = Number(tab.props.name)
      frontMessageService.setCurrentTab(tab.props.name)
    }

    const handleAddClick = () => {
      classifyItemDialogHandle.visible = true
      classifyItemDialogHandle.orderNum = frontMessageService.getCurrentTreeList.length + 1
      classifyItemDialogHandle.level = 1
    }

    const clickClassifyItemDialogClose = () => {
      classifyItemDialogHandle.visible = false
      classifyItemDialogHandle.id = null
      classifyItemDialogHandle.detailsData = null
      classifyItemDialogHandle.pid = null
      classifyItemDialogHandle.disabled = false
    }

    const clickClassifyItemDialogSubmit = () => {
      frontMessageService.initCurrentTabTreeData()
      clickClassifyItemDialogClose()
    }

    const handleTreeEdit = (item: TreeListItem) => {
      classifyItemDialogHandle.id = item.id
      classifyItemDialogHandle.detailsData = item
      classifyItemDialogHandle.visible = true
    }

    const handleTreeCheck = (item: TreeListItem) => {
      classifyItemDialogHandle.id = item.id
      classifyItemDialogHandle.detailsData = item
      classifyItemDialogHandle.visible = true
      classifyItemDialogHandle.disabled = true
    }

    const handleTreeAddChild = (item: TreeListItem) => {
      classifyItemDialogHandle.visible = true
      classifyItemDialogHandle.pid = item.id
      classifyItemDialogHandle.orderNum = item.children.length + 1
      classifyItemDialogHandle.level = item.level + 1
    }

    return {
      tabsWrapperRef,
      classifyItemDialogHandle,
      tabsHandle,
      clickClassifyItemDialogClose,
      clickClassifyItemDialogSubmit,
      handleAddClick,
      handleTabsClick,
      handleTreeEdit,
      handleTreeCheck,
      handleTreeAddChild
    }
  }
})
