import { RouteRecordRaw } from 'vue-router';
import RouterView from '@/components/routerView/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/classify',
    component: RouterView,
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "classify" */ '@/views/classify/admin/admin.vue')
      },
      {
        path: 'front',
        name: 'front',
        component: () => import(/* webpackChunkName: "member" */ '@/views/classify/front/front.vue')
      }
    ]
  }
]

export default routes
