
import { defineComponent, onMounted, onUnmounted, onUpdated, ref } from 'vue'

import Editor from 'jsoneditor'
import _, { isPlainObject } from 'lodash'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    json: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['validateData', 'setData', 'getData'],

  setup(props) {
    let _editor: any = null
    const editorDom = ref<any>(null)

    function initEditor() {
      _editor = new Editor(editorDom.value, { mode: 'code' })
    }

    function setData (data: any)  {
      if (!_editor) {
        this.initEditor()
      }
      if (!(Array.isArray(data) || isPlainObject(data))) {
        data = {}
      }
      _editor.set(data)
    }

    /** 不提供错误检测，外部使用是，先使用validateData自行检测 */
    function getData () {
      return _editor.get()
    }

    function validateData () {
      try {
        _editor.get()
        return true
      } catch (err) {
        return false
      }
    }

    onMounted(() => {
      initEditor()
      setData(props.json)
    })

    onUpdated(() => { setData(props.json) })

    onUnmounted(() => {
      _editor.destroy()
      _editor = null
    })

    return {
      editorDom,

      setData, // ref对外提供支持
      getData, // ref对外提供支持， 需要先使用validateData先检测
      validateData // ref对外支持
    }
  },
})
