
import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
import { ConstPagination } from '@/common/const';
import { MatsManageResponseData, ManageService } from '@/views/manage/manage.service';
import { formatDate } from '@shared/utils/base';
import { CheckStatusMap, CheckStatusByLabelMap, CheckStatusByTypeMap } from '@/common/const';

const ContentManageDialog = defineAsyncComponent(() => import('./_components/contentManageDialog/contentManageDialog.vue'))

const titleStr = 'title'
const searchList = Object.freeze([
  { label: '模板ID', value: 'id' },
  { label: '模板标题', value: titleStr },
  { label: '设计师ID', value: 'designer_id' }
])

export default defineComponent({
  components: { MainLayout, TableLayout, ContentManageDialog },

  setup() {
    const injector = useReflectiveInjector([ManageService])
    const manageService = injector.get(ManageService)

    function getMatsList() {
      tableHandle.loading = true

      const params = { page: paginationHandle.page, size: paginationHandle.size }
      if (searchHandle.type) {
        params[searchHandle.type] = searchHandle.type === titleStr ? searchHandle.value : Number(searchHandle.value) || null
      }

      manageService.getManagerMats(params).then((data: MatsManageResponseData) => {
        tableHandle.mats = data.list
        paginationHandle.total = data.total
      }).finally(() => {
        searchHandle.loading = false
        tableHandle.loading = false
      })
    }

    function changeStatus(id: number, status: number) {
      const params = { id: id, status: String(status) }

      manageService.modifyManageMat(params).then(() => {
        getMatsList()
      })
    }

    const searchHandle = reactive({
      type: '',
      list: searchList,
      loading: false,
      value: '',
      search: () => {
        searchHandle.loading = true
        getMatsList()
      }
    })

    const tableHandle = reactive({
      mats: [],
      loading: false,
      clickDetail: (id: number) => {
        contentManageDialogHandle.visible = true
        contentManageDialogHandle.id = id
      },
      upperShelfMat: (id: number) => {
        changeStatus(id, CheckStatusMap.up)
      },
      lowerShelfMat: (id: number) => {
        changeStatus(id, CheckStatusMap.down)
      },
      deleteShelfMat: (id: number) => {
        changeStatus(id, CheckStatusMap.delete)
      }
    })

    const contentManageDialogHandle = reactive({
      visible: false,
      id: null,
      clickContentManageDialogClose () {
        contentManageDialogHandle.visible = false
      },
      clickContentManageDialogModify () {
        contentManageDialogHandle.visible = false
        getMatsList()
      }
    })

    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getMatsList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getMatsList()
      }
    })

    function sortByDesignerId (scope: any) {
      tableHandle.mats = manageService.orderMats(scope.prop, scope.order)
    }

    onMounted(() => { getMatsList() })

    return {
      CheckStatusMap,
      CheckStatusByLabelMap,
      CheckStatusByTypeMap,
      searchHandle,
      tableHandle,
      paginationHandle,
      contentManageDialogHandle,

      sortByDesignerId,
      formatDate
    }
  }
})
