
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import { PlanDialogService, Types } from '@/views/members/plan/_components/planDialog/planDialog.service';
import { ElMessage } from 'element-plus';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';

export default defineComponent({
  props: {
    planDialog: {
      type: Boolean,
      default: false
    },

    id: {
      type: Number,
      default: null
    }
  },

  emits: ['clickPlanDialogClose', 'clickPlanDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([PlanDialogService])
    const planService = injector.get(PlanDialogService)
    const formRef = ref(null)

    // 会员套餐句柄
    const dialogHandle = reactive({
      visible: props.planDialog,
      loading: false,
      formData: {
        name: '',
        inner_name: '',
        tips: '',
        detail: '',
        real_price: '',
        price: '',
        discount_price: '',
        ext_arr: '',
        order_num: '',
        status: 1,
        channel: '',
        type_id: '',
        type: 0,
      },
      disabled: !!props.id,
      close: () => {
        ctx.emit('clickPlanDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            if (props.id) {
              const params = {
                id: props.id as number,
                name: dialogHandle.formData.name,
                inner_name: dialogHandle.formData.inner_name,
                tips: dialogHandle.formData.tips,
                detail: dialogHandle.formData.detail,
                real_price: Number(dialogHandle.formData.real_price),
                price: Number(dialogHandle.formData.price),
                discount_price: Number(dialogHandle.formData.discount_price),
                ext_arr: dialogHandle.formData.ext_arr,
                order_num: Number(dialogHandle.formData.order_num),
                status: dialogHandle.formData.status,
                channel: dialogHandle.formData.channel,
                type: Number(dialogHandle.formData.type)
              }
              planService.updateVipPackage({vip_package: params}).then(() => {
                ctx.emit('clickPlanDialogSubmit')
              })
            } else {
              const params = {
                name: dialogHandle.formData.name,
                inner_name: dialogHandle.formData.inner_name,
                tips: dialogHandle.formData.tips,
                detail: dialogHandle.formData.detail,
                real_price: Number(dialogHandle.formData.real_price),
                price: Number(dialogHandle.formData.price),
                discount_price: Number(dialogHandle.formData.discount_price),
                ext_arr: dialogHandle.formData.ext_arr,
                order_num: Number(dialogHandle.formData.order_num),
                status: dialogHandle.formData.status,
                channel: dialogHandle.formData.channel,
                type: Number(dialogHandle.formData.type)
              }
              planService.addVipPackage({vip_type_id: Number(dialogHandle.formData.type_id), vip_package: params}).then(() => {
                ctx.emit('clickPlanDialogSubmit')
              })
            }
          } else {
            tipFunc()
            return false
          }
        })
      }
    })

    if (props.id) {
      dialogHandle.formData = planService.getDataById(props.id as number)
    }

    const typesOptions = ref<Types[]>([])
    const typesSub = planService.typesData.subscribe(type => {
      typesOptions.value = type
    })

    function tipFunc(msg?: string, type?: string) {
      ElMessage[type || 'warning'](msg || '请填写完整信息')
    }

    onUnmounted(() => typesSub.unsubscribe())

    return {
      formRef,
      dialogHandle,
      typesOptions
    }
  }
})
