import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library';
import { strictExtend } from '@shared/utils/base';

export interface UpdatePackageItem {
  /** 主键id */
  id: number
  /** 套餐备注 */
  inner_name: string
  /** 套餐名称	*/
  name: string
  /** 产品原价	*/
  price: number
  /** 优惠价格 */
  discount_price: number
  /** 实际定价	*/
  real_price: number
  /** 套餐介绍	*/
  detail: string
  /** 套餐标签	*/
  tips: string
  /** 拓展字段 */
  ext_arr: string
  /** 套餐排序	*/
  order_num: number
  /** 启用状态：0-禁用，1-启用，默认启用 */
  status: number
  /** 渠道名称	*/
  channel: string
}

export interface PackageList {
  /** 会员权限id */
  vip_item_id: number
  /** 会员权限名称 */
  vip_item_name: string
  /** id */
  id: number
  /** 套餐备注 */
  inner_name: string
  /** 套餐名称 */
  name: string
  /** 产品原价 */
  price: number
  /** 优惠价格 */
  discount_price: number
  /** 实际定价	*/
  real_price: number
  /** 套餐介绍 */
  detail: string
  /** 套餐标签 */
  tips: string
  /** 拓展字段	*/
  ext_arr: string
  /** 套餐排序	*/
  order_num: number
  /** 启用状态：0-禁用，1-启用 */
  status: number
  /** 渠道名称 */
  channel: string
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string
  /** 最后一次操作修改人 */
  last_operator: string
  /** 套餐类型: 0-全站套餐，1-升级套餐 */
  type: number
}

export interface PageParams {
  /** 页数 */
  page: number
  /** 页的大小 */
  page_size: number
}

export interface PackageSearchParams {
  /** 会员id */
  vip_id?: number
  /** 会员名称 */
  vip_name?: string
  /** 最后一次修改人 */
  last_operator?: string,
  /** 页数字段 */
  page_params: PageParams
}

export interface PackageUpdateParams {
  vip_package: UpdatePackageItem
}

export interface PackageDeleteParams {
  package_id: number
}

export interface PackageListResponseData {
  data: {
    list: PackageList[],
    total: number
  }
}

/** 套餐状态 */
export const CheckStatusMap = {
  start: 1,
  forbid: 0
}

/** 套餐状态对应的中文名 */
export const CheckStatusByLabelMap = {
  [CheckStatusMap.start]: '启用',
  [CheckStatusMap.forbid]: '禁用'
}

@Injectable()
export class PlanService {
  packageVipList: PackageList[] = []

  constructor(private http: Http) {}

  getVipPackageList (params: PackageSearchParams) {
    return this.http.post<PackageListResponseData>('/vip/package/search', params).then((response: PackageListResponseData) => {
      const data = response.data
      this.packageVipList = strictExtend(data.list) as PackageList[]

      return { list: data.list, total: data.total }
    })
  }

  deleteVipPackage (params: PackageDeleteParams) {
    return this.http.post('/vip/package/delete', params)
  }

  updateVipPackage (params: PackageUpdateParams) {
    return this.http.post('/vip/package/update', params)
  }
}
