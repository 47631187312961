
import {useReflectiveInjector} from '@tanbo/vue-di-plugin'
import { ClientTypeByLabelMap, ActivityTemplateTypeByLabelMap } from '@/common/const'
import { objectToArray } from '@/common/utils'
import {computed, defineComponent, reactive, ref} from 'vue'
import {StatusActivityMap} from '@/views/marketing/topic_activity/topic_activity.service'
import {
  ActivityAddService,
  RequestActivityEdit
} from '@/views/marketing/topic_activity/_components/editDialog/addDialog.service'

const typeList = Object.freeze([
  { text: '图文广告', value: 1 },
  { text: '文字广告', value: 2 },
  { text: '轮播图', value: 3 }
])

const clientList = Object.freeze([
  ...objectToArray(ClientTypeByLabelMap)
])

const templateList = Object.freeze([
  ...objectToArray(ActivityTemplateTypeByLabelMap)
])

export default defineComponent({
  props: {
    activity: {
      type: Object,
      default: () => ({})
    },
  },

  emits: ['close', 'sumbit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([ActivityAddService])
    const activityAddService = injector.get(ActivityAddService)
    const isEdit = computed(() => !!props.activity?.id)

    const formRef = ref(null)

    const rules = reactive({
      name: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
        {
          max: 15,
          message: '长度超过15个字符',
          trigger: 'blur',
        },
      ],
      client_type: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      code: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      template: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      start_time: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      end_time: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
    })

    // 表单句柄，用于控制表单输入
    const dialogHandle = reactive({
      formData: {
        name: '',
        client_type: '',
        code: '',
        template: '',
        start_time: '',
        end_time: '',
        status: StatusActivityMap.close
      },
      reset: () => {
        dialogHandle.formData = {
          name: '',
          client_type: '',
          code: '',
          template: '',
          start_time: '',
          end_time: '',
          status: StatusActivityMap.close
        }
      },
      close: () => {
        ctx.emit('close')
      },
      submit: () => {
        formRef.value?.validate(async (valid: boolean) => {
          dialogHandle.formData.code = dialogHandle.formData.code.trim()
          if (valid) {
            if (isEdit.value) {
              await activityAddService.activityEdit(dialogHandle.formData)
            } else {
              await activityAddService.activityAdd(dialogHandle.formData)
            }
            ctx.emit('sumbit')
            ctx.emit('close')
          }
        })
      },
      handleSwitchChange: () => {
        if (dialogHandle.formData.status === StatusActivityMap.open) {
          dialogHandle.formData.status = StatusActivityMap.close
        } else {
          dialogHandle.formData.status = StatusActivityMap.open
        }
      }
    })

    if (isEdit.value) {
      dialogHandle.formData = props.activity as RequestActivityEdit
    }

    return {
      isEdit,
      formRef,
      dialogHandle,

      typeList,
      clientList,
      templateList,
      rules,
      StatusActivityMap
    }
  }
})
