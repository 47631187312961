
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive } from 'vue'
import { Uploader } from '../uploader.service'
import { UploadFileType } from '@/common/const';
import { Plus as PlusIcon } from '@element-plus/icons-vue'
import { isDevOrTest } from '@/common/env'

export default defineComponent({
  components: { PlusIcon },
  props: {
    defaultImg: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showImgList: {
      type: Array,
      default: () => [] as any[]
    }
  },

  emits: ['fileInfo'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([Uploader])
    const uploaderService = injector.get(Uploader)

    const uploadHandle = reactive({
      imageUrl: '' as string | ArrayBuffer,
      imageUrlList: [],
      loading: false,
    })

    function httpImgUpload (options: any) {
      uploadHandle.loading = true

      uploaderService.uploadFileResultFileName(options, UploadFileType.img).then(info => {
        ctx.emit('fileInfo', info)
      }).finally(() => { uploadHandle.loading = false })
    }

    function beforeUpload (file: File) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (props.multiple) {
          uploadHandle.imageUrlList.push(reader.result)
        } else {
          uploadHandle.imageUrl = reader.result
        }
      }
      reader.readAsDataURL(file)
    }

    return {
      httpImgUpload,
      uploadHandle,
      beforeUpload
    }
  },

  computed: {
    showImg() {
      return this.uploadHandle.imageUrl || this.defaultImg
    },
    imageUrlList () {
      return this.showImgList.map((url: string) => {
        if (url.startsWith('http')) {
          return url
        }
        const pre = isDevOrTest ? 'https://img.777ppt.com/test/mat_img/' : 'https://img.777ppt.com/mat_img/'
        return pre + url
      })
    }
  }
})
