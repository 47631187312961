
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { TreeClassifyService } from './treeClassify.service';
import { ElMessage, ElMessageBox } from 'element-plus'
import type Node from 'element-plus/es/components/tree/src/model/node'
import type { DropType } from 'element-plus/es/components/tree/src/tree.type'
import { FrontMessageService, TreeListItem } from '../../frontMessage.service';

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: null
    },
  },

  emits: ['onEdit', 'onCheck', 'onAddChild', 'onDelete'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([TreeClassifyService])
    const treeService = injector.get(TreeClassifyService)
    const frontMessageService = injector.get(FrontMessageService)
    const treeHandle = reactive({
      data: [],
      loading: true,
      defaultProps: {
        label: 'name'
      }
    })

    const treeEventHandle = {
      handleDrop(draggingNode: Node) {
        const ids = frontMessageService.findSiblingDataById(draggingNode.data.id).map((item, index) => {
          return {
            order_num: index + 1,
            mat_show_class_id: item.id
          }
        })

        treeService.updateTreeSort(ids).then(() => {
          ElMessage.closeAll()
          ElMessage.success('排序更新成功')
        })
      },
      allowDrop(draggingNode: Node, dropNode: Node, type: DropType) {
        if (type === 'inner') {
          return false
        }

        if (draggingNode.data.pid === dropNode.data.pid) {
          return true
        }

        return false
      }
    }

    const handleDeleteNode = (id: number) => {
      ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '暂不删除',
        type: 'warning',
      }).then(() => {
        treeService.deleteNode(id).then(() => {
          frontMessageService.initCurrentTabTreeData()
          ElMessage.success('节点删除成功!')
        })
      }).catch(() => {})
    }

    const handleSwitchClick = (item: TreeListItem) => {
      item.status = item.status === 1 ? -1 : 1
      treeService.updateStatus({ id: item.id, status: item.status }).catch(item => {
        item.status = item.status === 1 ? -1 : 1
      })
    }

    frontMessageService.initCurrentTabTreeData().then(res => {
      treeHandle.loading = false
    })

    frontMessageService.currentTreeList.subscribe(data => {
      treeHandle.data = data
      if (data.length) {
        treeHandle.loading = false
      }
    })

    return {
      handleDeleteNode,
      handleSwitchClick,
      treeHandle,
      treeEventHandle
    }
  }
})
