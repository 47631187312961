
import { defineComponent, reactive, onMounted, ref } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ConstPagination } from '@/common/const'
import { formatDate } from '@shared/utils/base'
import { List, QrcodeService, ReplyContent, RequestItemParams, ResponseData } from './qrcode.service'

import { ElForm, ElMessage } from 'element-plus'
import { copyToClipboard } from '@/common/utils'

const QR_STR_SCENE = 'QR_STR_SCENE'
const QR_LIMIT_STR_SCENE = 'QR_LIMIT_STR_SCENE'

const qrTypeMap = Object.freeze([
  { label: '临时二维码', value: QR_STR_SCENE },
  { label: '永久二维码', value: QR_LIMIT_STR_SCENE },
])
const qrTypeStrMap = {
  [QR_STR_SCENE]: '临时二维码',
  [QR_LIMIT_STR_SCENE]: '永久二维码'
}

const on = 1
const off = 2
const statusStrMap = {
  [on]: '开启',
  [off]: '禁止'
}
const qrStatusMap = Object.freeze([
  { label: '开启', value: 1 },
  { label: '禁止', value: 2 },
])

const defaultReplyType = 1
const textReplyType = 2
const imgReplyType = 3
const appletsReplyType = 4

const replayTypeMap = Object.freeze([
  { label: '默认回复', value: defaultReplyType },
  { label: '文本', value: textReplyType },
  { label: '图片', value: imgReplyType },
  { label: '小程序卡片', value: appletsReplyType },
])

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  components: { MainLayout, TableLayout },

  setup() {
    const injector = useReflectiveInjector([QrcodeService])
    const qrcodeService = injector.get(QrcodeService)

    /** 表单验证 */
    const rules = reactive({
      scene_name: [
        { required: true, message: '请输入二维码名称', trigger: 'blur' },
        { min: 1, max: 256, message: '最大长度256', trigger: 'blur' },
      ],
      expire_seconds: [
        { required: true, message: '请选择失效时间', trigger: 'change' },
      ]
    })

    const rules_reply = reactive({
      text: [
        { required: true, message: '请输入文本内容', trigger: 'blur' },
      ],
      media_id: [
        { required: true, message: '请输入图片id', trigger: 'blur' },
      ],
      miniprogram_title: [
        { required: true, message: '请输入小程序标题', trigger: 'blur' },
      ],
      miniprogram_appid: [
        { required: true, message: '请输入小程序appid', trigger: 'blur' },
      ],
      miniprogram_pagepath: [
        { required: true, message: '请输入小程序路径', trigger: 'blur' },
      ],
      miniprogram_thumb_media_id: [
        { required: true, message: '请输入小程序图片id', trigger: 'blur' },
      ],
    })

    // 获取列表
    function getQrcodeList() {
      tableHandle.loading = true

      const params = {
        scene_name: searchHandle.scene_name,
        scene_url: '', // 暂时无用
        scene_type: searchHandle.scene_type,
        status: searchHandle.status,
        page: paginationHandle.page,
        page_size: paginationHandle.size
      }
      qrcodeService.getQrcodeList(params).then((data: ResponseData) => {
        tableHandle.list = data.list.map(item => {
          return {
            ...item,
            create_time: item.create_time * 1000,
            update_time: item.update_time * 1000,
            expire_seconds: (item.create_time + item.expire_seconds) * 1000
          }
        })
        paginationHandle.total = data.total
      }).finally(() => {
        searchHandle.loading = false
        tableHandle.loading = false
      })
    }

    // 搜索句柄
    const searchHandle = reactive({
      loading: false,
      scene_name: '',
      scene_type: '',
      status: null,
      qrTypeMap: qrTypeMap,
      qrStatusMap: qrStatusMap,
      addItem() {
        // 新增内容就将默认内容赋值过去
        itemDialog.itemData = { ...itemDialog.defaultItemData, reply_content: {} }
        itemDialog.visible = true
      },
      search: () => {
        searchHandle.loading = true
        getQrcodeList()
      },
      reset: () => {
        searchHandle.loading = true
        searchHandle.scene_name = ''
        searchHandle.scene_type = ''
        searchHandle.status = null
        getQrcodeList()
      }
    })

    //
    const tableHandle = reactive({
      list: [],
      loading: false,
      configDetail: (item: List) => {
        itemDialog.visible = true
        itemDialog.itemData = { ...item, reply_content: { ...item.reply_content } } // 深拷贝
      },
      copyUrl: (url: string) => {
        copyToClipboard(url)
        ElMessage({ type: 'success', message: '拷贝成功' })
      }
    })

    // 分页句柄
    const paginationHandle = reactive({
      total: 0,
      page: 1,
      size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.size = page
        getQrcodeList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getQrcodeList()
      }
    })

    // 订单详情表单
    const itemDialog = reactive({
      visible: false,
      defaultItemData: {
        scene_name: '',
        scene_type: QR_STR_SCENE,
        expire_seconds: null,
        status: 1,
        reply_type: defaultReplyType,
        reply_content: {} as ReplyContent
      },
      itemData: {} as List,
      clickItemDialogClose: () => {
        itemDialog.visible = false
      },
      clickItemDialogSubmit(formRef: FormInstance | undefined, formRef_reply: FormInstance | undefined, formData: any) {
        if (!formRef || !formRef_reply) return
        formRef.validate((valid) => {
          if (valid) {
            formRef_reply.validate((valid_reply) => {
              if (valid_reply) {
                if (formData.id) {
                  itemDialog.editItem(formData)
                } else {
                  itemDialog.newItem(formData)
                }
              }
            })
          }
        })
      },
      newItem (data: RequestItemParams) {
        let expireTime = 0
        if (data.scene_type === QR_STR_SCENE) {
          const curTime = new Date().getTime() // 今天凌晨
          expireTime = new Date(data.expire_seconds).getTime() - curTime
        }

        const requestParams = {
          scene_type: data.scene_type,
          scene_name: data.scene_name,
          expire_seconds: Math.ceil(expireTime / 1000),
          reply_type: data.reply_type,
          status: data.status,
          reply_content: data.reply_content
        }
        tableHandle.loading = true
        qrcodeService.getWxmpSceneCreate(requestParams).then(() => {
          getQrcodeList()
        }).finally(() => {
          itemDialog.visible = false
        })
      },
      editItem(data: List) {
        const requestParams = {
          id: data.id,
          scene_name: data.scene_name,
          reply_type: data.reply_type,
          status: data.status,
          reply_content: data.reply_content
        }
        tableHandle.loading = true
        qrcodeService.updateWxmpScene(requestParams).then(() => {
          getQrcodeList()
        }).finally(() => {
          itemDialog.visible = false
        })
      }
    })

    onMounted(() => { getQrcodeList() })

    return {
      searchHandle,
      tableHandle,
      paginationHandle,
      itemDialog,

      formatDate,
      statusStrMap,
      replayTypeMap,
      defaultReplyType,
      textReplyType,
      imgReplyType,
      appletsReplyType,
      qrTypeStrMap,
      on,
      off,
      QR_STR_SCENE,

      rules,
      rules_reply,
      formRef: ref<FormInstance>(),
      formRef_reply: ref<FormInstance>(),

      dialogDisabledDate: (time: Date) => {
        const nowTiem = Date.now()
        const minTime = (nowTiem - (24 * 60 * 60 * 1000)) > time.getTime()
        const maxTime = nowTiem + (30 * 24 * 60 * 60 * 1000) < time.getTime()
        return minTime || maxTime
      }
    }
  }
})
