import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { RANK_URL } from '@/common/config'

export interface Classify {
  /** 分类id */
  classify_id: number
  /** 分类名称	*/
  classify_name: string
  /** 分类等级	*/
  classify_level: number
  /** 1级分类名称 */
  cat1_name: string
  /** 2级分类名称 */
  cat2_name: string
  /** 0 禁用 1启用 */
  status: number
  /** 排序值	*/
  order_num: number
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string

  // 以下是前端自定义字段
  isNew: boolean
  isEdit: boolean
}

export interface ClassifyList {
  list: Classify[]
}
export interface QuerySortParams {
  /** 唯一id */
  id?: string
  /** 自定义搜索置顶id */
  search_top_id?: string
  /** 关键字 */
  keyword?: string
}

export interface QueryClassifyParams {
  /** 起始页数	*/
  page: number
  /** 页容量	*/
  page_size: number
  /** 上下线状态，0 禁用 1启用,搜索筛选项 */
  status: number
}

export interface AddCatSortParams {
  /** 自定义搜索置顶id */
  search_top_id: string
  /** 关键字 */
  keyword: string
  /** 排序列表json 参考：[19276, 2004122, 20049, 2003977, 2003976] */
  mat_sort: string
}

export interface DeleteCatSortParams {
  /** 分类排序标识	*/
  id: string
}

export interface UpdateCatSortParams {
  /** 唯一id */
  id: string
  /** 自定义搜索置顶id */
  search_top_id: string
  /** 关键字 */
  keyword: string
  /** 排序列表json 参考：[19276, 2004122, 20049, 2003977, 2003976] */
  mat_sort: string
}

export interface CatMatSortItem {
  /** 唯一id */
  id?: string
  /** 自定义搜索置顶id */
  search_top_id?: string
  /** 关键字 */
  keyword?: string
  /** 排序列表json 参考：[19276, 2004122, 20049, 2003977, 2003976] */
  mat_sort?: string
  /** 创建时间 */
  create_time?: string
  /** 更新时间 */
  update_time?: string

  // 以下是前端自定义字段
  isNew?: boolean
  isEdit?: boolean
}

export interface CatSortList {
  list: CatMatSortItem[]
  total: number
}

export interface ClassifyLableValue {
  value: number
  label: string
}

@Injectable()
export class ConfigService {
  constructor(private http: Http) {}

  /** 查询分类素材排序 */
  queryCatSortList (params: QuerySortParams) {
    return this.http.post<CatSortList>('/searchtop/list_content_top', params).then((response) => {
      return {
        list: response.list,
        total: Number(response.total)
      }
    })
  }

  /** 查询分类列表 */
  queryClassifyList (params: QueryClassifyParams) {
    return this.http.post('/classify/search', params).then((res) => {
      return {
        list: res.list,
        total: Number(res.total)
      }
    })
  }

  getAllClassifyList() {
    const params = {
      page: 1,
      page_size: 1000,
      status: 1
    }
    return this.queryClassifyList(params)
      .then<ClassifyLableValue[]>((res: ClassifyList) => {
        return res.list.map((item: Classify) => {
          return {
            value: item.classify_id,
            label: item.classify_name
          }
        })
      })
  }

  /** 添加分类素材排序 */
  addClassifySort (params: AddCatSortParams) {
    return this.http.post('/searchtop/add_content_top', params)
  }

  /** 更新分类素材排序 */
  updateClassifySort (params: UpdateCatSortParams) {
    return this.http.post('/searchtop/update_content_top', params).then((res) => {
      return res
    })
  }

  /** 删除分类素材排序 */
  deleteClassifySort (params: DeleteCatSortParams) {
    return this.http.post('/searchtop/delete_content_top', params)
  }

  cdnAndCacheRefresh ({ product, classId }: { product: string, classId: string }) {
    return this.http.get(`${RANK_URL}/mat/ranking/one?product=${product}&class=${classId}`, {})
  }
}
