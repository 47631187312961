
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, ref } from 'vue'
import { Uploader } from '../uploader.service'
import { UploadFileType } from '@/common/const';

export default defineComponent({
  emits: ['fileInfo'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([Uploader])
    const uploaderService = injector.get(Uploader)

    const loading = ref<boolean>(false)

    function httpFileUpload (options: any) {
      loading.value = true

      uploaderService.uploadFileResultFileName(options, UploadFileType.file).then(info => {
        ctx.emit('fileInfo', info)
      }).finally(() => { loading.value = false })
    }

    return {
      httpFileUpload,
      loading
    }
  },
})
