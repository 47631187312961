import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface AddSortParams {
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码	*/
  product_code?: string
  /** 状态码 -1禁用1启用 */
  statue?: number
}

export interface UpdateSortParams {
  /** id */
  id?: number
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码	*/
  product_code?: string
  /** 状态码 -1禁用1启用 */
  statue?: number
}

@Injectable()
export class NewSortItemDialogService {
  constructor(private http: Http) {}

  /** 添加排序列表 */
  addSortMain (params: AddSortParams) {
    return this.http.post('/sort_main/add', params)
  }

  /** 更新排序列表 */
  updateSortMain (params: UpdateSortParams) {
    return this.http.post('/sort_main/update', params)
  }
}
