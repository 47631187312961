
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { AdvertisementItemDialogService } from '@/views/marketing/advertisement/_components/advertisementItemDialog/advertisementItemDialog.service'
import { UploadReturnInfo } from '@/components/uploader/uploader.service'
import imgUploader from '@/components/uploader/imgUploader/index.vue'
import { ElMessage } from 'element-plus';
import { Delete as deleteIcon } from '@element-plus/icons-vue'

export default defineComponent({
  components: { imgUploader },

  props: {
    advertisementItemVisible: {
      type: Boolean,
      default: false
    },

    adId: {
      type: Number,
      default: null
    },

    type: {
      type: Number,
      default: null
    }
  },

  emits: ['clickAdvertisementItemDialogClose'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([AdvertisementItemDialogService])
    const advertisementService = injector.get(AdvertisementItemDialogService)

    // 生成广告项
    const getAdItem = () => {
      return {
        id: '',
        rid: Math.random(),
        /** 图片 */
        img: '',
        /** 文字 */
        title: '',
        /** 链接 */
        url: '',
        /** 排序号 */
        order_num: 0,
        /** 状态：默认为禁用 */
        status: 1
      }
    }

    // 获取广告项列表
    function getItemList() {
      advertisementService.getAdItemList({ad_id: props.adId as number}).then((res) => {
        dialogHandle.itemList = res.list
      })
    }

    // 获取当前最大的序号
    function getMaxOrderNum() {
      let maxNum = 0
      for (let i = 0; i < dialogHandle.itemList.length; i++) {
        if (dialogHandle.itemList[i].order_num > maxNum) {
          maxNum = dialogHandle.itemList[i].order_num
        }
      }
      return maxNum + 1
    }

    // 表单句柄
    const dialogHandle = reactive({
      itemList: [],
      pushItem: () => {
        dialogHandle.itemList.push(getAdItem())
      },
      delItem: (index: number) => {
        if (dialogHandle.itemList[index].id) {
          advertisementService.deleteAdItem({id: Number(dialogHandle.itemList[index].id)}).then(() => {
            ElMessage.success('删除成功')
            dialogHandle.itemList.splice(index, 1)
          })
        } else {
          dialogHandle.itemList.splice(index, 1)
        }
      },
      handleImg: (info: UploadReturnInfo, index: number) => {
        dialogHandle.itemList[index].img = info.name
      },
      close: () => {
        ctx.emit('clickAdvertisementItemDialogClose')
      },
      submit: (index: number) => {
        if (dialogHandle.itemList[index].id) {
          const params = {
            ad_id: props.adId as number,
            id: Number(dialogHandle.itemList[index].id),
            img: dialogHandle.itemList[index].img,
            title: dialogHandle.itemList[index].title,
            url: dialogHandle.itemList[index].url,
            order_num: dialogHandle.itemList[index].order_num,
            status: dialogHandle.itemList[index].status
          }
          advertisementService.updateAdItem(params).then(() => {
            ElMessage.success('更新成功')
          })
        } else {
          dialogHandle.itemList[index].order_num = getMaxOrderNum()
          const params = {
            ad_id: props.adId as number,
            img: dialogHandle.itemList[index].img,
            title: dialogHandle.itemList[index].title,
            url: dialogHandle.itemList[index].url,
            order_num: dialogHandle.itemList[index].order_num,
            status: dialogHandle.itemList[index].status
          }
          advertisementService.addAdItem(params).then((res) => {
            dialogHandle.itemList[index].id = String(res.id)
            ElMessage.success('新建成功')
          })
        }
      }
    })

    onMounted(() => { getItemList() })

    return {
      deleteIcon,
      dialogHandle
    }
  },

  computed: {
    AbleAddAdItem() {
      return ((this.type === 1 || this.type === 2) && this.dialogHandle.itemList.length === 1) || (this.type === 3 && this.dialogHandle.itemList.length === 10)
    }
  }
})
