
import { defineAsyncComponent, defineComponent } from 'vue'

const Aside = defineAsyncComponent(() => import('./aside.vue'))
const UserInfo = defineAsyncComponent(() => import('./UserInfo.vue'))

export default defineComponent({
  components: { Aside, UserInfo },

  setup() {

  },
})
