import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { strictExtend } from '@shared/utils/base';

export interface PageParams {
  /** 页数 */
  page: number
  /** 页的大小 */
  page_size: number
}

export interface TypeSearchRequestParams {
  /** 会员id */
  vip_id?: number
  /** 会员名称 */
  vip_name?: string
  /** 最后一次修改人 */
  last_operator?: string
  /** 页码配置 */
  page_params: PageParams
}

export interface VipItemType {
  /** 主键id */
  aid: number
  /** 会员权限名称	*/
  name: string
  /** 分类权限：逗号分隔；*表示有全部分类 */
  limit_class: string
  /** 会员等级 */
  level: number
  /** 有效期 */
  duration: number
  /** 每天最大下载次数 */
  limit_num: number
  /** 启用状态 */
  status: number
}

export interface AddVipItemType {
  /** 会员套餐 */
  name: string
  /** 会员等级 */
  level: number
  /** 有效期 */
  duration: number
  /** 每天最大下载次数 */
  limit_num: number
  /** 启用状态 */
  status: number
}

export interface TypeUpdateParams {
  vip_item_type: VipItemType
}

export interface TypeDeleteParams {
  /** id */
  id: number
}

export interface TypeAddParams {
  vip_item_type: AddVipItemType
}

/** 套餐状态 */
export const CheckStatusMap = {
  start: 1,
  forbid: -1
}

/** 套餐状态对应的中文名 */
export const CheckStatusByLabelMap = {
  [CheckStatusMap.start]: '启用',
  [CheckStatusMap.forbid]: '禁用'
}

export interface VipList {
  /** 主键id */
  aid: number,
  /** 会员权限 */
  name: string,
  /** 会员等级 */
  level: number,
  /** 有效期 */
  duration: number,
  /** 每天最大下载次数 */
  limit_num: number,
  /** 分类权限：逗号分隔；*表示有全部分类 */
  limit_class: string,
  /** 启用状态：-1=禁用，1=启用 */
  status: number,
  /** 创建时间 */
  create_time: string,
  /** 更新时间 */
  update_time: string,
  /** 最后一次修改人 */
  last_operator: string
  /** 权限范围：0-全部，1-PPT，2-word,3-excel */
  type: number
}

export interface TypeListResponseData {
  data: {
    list: VipList[],
    total: number
  }
}

@Injectable()
export class PermissionService {
  typeVipList: VipList[] = []

  constructor(private http: Http) {}

  getVipTypeList (params: TypeSearchRequestParams) {
    return this.http.post<TypeListResponseData>('/vip/item/type/search', params).then((response: TypeListResponseData) => {
      const data = response.data
      this.typeVipList = strictExtend(data.list) as VipList[]

      return { list: data.list, total: data.total }
    })
  }

  deleteVipType (params: TypeDeleteParams) {
    return this.http.post('/vip/item/type/delete', params)
  }

  updateVipType (params: TypeUpdateParams) {
    return this.http.post('/vip/item/type/update', params)
  }
}
