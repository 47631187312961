import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';

export interface OperatelogRequestParams {
  /** 模板id */
  mat_id: number
}

export interface OperateList {
  /** 模板id */
  id: number
  /** 设计师id */
  mat_id: number
  /** 上传时间 */
  operator: string
  /** 标题 */
  operate_type: number
  /** 标签 */
  operate_result: number
  /** 1：web，2：duba,3:ppt,4:liebao,5:pdf */
  operate_reason: string
  /** 大图地址 */
  status_before: number
  /** 小图地址 */
  status_after: number
  /** 多格式 */
  created_time: string
}

export interface OperatelogResponseData {
  logs: OperateList[]
}

@Injectable()
export class VerifyLogsService {
  private logs: OperateList[] = []

  constructor(private http: Http) {}

  getOperatelog(params: OperatelogRequestParams) {
    return this.http.post<OperatelogResponseData>('/operatelog/list', params).then((response: OperatelogResponseData) => response.logs || [])
  }
}
