import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import {AdvertisementService} from '@/views/marketing/advertisement/advertisement.service'

export interface AddAdParams {
  /** 广告类型：0-全部，1-图文，2-文字，3-轮播 */
  ad_type: number
  /** 产品形态	*/
  client_type: string
  /** 广告位置	*/
  pos_code: string
  /** 方案代号	*/
  plan: string
  /** 权重 */
  weight: number
  /** 状态：默认为禁用 */
  status: number
}

export interface UpdateAdParams {
  /** id */
  id: number
  /** 广告类型：0-全部，1-图文，2-文字，3-轮播 */
  ad_type: number
  /** 产品形态	*/
  client_type: string
  /** 广告位置	*/
  pos_code: string
  /** 方案代号	*/
  plan: string
  /** 权重 */
  weight: number
  /** 状态：默认为禁用 */
  status: number
}

@Injectable()
export class AdvertisementInformationDialogService {
  constructor(private http: Http, private advertisementService: AdvertisementService) {
  }

  // 获取具体广告信息
  getAdById (id: number) {
    const idData = this.advertisementService.adList.find(item => item.id === id)
    return {
      ad_type: idData.ad_type,
      client_type: idData.client_type,
      pos_code: idData.pos_code,
      ad_name: idData.ad_name,
      plan: idData.plan,
      weight: idData.weight,
      status: idData.status
    }
  }

  // 新建广告
  addAd (params: AddAdParams) {
    return this.http.post('/ad/add', params)
  }

  // 更新广告
  updateAd (params: UpdateAdParams) {
    return this.http.post('/ad/update', params)
  }
}
