import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface RequestActivityId {
  /** 活动id */
  activity_id: number
}

export interface WebPay {
  id: number
  /** 活动id */
  activity_id: number
  /** banner图地址 */
  banner_img: string
  /** 套餐id */
  package_id: number
}

export interface ResponseGetWebPay {
  data: WebPay
}

@Injectable()
export class WebPayService {
  constructor (private http: Http) {}

  getWebPay (data: RequestActivityId) {
    return this.http.post<ResponseGetWebPay>('/pay-activity/get', data)
  }

  addWebPay (data: WebPay) {
    return this.http.post<void>('/pay-activity/add', data)
  }

  updateWebPay (data: WebPay) {
    return this.http.post<void>('/pay-activity/update', data)
  }
}
