import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface RequestActivityId {
  /** 活动id */
  activity_id: number
}

export interface Topic {
  id: number
  /** 活动id */
  activity_id: number
  /** banner图地址 */
  banner_img: string
  /** 素材id集合，英文逗号分隔 */
  mat_ids: string
  /** 购买方案 */
  title: string
  /** 价格 */
  price: number
  mat_file: string
}

export interface ResponseGetTopic {
  data: Topic
}

export interface RequestPacksalePack {
  /** 素材id集合，逗号分隔 */
  mat_ids: string
}

export interface ResponsePacksalePack {
  /** 下载url */
  url: string
}

@Injectable()
export class TopicService {
  constructor (private http: Http) {}

  getTopic (data: RequestActivityId) {
    return this.http.post<ResponseGetTopic>('/packsale/get', data)
  }

  addTopic (data: Topic) {
    return this.http.post<void>('/packsale/add', data)
  }

  updateTopic (data: Topic) {
    return this.http.post<void>('/packsale/update', data)
  }

  packPacksale (data: RequestPacksalePack) {
    return this.http.post<ResponsePacksalePack>('/packsale/pack', data)
  }

  packDownload (data: RequestActivityId) {
    return this.http.post<ResponsePacksalePack>('/packsale/download', data)
  }
}
