import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { RANK_URL } from '@/common/config'

export enum DefaultType {
  is_no_default = 0,
  is_default = 1
}

export enum StatusType {
  open = 1,
  close = -1
}

export interface SearchSortListParams {
  /** 唯一id */
  id?: string
  /** 名称 */
  name?: string
  is_default?: DefaultType
  /** 投放产品 */
  product_code?: string
  /** 状态 -1 禁用 1 启用 */
  status?: StatusType
}

export interface UpdateSortParams {
  /** id */
  id?: number
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码	*/
  product_code?: string
  /** 状态码 -1禁用1启用 */
  statue?: number
}

export interface SortDeleteParams {
  id: string
}

export interface SortItem {
  /** 唯一id */
  id: string
  /** 名称 */
  name: string
  /** 是否默认 0非默认1默认 */
  is_default: DefaultType
  /** 投放产品 */
  product_code: string
  /** 状态 -1 禁用 1 启用 */
  status: StatusType
  /** 创建时间 */
  create_time: string
  /** 更新时间 */
  update_time: string
}

export interface SortListResponseData {
  /** 列表 */
  list: SortItem[]
  /** 总数量 */
  total: number
}

export interface QuerySortParams {
  /** 分类排序唯一标识	*/
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number
}

export interface CatMatSortItem {
  /** 分类排序唯一标识 */
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number | string
  /** 分类列表 */
  mat_sort?: string
  /** 创建时间 */
  create_time?: string
  /** 更新时间	*/
  update_time?: string

  // 以下是前端自定义字段
  isEdit?: boolean
  isNew?: boolean
}

export interface CatSortList {
  list: CatMatSortItem[]
  total: string | number
}

/** 排序状态 */
export const CheckStatusMap = {
  start: 1,
  forbid: -1
}

/** 排序状态对应的中文名 */
export const CheckStatusByLabelMap = {
  [StatusType.open]: '启用',
  [StatusType.close]: '禁用'
}

/** 渠道名称 */
export const ProductCodeMap = {
  web: 'web端',
  duba: '客户端',
  ppt: '侧边栏'
}

@Injectable()
export class SortService {
  constructor(private http: Http) {}

  getSearchTopList (params: SearchSortListParams) {
    return this.http.post<SortListResponseData>('/searchtop/list_search_top', params)
            .then(e => {
              e.total = Number(e.total)
              return e
            })
  }

  /** 更新排序列表 */
  updateSortItem (params: UpdateSortParams) {
    return this.http.post('/searchtop/update_search_top', params)
  }

  /** 删除排序列表 */
  deleteSortItem(params: SortDeleteParams) {
    return this.http.post('/searchtop/delete_search_top', params)
  }

  /** 执行素材排序任务 */
  rankingMat () {
    return this.http.post(`${RANK_URL}/mat/ranking`, {})
  }

  /** 执行素材收集排序任务 */
  rankingMatCalculate () {
    return this.http.post(`${RANK_URL}/mat/ranking/calculate`, {})
  }

  queryCatSortList (params: QuerySortParams) {
    return this.http.post<CatSortList>('/cat_mat_sort/list', params).then((response: CatSortList) => {
      return { list: response.list }
    })
  }

  cdnAndCacheRefresh ({ product, classId }: { product: string, classId?: string }) {
    return this.http.get(`${RANK_URL}/mat/ranking/one?product=${product}&class=${classId}`, {})
  }
}
