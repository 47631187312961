
import { defineComponent, reactive } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  components: { draggable },

  props: {
    showImagesDialogVisible: {
      type: Boolean,
      default: false
    },
    imagesFiles: {
      type: Array,
      default: () => [] as any
    },
  },

  emits: ['update:showImagesDialogVisible', 'clickShowImagesDialogClose', 'update:imagesFiles', 'clickShowImagesDialogSubmit'],

  setup(props, ctx) {
    const dialogHandle = reactive({
      visible: props.showImagesDialogVisible,
      showImages: [],
      close: () => {
        ctx.emit('clickShowImagesDialogClose', false)
      },
      submit: () => {
        ctx.emit('clickShowImagesDialogSubmit', dialogHandle.showImages)
      }
    })

    return {
      dialogHandle
    }
  },

  data () {
    return {
      showImages: []
    }
  },

  watch: {
    imagesFiles: {
      handler: function (value) {
        this.dialogHandle.showImages = value.map((item: any) => { return { src: window.URL.createObjectURL(item), id: Math.random() } })
      },
      immediate: true
    }
  }
})
