import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { strictExtend } from '@shared/utils/base';

export interface PageParams {
  /** 页数 */
  page: number
  /** 页数大小 */
  page_size: number
}

export interface ItemSearchRequestParams {
  /** 分页参数 */
  page_params: PageParams
  /** 用户id */
  uid: number
  /** 来源id */
  source_id: string
}

export interface DeleteItemParams {
  /** 用户id */
  uid: number
  /** 删除项对应id */
  delete_id: number
  /** 1 插入 2 删除 */
  operation_code: number
}

export interface ItemList {
  /** 主键id */
  id: number
  /** 用户id */
  uid: number
  /** 用户昵称	*/
  user_name: string
  /** 权益id */
  right_id: number
  /** 权益名称	*/
  right_name: string
  /** 权益等级	*/
  level: number
  /** 权益类型	*/
  right_type: string
  /** 来源id,当权益来源为kefu时，来源id为创建者名称 */
  source_id: string
  /** 来源 */
  source: string
  /** 会员开始时间	*/
  start_time: string
  /** 会员结束时间	*/
  end_time: string
  /** 创建时间	*/
  create_time: string
  /** 更新时间 */
  update_time: string
  /** 1.启用-权益生效 2.禁用-权益失效 3.已退款-权益失效-（用户已退款） 4.已到期-权益失效（权益结束时间>当前时间） */
  status: number
}

export interface ItemListResponse {
  data: {
    list: ItemList[],
    total: number
  }
}

@Injectable()
export class RightsService {
  itemList: ItemList[] = []

  constructor(private http: Http) {}

  getVipItemSearch(params: ItemSearchRequestParams) {
    return this.http.post<ItemListResponse>('/vip/item/search', params).then((response: ItemListResponse) => {
      const data = response.data
      this.itemList = strictExtend(data.list) as ItemList[]

      return { list: data.list, total: data.total }
    })
  }

  deleteVipItem(params: DeleteItemParams) {
    return this.http.post('/vip/item/delete', params)
  }
}
