
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive, defineAsyncComponent, ref, toRefs, computed } from 'vue'
import { Uploader } from '@/components/uploader/uploader.service'
import { UploadFileType } from '@/common/const'
import { ElMessage } from 'element-plus'
import { Plus as PlusIcon } from '@element-plus/icons-vue'
import { DrawColor, DrawSize, Formats, PicLimits } from './uploader'
import { isDevOrTest } from '@/common/env'

const ShowImagesDialog = defineAsyncComponent(() => import('./_components/showImagesDialog/showImagesDialog.vue'))

export default defineComponent({
  components: { ShowImagesDialog, PlusIcon },

  props: {
    defaultImgList: {
      type: Array,
      default: ():string[] => []
    },

    fileData: {
      type: Object as any,
      default: null
    },

    imgType: {
      type: String,
      default: ''
    }
  },

  emits: ['fileInfo'],

  setup(props, ctx) {
    const propData = toRefs(props)

    const injector = useReflectiveInjector([Uploader])
    const uploaderService = injector.get(Uploader)

    const isPPT = computed(() => {
      const classArr = propData.fileData.value.classId as any || []
      return classArr.indexOf(Formats.ppt) > -1
    })

    const isWord = computed(() => {
      const classArr = propData.fileData.value.classId as any || []
      return classArr.indexOf(Formats.word) > -1
    })

    const isExcel = computed(() => {
      const classArr = propData.fileData.value.classId as any || []
      return classArr.indexOf(Formats.excel) > -1
    })

    const isUploaded = ref(false)

    const uploadCanvas = ref(null)

    const showCanvas = ref(null)

    const picUpload = ref(null)

    const uploadHandle = reactive({
      imageUrl: '' as string | ArrayBuffer,
      loading: false
    })

    const limitPics = computed(() => {
      // 横版是'1'，纵版是'2'
      return isPPT.value ? PicLimits.ppt : propData.fileData.value.bigImgLayout === '1' ? 4 : 2
    })

    const showImagesDialogHandle = reactive({
      visible: false,
      images: [],
      clickShowImagesDialogClose () {
        showImagesDialogHandle.visible = false
        showImagesDialogHandle.images = []
      },
      clickShowImagesDialogSubmit (images: any) {
        if (propData.imgType.value === 'thumbNail') {
          mergeAndUploadThumbNail(images)
        } else {
          mergeAndUploadPreviewPics(images)
        }
        showImagesDialogHandle.visible = false
        showImagesDialogHandle.images = []
      }
    })

    function httpImgUpload (options: any) {
      if (!propData.fileData.value.title && !isPPT.value) {
        ElMessage('请填写模板名称')
        return
      }
      if (propData.fileData.value.classId.length === 0) {
        ElMessage('请选择模板分类')
        return
      }
      showImagesDialogHandle.visible = true
      showImagesDialogHandle.images = showImagesDialogHandle.images.concat([options.file])
      picUpload.value.clearFiles()
    }

    function mergeAndUploadThumbNail(images: any) {
      if (isPPT.value) {
        mergePPTThumbNail(images)
        mergePPTShowThumbNail(images)
      } else if (propData.fileData.value.bigImgLayout === '1') { // 非PPT模板、横板
          mergeRowNotPPTThumbNail(images)
          mergeRowNotPPTShowThumbNail(images)
        } else { // 非PPT模板、纵版
          mergeNotPPTThumbNail(images)
          mergeNotPPTShowThumbNail(images)
        }
    }

    function mergeAndUploadPreviewPics(images: any) {
      if (isPPT.value) {
        mergePPTPreviewPics(images)
        mergePPTShowPreviewPics(images)
      } else if (propData.fileData.value.bigImgLayout === '1') {
          mergeRowNotPPTPreviewPics(images)
          mergeRowNotPPTShowPreviewPics(images)
        } else {
          mergeNotPPTPreviewPics(images)
          mergeNotPPTShowPreviewPics(images)
        }
    }

    /** 重置ppt缩略图背景canvas
     * @param
     *  resetParams {
     *   width: canvas宽度
     *   images: 需要用于canvas拼图的元素
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *  }
     */
    function resetPPTThumbNailCanvas(resetParams: any) {
      const { width, canvas, canvasCtx, images } = resetParams
      const scale = width / 2880
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      const totalHeight = images.length === 0 ? 0 : (787 + 23) * Math.ceil((images.length - 1) / 2) + 1588 + 42
      const canvasHeight = totalHeight > DrawSize.MAX_HEIGHT ? DrawSize.MAX_HEIGHT : totalHeight
      canvas.setAttribute('width', width + '')
      canvas.setAttribute('height', canvasHeight * scale + '')
      canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
    }

    /** 合并ppt缩略图图片canvas
     * @param
     *  mergeParams {
     *   width: canvas宽度
     *   images: 需要用于canvas拼图的元素
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *  }
     */
    function mergePPTThumbNailCanvas(mergeParams: any) {
      const { width, canvasCtx, images, upload } = mergeParams
      const scale = width / 2880
      const mergePromise = []
      const imagesLength = images.length > 5 ? 5 : images.length
      for (let i = 0; i < imagesLength; i++) {
        const img = new Image()
        img.src = images[i].src
        mergePromise.push(new Promise((resolve) => {
          img.onload = () => {
            resolve(img)
          }
        }))
      }
      const drawAllImage = Promise.all(mergePromise).then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            canvasCtx.drawImage(res[i] as CanvasImageSource, 28 * scale, 21 * scale, 2824 * scale, 1588 * scale)
          } else {
            canvasCtx.drawImage(
              res[i] as CanvasImageSource,
              i % 2 === 0 ? 1457 * scale : 28 * scale,
              (1609 + Math.ceil(i / 2) * 23 + Math.floor((i - 1) / 2) * 787) * scale,
              1395 * scale,
              787 * scale
            )
          }
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          await uploadURL(canvasCtx)
        })
      }
    }

    /** 合并需要上传的ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergePPTThumbNail(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 800, canvas, canvasCtx, images }
      const mergeParams = { width: 800, canvasCtx, images, upload: true }
      resetPPTThumbNailCanvas(resetParams)
      mergePPTThumbNailCanvas(mergeParams)
    }

    /** 合并展示出来的ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergePPTShowThumbNail(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 600, canvas, canvasCtx, images }
      const mergeParams = { width: 600, canvasCtx, images, upload: false }
      resetPPTThumbNailCanvas(resetParams)
      mergePPTThumbNailCanvas(mergeParams)
    }

    /** 重置非ppt纵版缩略图背景canvas
     * @param
     *  resetParams {
     *   width: canvas宽度
     *   height: canvas高度
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *  }
     */
    function resetNotPPTThumbNailCanvas(resetParams: any) {
      const { width, height, canvas, canvasCtx } = resetParams
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      canvas.setAttribute('width', width + '')
      canvas.setAttribute('height', height + '')
    }

    /** 重置非ppt横版缩略图背景canvas
     * @param
     *  resetParams {
     *   width: canvas宽度
     *   height: canvas高度
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *  }
     */
    function resetRowNotPPTThumbNailCanvas(resetParams: any) {
      const { width, height, canvas, canvasCtx } = resetParams
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      canvas.setAttribute('width', width + '')
      canvas.setAttribute('height', height + '')
    }

    /** 合并非ppt纵版缩略图图片canvas
     * @param
     *  mergeParams {
     *   scale: 比例，默认宽度是2880px，根据ui或者具体情况来设置值
     *   images: 需要用于canvas拼图的元素
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *  }
     */
    function mergeNotPPTThumbNailCanvas(mergeParams: any) {
      const { scale, canvasCtx, images, upload } = mergeParams

      const notPPTTitle = propData.fileData.value.title

      const mergePromise = [] as any
      const img = new Image()
      const img2 = new Image()
      mergePromise.push(new Promise((resolve) => {
        img.src = images[0].src
        img.onload = () => {
          resolve(img)
        }
      }))

      if (isWord.value) {
        mergePromise.push(new Promise((resolve) => {
          img2.src = require('../../../assets/img/contentManage/word.png')
          img2.onload = () => {
            resolve(img2)
          }
        }))
      }

      if (isExcel.value) {
        mergePromise.push(new Promise((resolve) => {
          img2.src = require('../../../assets/img/contentManage/excel.png')
          img2.onload = () => {
            resolve(img2)
          }
        }))
      }

      /** box-shadow: 0 75 105.6 4.4 rgb(169, 168, 168) */

      const titleLength = (notPPTTitle as String).length
      const leftPadding = Math.ceil((2880 - (333 + 185 * titleLength)) / 2)

      const drawAllImage = Promise.all(mergePromise).then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
            canvasCtx.fillRect(0, 0, 2880 * scale, 3250 * scale)

            canvasCtx.shadowBlur = 105.6 * scale
            canvasCtx.shadowOffsetX = 0
            canvasCtx.shadowOffsetY = 2 * scale
            canvasCtx.shadowColor = 'rgb(169, 168, 168)'
            canvasCtx.drawImage(res[i] as CanvasImageSource, 459 * scale, 383 * scale, 1962 * scale, 2775 * scale)

            canvasCtx.shadowBlur = 0
            canvasCtx.shadowOffsetX = 0
            canvasCtx.shadowOffsetY = 0
            const fontSize = 185 * scale
            canvasCtx.font = `${fontSize}px Source Han Sans CN`
            canvasCtx.textBaseline = 'top'
            canvasCtx.fillStyle = isExcel.value ? 'rgb(34, 117, 71)' : isWord.value ? 'rgb(41, 82, 146)' : 'rgb(0, 0, 0)'
            if (!(isExcel.value || isWord.value)) {
              canvasCtx.textAlign = 'center'
              canvasCtx.fillText(notPPTTitle, 1440 * scale, 112 * scale)
            } else {
              canvasCtx.fillText(notPPTTitle, (leftPadding + 333) * scale, 112 * scale)
            }
          } else {
            canvasCtx.drawImage(res[i] as CanvasImageSource, leftPadding * scale, 77 * scale, 198 * scale, 253 * scale)
          }
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          await uploadURL(canvasCtx)
        })
      }
    }

    /** 合并非ppt横板缩略图图片canvas
     * @param
     *  mergeParams {
     *   scale: 比例，默认宽度是2880px，根据ui或者具体情况来设置值
     *   images: 需要用于canvas拼图的元素
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *  }
     */
    function mergeRowNotPPTThumbNailCanvas(mergeParams: any) {
      const { scale, canvasCtx, images, upload } = mergeParams

      const notPPTTitle = propData.fileData.value.title

      const mergePromise = [] as any
      const img = new Image()
      const img2 = new Image()
      mergePromise.push(new Promise((resolve) => {
        img.src = images[0].src
        img.onload = () => {
          resolve(img)
        }
      }))

      if (isWord.value) {
        mergePromise.push(new Promise((resolve) => {
          img2.src = require('../../../assets/img/contentManage/word.png')
          img2.onload = () => {
            resolve(img2)
          }
        }))
      }

      if (isExcel.value) {
        mergePromise.push(new Promise((resolve) => {
          img2.src = require('../../../assets/img/contentManage/excel.png')
          img2.onload = () => {
            resolve(img2)
          }
        }))
      }

      /** box-shadow: 0 75 105.6 4.4 rgb(169, 168, 168) */

      const titleLength = (notPPTTitle as String).length
      const leftPadding = Math.ceil((2880 - (333 + 207 * titleLength)) / 2)

      const drawAllImage = Promise.all(mergePromise).then((res: any) => {
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            /** 获取宽高 */
            const imgHeight = res[i].height
            const imageWidth = res[i].width
            const imgScale = (imgHeight / imageWidth) ? (imgHeight / imageWidth) : 0
            const canvasHeight = 2511 * imgScale

            /** 绘制大背景 */
            canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
            canvasCtx.fillRect(0, 0, 2880 * scale, 3124 * scale)

            /** 绘制展示的图片 */
            canvasCtx.shadowBlur = 105.6 * scale
            canvasCtx.shadowOffsetX = 0
            canvasCtx.shadowOffsetY = 2 * scale
            canvasCtx.shadowColor = 'rgb(169, 168, 168)'
            canvasCtx.drawImage(res[i] as CanvasImageSource, 184 * scale, 921 * scale, 2511 * scale, canvasHeight * scale)

            /** 绘制标题 */
            canvasCtx.shadowBlur = 0
            canvasCtx.shadowOffsetX = 0
            canvasCtx.shadowOffsetY = 0
            const fontSize = 207 * scale
            canvasCtx.font = `${fontSize}px Source Han Sans CN`
            canvasCtx.textBaseline = 'top'
            canvasCtx.fillStyle = isExcel.value ? 'rgb(34, 117, 71)' : isWord.value ? 'rgb(41, 82, 146)' : 'rgb(0, 0, 0)'
            if (!(isExcel.value || isWord.value)) {
              canvasCtx.textAlign = 'center'
              canvasCtx.fillText(notPPTTitle, 1440 * scale, 311 * scale)
            } else {
              canvasCtx.fillText(notPPTTitle, (leftPadding + 333) * scale, 311 * scale)
            }
          } else {
            canvasCtx.drawImage(res[i] as CanvasImageSource, leftPadding * scale, 264 * scale, 218 * scale, 276 * scale)
          }
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          await uploadURL(canvasCtx)
        })
      }
    }

    /** 合并需要上传的非ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeNotPPTThumbNail(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 2880, height: 3250, canvas, canvasCtx }
      const mergeParams = { scale: 1, canvasCtx, images, upload: true }
      resetNotPPTThumbNailCanvas(resetParams)
      mergeNotPPTThumbNailCanvas(mergeParams)
    }

    /** 合并需要上传的横板非ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeRowNotPPTThumbNail(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 2880, height: 3124, canvas, canvasCtx }
      const mergeParams = { scale: 1, canvasCtx, images, upload: true }
      resetRowNotPPTThumbNailCanvas(resetParams)
      mergeRowNotPPTThumbNailCanvas(mergeParams)
    }

    /** 合并需要展示的非ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeNotPPTShowThumbNail(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 576, height: 650, canvas, canvasCtx }
      const mergeParams = { scale: 0.2, canvasCtx, images, upload: false }
      resetNotPPTThumbNailCanvas(resetParams)
      mergeNotPPTThumbNailCanvas(mergeParams)
    }

    /** 合并需要展示的横板非ppt缩略图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeRowNotPPTShowThumbNail(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 576, height: 625, canvas, canvasCtx }
      const mergeParams = { scale: 0.2, canvasCtx, images, upload: false }
      resetRowNotPPTThumbNailCanvas(resetParams)
      mergeRowNotPPTThumbNailCanvas(mergeParams)
    }

    /** 重置ppt预览图背景canvas
     *  resetParams {
     *  @param
     *   width: canvas宽度
     *   images: 用于拼图的图片
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *  }
     */
    function resetPPTPreviewCanvas(resetParams: any) {
      const { width, canvas, canvasCtx, images } = resetParams
      const scale = width / 2880
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      const imagesHeight = (787 + 23) * Math.ceil((images.length - 1) / 2) + 1588 + 42
      const totalHeight = images.length === 0 ? 0 : imagesHeight > DrawSize.MAX_HEIGHT ? DrawSize.MAX_HEIGHT : imagesHeight
      canvas.setAttribute('width', width + '')
      canvas.setAttribute('height', totalHeight * scale + '')
      canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
    }

    /** 合并ppt预览图图片canvas
     * @param
     *  mergeParams {
     *   width: canvas宽度
     *   images: 用于拼图的图片
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *  }
     */
    function mergePPTPreviewPicsCanvas(mergeParams: any) {
      const { width, canvasCtx, images, upload } = mergeParams
      const scale = width / 2880
      const mergePromise = []
      for (let i = 0; i < images.length; i++) {
        const img = new Image()
        img.src = images[i].src
        mergePromise.push(new Promise((resolve) => {
          img.onload = () => {
            resolve(img)
          }
        }))
      }
      const drawAllImage = Promise.all(mergePromise).then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            canvasCtx.drawImage(res[i] as CanvasImageSource, 28 * scale, 21 * scale, 2824 * scale, 1588 * scale)
          } else {
            canvasCtx.drawImage(
              res[i] as CanvasImageSource,
              i % 2 === 0 ? 1457 * scale : 28 * scale,
              (1609 + Math.ceil(i / 2) * 23 + Math.floor((i - 1) / 2) * 787) * scale,
              1395 * scale,
              787 * scale
            )
          }
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          if (isPPT.value) {
            await uploadURLSplit(canvasCtx)
          } else {
            await uploadURL(canvasCtx)
          }
        })
      }
    }

    /** 合并需要上传的ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergePPTPreviewPics(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 2880, canvas, canvasCtx, images }
      const mergeParams = { width: 2880, canvasCtx, images, upload: true }
      resetPPTPreviewCanvas(resetParams)
      mergePPTPreviewPicsCanvas(mergeParams)
    }

    /** 合并需要展示的ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergePPTShowPreviewPics(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 600, canvas, canvasCtx, images }
      const mergeParams = { width: 600, canvasCtx, images, upload: false }
      resetPPTPreviewCanvas(resetParams)
      mergePPTPreviewPicsCanvas(mergeParams)
    }

    /** 重置非ppt预览图背景canvas
     * @param
     *  resetParams {
     *   width: 单张图片宽度
     *   images: 用于拼图的图片
     *   height: 单张图片高度
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *   marginHeight: 图片间距
     *  }
     */
    function resetNotPPTPreviewPicsCanvas(resetParams: any) {
      const { width, height, marginHeight, canvas, canvasCtx, images} = resetParams
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      const totalHeight = height * images.length + marginHeight * (images.length - 1)
      const canvasHeight = totalHeight > DrawSize.MAX_HEIGHT ? DrawSize.MAX_HEIGHT : totalHeight
      canvas.setAttribute('width', width + '')
      canvas.setAttribute('height', canvasHeight + '')
      canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
    }

    /** 重置非ppt预览图背景canvas
     * @param
     *  resetParams {
     *   width: 单张图片宽度
     *   images: 用于拼图的图片
     *   height: 单张图片高度
     *   canvasCtx: 目标canvas
     *   canvas: 目标canvas所在的dom
     *   marginHeight: 图片间距
     *  }
     *  mergeParams {
     *   width: 单张图片宽度
     *   images: 用于拼图的图片
     *   height: 单张图片高度
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *   marginHeight: 图片间距
     *  }
     */
    function resetRowNotPPTPreviewPicsCanvas(resetParams: any, mergeParams: any) {
      const { width, height, marginHeight, canvas, canvasCtx, images} = resetParams
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
      const mergePromise = [] as any
      for (let i = 0; i < images.length; i++) {
        mergePromise.push(new Promise((resolve) => {
          const img = new Image()
          img.src = images[i].src
          img.onload = () => {
            resolve(img)
          }
        }))
      }
      const drawAllImage = Promise.all(mergePromise).then((res: any) => {
        let totalCanvasHeight = 0
        for (let i = 0; i < res.length; i++) {
          /** 获取宽高 */
          const imgHeight = res[i].height
          const imageWidth = res[i].width
          const imgScale = (imgHeight / imageWidth) ? (imgHeight / imageWidth) : 0
          const canvasHeight = width * imgScale
          totalCanvasHeight += canvasHeight
        }
        const totalHeight = totalCanvasHeight + marginHeight * (images.length - 1)
        const canvasHeight = totalHeight > DrawSize.MAX_HEIGHT ? DrawSize.MAX_HEIGHT : totalHeight
        canvas.setAttribute('width', width + '')
        canvas.setAttribute('height', canvasHeight + '')
        canvasCtx.fillStyle = DrawColor.COMMON_BACKGROUND
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
      })
      /** 因为重置canvas时需要动态获得高度，如果和合并canvas一起操作会造成异步任务紊乱造成生成的canvas出问题，所以就将合并操作置于重置canvas后的下一步 */
      drawAllImage.then(() => { mergeRowNotPPTPreviewPicsCanvas(mergeParams) })
    }

    /** 合并非ppt预览图图片canvas
     * @param
     *  mergeParams {
     *   width: 单张图片宽度
     *   images: 用于拼图的图片
     *   height: 单张图片高度
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *   marginHeight: 图片间距
     *  }
     */
    function mergeNotPPTPreviewPicsCanvas(mergeParams: any) {
      const { width, height, marginHeight, canvasCtx, images, upload } = mergeParams
      const mergePromise = [] as any
      for (let i = 0; i < images.length; i++) {
        const img = new Image()
        img.src = images[i].src
        mergePromise.push(new Promise((resolve) => {
          img.onload = () => {
            resolve(img)
          }
        }))
      }
      const drawAllImage = Promise.all(mergePromise).then((res) => {
        for (let i = 0; i < res.length; i++) {
          canvasCtx.drawImage(res[i] as CanvasImageSource, 0, height * i + marginHeight * i, width, height)
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          await uploadURL(canvasCtx)
        })
      }
    }

    /** 重置非ppt横板预览图背景canvas
     * @param
     *  mergeParams {
     *   width: 单张图片宽度
     *   images: 用于拼图的图片
     *   height: 单张图片高度
     *   canvasCtx: 目标canvas
     *   upload: Boolean 判断是否上传
     *   marginHeight: 图片间距
     *  }
     */
    function mergeRowNotPPTPreviewPicsCanvas(mergeParams: any) {
      const { width, height, marginHeight, canvasCtx, images, upload } = mergeParams
      const mergePromise = [] as any
      for (let i = 0; i < images.length; i++) {
        const img = new Image()
        img.src = images[i].src
        mergePromise.push(new Promise((resolve) => {
          img.onload = () => {
            resolve(img)
          }
        }))
      }
      const drawAllImage = Promise.all(mergePromise).then((res: any) => {
        let prevCanvasHeight = 0
        for (let i = 0; i < res.length; i++) {
          const imgHeight = res[i].height
          const imageWidth = res[i].width
          const imgScale = (imgHeight / imageWidth) ? (imgHeight / imageWidth) : 0
          const canvasHeight = width * imgScale
          if (canvasHeight) {
            canvasCtx.drawImage(res[i] as CanvasImageSource, 0, prevCanvasHeight, width, canvasHeight)
          }
          prevCanvasHeight += marginHeight
          prevCanvasHeight += canvasHeight
        }
      })

      if (upload) {
        drawAllImage.then(async () => {
          await uploadURL(canvasCtx)
        })
      }
    }

    /** 合并需要上传的纵版非ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeNotPPTPreviewPics(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 2381, height: 3368, marginHeight: 40, canvas, canvasCtx, images }
      const mergeParams = { width: 2381, height: 3368, marginHeight: 40, canvasCtx, images, upload: true }
      resetNotPPTPreviewPicsCanvas(resetParams)
      mergeNotPPTPreviewPicsCanvas(mergeParams)
    }

    /** 合并需要上传的横板非ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeRowNotPPTPreviewPics(images: any) {
      const canvas = uploadCanvas.value
      const canvasCtx = uploadCanvas.value.getContext('2d')
      const resetParams = { width: 2381, height: 0, marginHeight: 40, canvas, canvasCtx, images }
      const mergeParams = { width: 2381, height: 0, marginHeight: 40, canvasCtx, images, upload: true }
      resetRowNotPPTPreviewPicsCanvas(resetParams, mergeParams)
    }

    /** 合并需要展示的纵版非ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeNotPPTShowPreviewPics(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 460, height: 594, marginHeight: 4, canvas, canvasCtx, images }
      const mergeParams = { width: 460, height: 594, marginHeight: 4, canvasCtx, images, upload: false }
      resetNotPPTPreviewPicsCanvas(resetParams)
      mergeNotPPTPreviewPicsCanvas(mergeParams)
    }

    /** 合并需要展示的横板非ppt预览图
     * @param
     *  images: 需要用于canvas拼图的元素
     */
    function mergeRowNotPPTShowPreviewPics(images: any) {
      const canvas = showCanvas.value
      const canvasCtx = showCanvas.value.getContext('2d')
      const resetParams = { width: 654, height: 0, marginHeight: 4, canvas, canvasCtx, images }
      const mergeParams = { width: 654, height: 0, marginHeight: 4, canvasCtx, images, upload: false }
      resetRowNotPPTPreviewPicsCanvas(resetParams, mergeParams)
    }

    function dataURLtoFile(dataUrl: string, filename: string) {
      const arr = dataUrl.split(',')
      const mime = arr[0]
      const bStr = atob(arr[1])
      let n = bStr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bStr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }

    async function uploadURL(canvasCtx: any) {
      const convertImg = canvasCtx.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/jpg')
      const mergeImg = dataURLtoFile(convertImg, 'merge.jpg')

      const info = await uploaderService.uploadFileResultFileName(mergeImg, UploadFileType.img)
      ctx.emit('fileInfo', info)
      uploadHandle.imageUrl = convertImg
      isUploaded.value = true
    }

    async function uploadURLSplit(canvasCtx: CanvasRenderingContext2D) {
      const canvas = canvasCtx.canvas
      const convertImg = canvasCtx.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/jpg')
      uploadHandle.imageUrl = convertImg

      const splitHeight = 9 * (DrawSize.PPT_THUMBNAIL_HEIGHT + DrawSize.PPT_MARGIN_TOP_HEIGHT)
      const firstHeight = splitHeight + DrawSize.PPT_FIRST_THUMBNAIL_HEIGHT + DrawSize.PPT_THUMBNAIL_PADDING_TOP_AND_BOTTOM

      let cutHeigthed = 0
      let cutHeigth = 0
      let isCutFirst = true
      const l = []
      while (1) {
        if (isCutFirst) {
          if (canvas.height > (firstHeight + DrawSize.PPT_THUMBNAIL_PADDING_TOP_AND_BOTTOM)) {
            cutHeigth = firstHeight
          } else {
            cutHeigth = canvas.height
          }
          isCutFirst = false
        }

        const canvasNew = document.createElement('canvas')
        const contextNew = canvasNew.getContext('2d')
        canvasNew.width = DrawSize.UPLOAD_COMMON_WIDTH
        canvasNew.height = cutHeigth
        const imageData = canvasCtx.getImageData(0, cutHeigthed, DrawSize.UPLOAD_COMMON_WIDTH, cutHeigth)
        contextNew.putImageData(imageData, 0, 0)
        const convertImg = canvasNew.toDataURL('image/jpeg').replace('image/jpeg', 'image/jpg')
        const mergeImg = dataURLtoFile(convertImg, 'merge.jpg')
        const info = await uploaderService.uploadFileResultFileName(mergeImg, UploadFileType.img)
        isUploaded.value = true
        l.push(info)

        cutHeigthed += cutHeigth
        const leftHeigth = canvas.height - cutHeigthed
        if (leftHeigth <= 0) {
          break
        } else if (leftHeigth > (splitHeight + DrawSize.PPT_THUMBNAIL_PADDING_TOP_AND_BOTTOM)) {
          cutHeigth = splitHeight
        } else {
          cutHeigth = leftHeigth
        }
      }
      ctx.emit('fileInfo', l)
    }

    function exceed() {
      ElMessage(`超过上传上限，上传张数不超过${limitPics.value}张`)
    }

    return {
      uploadHandle,
      showImagesDialogHandle,
      uploadCanvas,
      showCanvas,
      limitPics,
      picUpload,
      isUploaded,

      httpImgUpload,
      exceed
    }
  },

  computed: {
    showImg() {
      return this.uploadHandle.imageUrl || this.defaultImgList[0]
    },
    imgList() {
      return this.defaultImgList
                .filter((e: string) => !!e)
                .map((url: string) => {
                  if (url.startsWith('http')) {
                    return url
                  }
                  const pre = isDevOrTest ? 'https://loveoffice-cdn.jinshanapi.com/test/mat_img/' : 'https://loveoffice-cdn.jinshanapi.com/mat_img/'
                  return pre + url
                })
    }
  }
})
