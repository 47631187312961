
import { defineComponent, reactive } from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import {
  ConfigService,
  CatMatSortItem,
  ClassifyLableValue,
  AddCatSortParams
} from './sort-config.service'
import { SortService } from '@/views/sort/search/sort.service'
import { formatDate } from '@shared/utils/base'

const log = console.log.bind(console)

export default defineComponent({
  components: {
    MainLayout,
    TableLayout
  },
  setup() {
    const injector = useReflectiveInjector([ConfigService, SortService])
    const configService = injector.get(ConfigService)
    const sortService = injector.get(SortService)

    // 获取路由信息
    const route = useRoute()
    const router = useRouter()
    const catSortId = route.query.id ? route.query.id as string : ''

    // 排序分类配置相关逻辑
    const catSortHandle = {
      catSortDetail: {} as any,
      classifyList: [] as ClassifyLableValue[],
      getCatSortDetailById(id: string) {
        const req = {
          id: id
        }
        return sortService.getSearchTopList(req)
          .then(res => res.list[0])
      },
      getSortListById(id: string) {
        const req = {
            search_top_id: id,
            id: searchHandle.id || null,
            keyword: searchHandle.name || null,
        }
        return configService.queryCatSortList(req)
          .then<CatMatSortItem[]>((res) => {
              return res.list.map((item: CatMatSortItem) => {
                return {
                  ...item,
                  isEdit: false
                }
              })
          })
      },
      addCatMatSort(sortItem: AddCatSortParams) {
        const req = {
          ...sortItem,
        }
        configService.addClassifySort(sortItem)
          .then(() => {
            tableHandle.loadCatSortList()
          })
      },
      toSortOrderConfigRoute(sortItem: CatMatSortItem) {
        router.push({
          name: 'sort-search-order',
          query: {
            product: catSortHandle.catSortDetail.product_code,
            search_top_id: sortItem.search_top_id,
            id: sortItem.id
          }
        })
      },
      loadClassifyList() {
        return configService.getAllClassifyList()
          .then((list: ClassifyLableValue[]) => {
            catSortHandle.classifyList = list.map<ClassifyLableValue>(e => {
              return {
                label: `${e.label} id:${e.value}`,
                value: e.value,
              }
            })
          })
      },
      getCatNameById(catId: number | string) {
        const idSearch = Number(catId)
        const o = catSortHandle.classifyList.find(classify => classify.value === idSearch)
        return o ? o.label : ''
      },
      loadCatSortDetail() {
        catSortHandle.getCatSortDetailById(catSortId)
          .then(res => {
            catSortHandle.catSortDetail = res
          })
      },
    }

    // 搜索相关逻辑
    const searchHandle = reactive({
      id: '',
      name: '',
      clickSearch: () => {
        tableHandle.loadCatSortList()
      },
      clickReset: () => {
        searchHandle.id = ''
        searchHandle.name = ''
        tableHandle.loadCatSortList()
      }
    })

    // 列表展示操作相关逻辑
    const tableHandle = reactive({
      catSortList: [] as CatMatSortItem[],
      loading: false,
      clickConfig(sortItem: CatMatSortItem) {
        if (sortItem.isEdit || sortItem.isNew) {
          ElMessageBox.alert('请保存后再进行配置')
        } else {
          catSortHandle.toSortOrderConfigRoute(sortItem)
        }
      },
      clickNew() {
        tableHandle.catSortList.unshift({
          search_top_id: catSortId,
          keyword: '',
          mat_sort: '[]',
          isNew: true
        })
      },
      clickEdit(sortItem: CatMatSortItem) {
        sortItem.isEdit = true
      },
      clickSave(sortItem: CatMatSortItem) {
        if (sortItem.keyword === '') {
          ElMessageBox.alert('请不要保存空搜索词')
          return
        }
        if (sortItem.mat_sort === '') {
          sortItem.mat_sort  = '[]'
        }
        ElMessageBox.confirm('是否确认保存', '提示', {
          confirmButtonText: '确认保存',
          cancelButtonText: '暂不保存',
          type: 'info',
        })
          .then(() => {
            if (sortItem.isNew) {
              const req = sortItem as AddCatSortParams
              catSortHandle.addCatMatSort(req)
            } else {
              sortItem.isEdit = false
              const req = {
                id: sortItem.id,
                search_top_id: sortItem.search_top_id,
                keyword: sortItem.keyword,
                mat_sort: sortItem.mat_sort
              }
              configService.updateClassifySort(req)
            }
          })
      },
      refreshItem (sortItem: CatMatSortItem) {
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        sortService.cdnAndCacheRefresh({
          product: catSortHandle.catSortDetail.product_code,
        })
      },
      clickDelete(index: number) {
        ElMessageBox.confirm('是否确认删除？删除后无法恢复', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            if (tableHandle.catSortList[index].id) {
              configService.deleteClassifySort({id: tableHandle.catSortList[index].id }).then(() => {
                ElMessage.success('删除成功')
                tableHandle.catSortList.splice(index, 1)
              })
            } else {
              tableHandle.catSortList.splice(index, 1)
              ElMessage.success('删除成功')
            }
          })
      },
      loadCatSortList() {
        tableHandle.loading = true
        catSortHandle.getSortListById(catSortId)
          .then(list => {
            tableHandle.catSortList = list
            tableHandle.loading = false
          })
      },
      clickRefresh: async () => {
        // Promise.all([sortService.rankingMat(), sortService.rankingMatCalculate()]).then(() => {
        //   ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        // })
        const product = catSortHandle.catSortDetail.product_code
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        // Promise.all(cats.map(keyword => {
        //   return  sortService.cdnAndCacheRefresh({
        //     product,
        //     classId: String(keyword)
        //   })
        // }))
      }
    })

    // create加载数据
    catSortHandle.loadClassifyList()
      .then(() => {
        catSortHandle.loadCatSortDetail()
      })
      .then(() => {
        tableHandle.loadCatSortList()
      })

    return {
      catSortHandle,
      tableHandle,
      searchHandle,
      formatDate,
    }
  }
})
