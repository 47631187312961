import { Injectable } from '@tanbo/vue-di-plugin';
import { HttpInterceptor } from '@zhfe/common-library';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ElNotification } from 'element-plus';

export interface CommonRequestFields {
  uid?: string,
  token?: string
}

export const CommonErrorLabelMap = {
  'duplicate cat sort record': '重复分类排序id'
}

export class ResponseError extends Error {
  name = 'ResponseError'

  constructor (responseBody: any, msg?: string) {
    super(msg)
  }
}

const AuthExpiredArr = [4001002, 4001004]

@Injectable()
export class HttpInterceptorService implements HttpInterceptor<any> {
  private writeList: RegExp[] = ['//loveoffice-img.oss-cn-shenzhen.aliyuncs.com', '//loveoffice.oss-cn-shenzhen.aliyuncs.com'].map(i => {
    const url = i.replace(/[/.]/g, text => {
      return '\\' + text;
    })
    return new RegExp(url)
  })

  private commonRequestFields: CommonRequestFields = {}

  constructor() {}

  request(request: AxiosRequestConfig) {
    request.headers.common['X-CF-From'] = ''
      if (request.data) {
        request.data.common = {
          ...this.commonRequestFields
        }
      } else if (request.params) {
        request.params = {
          ...this.commonRequestFields
        }
      }

      return request
  }

  response(response: AxiosResponse) {
    const url = response.config.url;

    if (this.writeList.some(i => i.test(url))) {
      return Promise.resolve({
        resp_common: {
          ret: 0,
          msg: '上传成功'
        }
      })
    }

    const body = response.data
    body.resp_common = body.resp_common || {}
    const { ret, msg } = body.resp_common

    if (ret === 0) { return body }

    if (AuthExpiredArr.includes(ret)) {
      // location.reload()
      return Promise.reject(new ResponseError('登录失效，请重新登录!'))
    }

    ElNotification({
      type: 'error',
      message: CommonErrorLabelMap[msg] || msg || '未知错误！',
      title: '错误提示'
    })
    return Promise.reject(new ResponseError(body.resp_common, msg || '未知错误！'))
  }

  addCommonRequestFields (params: CommonRequestFields) {
    Object.assign(this.commonRequestFields, params)
  }
}
