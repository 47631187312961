import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uploader = _resolveComponent("uploader")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.isShow)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.defaultFileName), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_uploader, { onFileInfo: _ctx.fileInfo }, null, 8, ["onFileInfo"])
  ]))
}