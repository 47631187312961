/** 文件格式对应的分类id */
export const Formats = {
  ppt: 1,
  word: 16,
  excel: 31
}

/** 拼图下各种文件格式对应的最大页数 */
export const PicLimits = {
  ppt: 99999999999, // 避免上传超级多图片，实际无限制
  row: 4,
  column: 2
}

/** 拼图类型：row: 横板; column: 纵版 */
export const PuzzleLayout = {
  column: '2',
  row: '1'
}

/** 颜色值 */
export const DrawColor = {
  /** 拼图背景色 */
  COMMON_BACKGROUND: 'rgb(255, 255, 255)',
  /** 非PPT模板下的图片阴影值 */
  SHADOW_COLOR: 'rgb(169, 168, 168)',

  /** 拼图EXCEL类型下的标题色 */
  TITLE_EXCEL_TEXT_COLOR: 'rgb(34, 117, 71)',
  /** 拼图Word类型下的标题色 */
  TITLE_WORD_TEXT_COLOR: 'rgb(41, 82, 146)',
  /** 拼图默认标题色 */
  TITLE_DEFAULT_TEXT_COLOR: 'rgb(0, 0, 0)'
}

export const DrawSize = {
  /** 拼图最大高度 */
  MAX_HEIGHT: 99999999999,

  /** 一张拼图默认宽度 */
  UPLOAD_COMMON_WIDTH: 2880,
  /** 一张拼图默认高度 */
  UPLOAD_COMMON_HEIGHT: 3250,
  /** 一张非PPT预览下宽度 */
  UPLOAD_NOT_PPT_PREVIEW_WIDTH: 2381,
  /** 一张非PPT在横板下的高度 */
  UPLOAD_NOT_PPT_ROW_HEIGHT: 3124,

  /** PPT缩略图总体距上下的内边距 */
  PPT_THUMBNAIL_PADDING_TOP_AND_BOTTOM: 21,
  /** PPT缩略图单张高度 */
  PPT_THUMBNAIL_HEIGHT: 787,
  /** PPT缩略图第一张高度 */
  PPT_FIRST_THUMBNAIL_HEIGHT: 1588,
  /** PPT缩略图各图之间的上边距 */
  PPT_MARGIN_TOP_HEIGHT: 23,
  /** PPT缩略图最大高度 */
  PPT_THUMBNAIL_MAX_HEIGHT: 3264,
  /** PPT缩略图单张宽度 */
  PPT_THUMBNAIL_WIDTH: 1395,
  /** PPT缩略图第一张宽度 */
  PPT_FIRST_THUMBNAIL_WIDTH: 2824,
  /** PPT缩略图总体距左右的内边距 */
  PPT_THUMBNAIL_PADDING_LEFT_AND_RIGHT: 28,
  /** PPT缩略图各图之间的左右边距 */
  PPT_THUMBNAIL_BETWEEN_MARGIN: 34,
  /** PPT缩略图上传时定义的宽度 */
  PPT_THUMBNAIL_UPLOAD_WIDTH: 800,
  /** PPT缩略图展示时定义的宽度 */
  PPT_THUMBNAIL_SHOW_WIDTH: 600,
  /** PPT预览图展示时定义的宽度 */
  PPT_PREVIEW_PICS_SHOW_WIDTH: 600,

  /** 非PPT模板内图左边距 */
  NOT_PPT_IMG_PADDING_LEFT: 459,
  /** 非PPT模板内图上边距 */
  NOT_PPT_IMG_PADDING_TOP: 383,
  /** 非PPT模板内图宽度 */
  NOT_PPT_IMG_WIDTH: 1962,
  /** 非PPT模板内图高度 */
  NOT_PPT_IMG_HEIGHT: 2775,
  /** 非PPT模板预览图展示宽度 */
  NOT_PPT_SHOW_PREVIEW_PICS_WIDTH: 460,
  /** 非PPT模板横板预览图展示宽度 */
  NOT_PPT_ROW_SHOW_PREVIEW_PICS_WIDTH: 654,
  /** 非PPT模板预览图展示高度 */
  NOT_PPT_SHOW_PREVIEW_PICS_HEIGHT: 594,
  /** 非PPT模板预览图展示图间距 */
  NOT_PPT_SHOW_PREVIEW_PICS_MARGIN: 4,
  /** 非PPT模板预览图上传高度 */
  NOT_PPT_UPLOAD_PREVIEW_PICS_HEIGHT: 3368,
  /** 非PPT模板预览图上传图间距 */
  NOT_PPT_UPLOAD_PREVIEW_PICS_MARGIN: 40,

  /** 非PPT模板横板内图左边距 */
  NOT_PPT_ROW_IMG_PADDING_LEFT: 184,
  /** 非PPT模板横板内图上边距 */
  NOT_PPT_ROW_IMG_PADDING_TOP: 921,
  /** 非PPT模板横板内图高度 */
  NOT_PPT_ROW_IMG_HEIGHT: 2511,

  /** 非PPT模板icon宽度 */
  ICON_WIDTH: 198,
  /** 非PPT模板横板icon宽度 */
  ROW_ICON_WIDTH: 218,
  /** 非PPT模板icon高度 */
  ICON_HEIGHT: 253,
  /** 非PPT模板横板icon高度 */
  ROW_ICON_HEIGHT: 276,
  /** 非PPT模板icon上外边距 */
  ICON_MARGIN_TOP: 77,
  /** 非PPT模板横板icon上外边距 */
  ROW_ICON_MARGIN_TOP: 264,
  /** 非PPT模板icon右外边距 */
  ICON_MARGIN_RIGHT: 135,

  /** 标题文字大小 */
  TITLE_FONT_SIZE: 185,
  /** 横板标题文字大小 */
  ROW_TITLE_FONT_SIZE: 207,
  /** 标题上外边距 */
  TITLE_MARGIN_TOP: 112,
  /** 横板标题上外边距 */
  ROW_TITLE_MARGIN_TOP: 311
}
