
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive, ref } from 'vue'
import { AdvertisementInformationDialogService } from '@/views/marketing/advertisement/_components/advertisementInformationDialog/advertisementInformationDialog.service'

const typeList = Object.freeze([
  { label: '图文广告', value: 1 },
  { label: '文字广告', value: 2 },
  { label: '轮播图', value: 3 }
])

const clientList = Object.freeze([
  { label: 'web端', value: 'web' },
  { label: '客户端', value: 'client' },
  { label: '侧边栏', value: 'officeside' },
  { label: '小程序', value: 'applets' },
  { label: '猎豹浏览器', value: 'liebao' },
  { label: '可牛PDF', value: 'pdf' },
])

export default defineComponent({
  props: {
    advertisementInformationDialogVisible: {
      type: Boolean,
      default: false
    },

    id: {
      type: Number,
      default: null
    }
  },

  emits: ['clickAdvertisementInformationDialogClose', 'clickAdvertisementInformationDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([AdvertisementInformationDialogService])
    const advertisementInformationDialogService = injector.get(AdvertisementInformationDialogService)

    const formRef = ref(null)

    // 表单句柄，用于控制表单输入
    const dialogHandle = reactive({
      visible: props.advertisementInformationDialogVisible,
      loading: false,
      disable: false,
      formData: {
        ad_type: 1,
        client_type: '',
        pos_code: '',
        ad_name: '',
        plan: '',
        weight: 0,
        status: -1
      },
      close: () => {
        ctx.emit('clickAdvertisementInformationDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (props.id) {
            advertisementInformationDialogService.updateAd({...dialogHandle.formData, id: props.id as number}).then(() => {
              ctx.emit('clickAdvertisementInformationDialogSubmit')
            })
          } else {
            advertisementInformationDialogService.addAd({...dialogHandle.formData}).then(() => {
              ctx.emit('clickAdvertisementInformationDialogSubmit')
            })
          }
        })
      }
    })

    // 如果有id，意味着编辑，需要获取id对应的广告信息
    if (props.id) {
      dialogHandle.disable = true
      dialogHandle.formData = advertisementInformationDialogService.getAdById(props.id as number)
    }

    return {
      formRef,
      dialogHandle,

      typeList,
      clientList
    }
  }
})
