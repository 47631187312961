
  import { defineComponent, reactive, onMounted, defineAsyncComponent } from 'vue'

  import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
  import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

  import { useReflectiveInjector } from '@tanbo/vue-di-plugin';
  import { ConstPagination } from '@/common/const';
  import { formatDate } from '@shared/utils/base';
  import { PermissionService, CheckStatusByLabelMap } from '@/views/members/permission/permission.service';

  const PermissionDialog = defineAsyncComponent(() => import('./_components/permissionDialog/permissionDialog.vue'))

  export default defineComponent({
    components: { MainLayout, TableLayout, PermissionDialog },

    setup() {
      const injector = useReflectiveInjector([PermissionService])
      const permissionService = injector.get(PermissionService)

      // 获取会员权限列表
      function getMemberList() {
        tableHandle.loading = true

        const params = {
          page_params: {
            page: paginationHandle.page,
            page_size: paginationHandle.size
          },
          vip_id: !searchHandle.vip_id ? null : Number(searchHandle.vip_id),
          vip_name: searchHandle.vip_name,
          last_operator: searchHandle.last_operator
        }

        permissionService.getVipTypeList(params).then((res) => {
          tableHandle.list = res.list
          paginationHandle.total = res.total
        }).finally(() => {
          tableHandle.loading = false
          searchHandle.loading = false
        })
      }

      const searchHandle = reactive({
        loading: false,
        vip_id: '',
        vip_name: '',
        last_operator: '',
        search: () => {
          searchHandle.loading = true
          getMemberList()
        },
        reset: () => {
          searchHandle.loading = true
          searchHandle.vip_id = ''
          searchHandle.vip_name = ''
          searchHandle.last_operator = ''
          getMemberList()
        }
      })

      const newPermissionHandle = reactive({
        newMemberPermission: () => {
          permissionDialogHandle.visible = true
        }
      })

      const tableHandle = reactive({
        list: [],
        loading: false,
        configDetail: (row: any) => {
          permissionDialogHandle.id = row.aid
          permissionDialogHandle.visible = true
        },
        deleteType: (row: any) => {
          const params = {
            id: row.aid
          }
          permissionService.deleteVipType(params).then(() => {
            getMemberList()
          })
        },
        start: (row: any) => {
          permissionService.updateVipType({
            vip_item_type: {...row, status: 1}
          }).then(() => {
            getMemberList()
          })
        },
        forbid: (row: any) => {
          permissionService.updateVipType({
            vip_item_type: {...row, status: -1}
          }).then(() => {
            getMemberList()
          })
        }
      })

      // 分页句柄
      const paginationHandle = reactive({
        total: 0,
        page: 1,
        size: ConstPagination.defaultPageSize,
        handleSizeChange: (page: number) => {
          paginationHandle.size = page
          getMemberList()
        },
        handleCurrentChange: (page: number) => {
          paginationHandle.page = page
          getMemberList()
        }
      })

      const permissionDialogHandle = reactive({
        visible: false,
        id: null,
        clickPermissionDialogClose() {
          permissionDialogHandle.visible = false
          permissionDialogHandle.id = null
        },
        clickPermissionDialogSubmit() {
          permissionDialogHandle.visible = false
          permissionDialogHandle.id = null
          getMemberList()
        }
      })

      onMounted(() => { getMemberList() })

      return {
        searchHandle,
        tableHandle,
        paginationHandle,
        permissionDialogHandle,
        newPermissionHandle,

        formatDate,
        CheckStatusByLabelMap
      }
    }
  })
