
import { ActivityTemplateTypeMap } from '@/common/const'
import wxAppletsTopic from './wxAppletsTopic/wxAppletsTopic.vue'
import webTopic from './webTopic/webTopic.vue'
import webPay from './webPay/webPay.vue'
import { defineComponent, PropType, computed } from 'vue'
import { Activity } from '@/views/marketing/topic_activity/topic_activity.service'

export default defineComponent({
  props: {
    activity: {
      type: Object as PropType<Activity>,
      default: () => ({})
    },
  },
  setup (props) {
    const activity = props.activity as Activity

    const componentTopic = computed(() => {
      const component = {
        [ActivityTemplateTypeMap.webTopic]: webTopic,
        [ActivityTemplateTypeMap.wxAppletsTopic]: wxAppletsTopic,
        [ActivityTemplateTypeMap.webPay]: webPay
      }
      return component[activity.template]
    })

    return {
      componentTopic
    }
  }
})
