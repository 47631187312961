
import imgUploader from '@/components/uploader/imgUploader/index.vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { defineComponent, reactive, ref, PropType } from 'vue'
import { Activity } from '@/views/marketing/topic_activity/topic_activity.service'
import { WebPayService, ResponseGetWebPay } from './webPay.service'
import { UploadReturnInfo } from '@/components/uploader/uploader.service'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'WebPay',
  components: { imgUploader },

  props: {
    activity: {
      type: Object as PropType<Activity>,
      default: () => ({})
    },
  },

  emits: ['close', 'sumbit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([WebPayService])
    const packsaleService = injector.get(WebPayService)
    const isEdit = ref(false)
    // vue3 ts类型PropType类型会丢失，需要重新给props.activity赋予类型
    const activity = props.activity as Activity

    packsaleService.getWebPay({ activity_id: activity.id }).then((res: ResponseGetWebPay) => {
      const packsale = res.data
      if (packsale?.id) {
        isEdit.value = true
        dialogHandle.formData = packsale
      }
    })

    const formRef = ref(null)

    const rules = reactive({
      banner_img: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      name: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
      package_id: [
        {
          required: true,
          message: '必选',
          trigger: 'blur',
        },
      ],
    })

    // 表单句柄，用于控制表单输入
    const dialogHandle = reactive({
      loading: false,
      disable: false,
      formData: {
        id: 0,
        activity_id: activity.id,
        banner_img: '',
        package_id: 0
      },
      reset: () => {
        dialogHandle.formData = {
          id: 0,
          activity_id: 0,
          banner_img: '',
          package_id: 0,
        }
      },
      close: () => {
        ctx.emit('close')
      },
      submit: () => {
        formRef.value?.validate(async (valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              await packsaleService.updateWebPay(dialogHandle.formData)
            } else {
              await packsaleService.addWebPay(dialogHandle.formData)
            }
            ElMessage.success('保存成功')
            ctx.emit('sumbit')
          }
        })
      },
      handleSmallFileInfo: (info: UploadReturnInfo) => {
        dialogHandle.formData.banner_img = info.name
      },
    })

    return {
      isEdit,
      formRef,
      dialogHandle,
      rules
    }
  }
})
