import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common_img_uploader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uploader = _resolveComponent("uploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_uploader, {
      "default-img-list": _ctx.defaultImgList,
      "file-data": _ctx.fileData,
      "img-type": _ctx.imgType,
      onFileInfo: _ctx.fileInfo
    }, null, 8, ["default-img-list", "file-data", "img-type", "onFileInfo"])
  ]))
}