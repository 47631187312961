import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/marketing',
    component: () => import(/* webpackChunkName: "marketing" */ '../../views/marketing/marketing.vue'),
    children: [
      {
        path: 'advertisement',
        name: 'advertisement',
        component: () => import(/* webpackChunkName: "advertisement" */ '../../views/marketing/advertisement/advertisement.vue')
      },
      {
        path: 'topic_activity',
        name: 'topic_activity',
        component: () => import(/* webpackChunkName: "topic_activity" */ '../../views/marketing/topic_activity/topic_activity.vue')
      }
    ]
  }
]

export default routes
