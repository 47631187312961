<template>
  <div class="layout_table">
    <div class="header"><slot name="header" /></div>
    <div class="main"><slot name="main" /></div>
    <div class="footer"><slot name="footer" /></div>
  </div>
</template>

<style lang="scss" scoped>
  .layout_table { height: 100%; box-sizing: border-box; display: flex; flex-direction: column;
    .header { padding: 20px; }
    .main { flex: 1; padding: 0 10px 0 20px; height: 400px; }
    .footer { padding: 20px; text-align: right; }
  }
</style>
