import { Http } from '@zhfe/common-library';
import { Injectable } from '@tanbo/vue-di-plugin';

export interface RequestListParams {
  /** 页码 */
  page?: number
  page_size?: number
  /** 名称 */
  scene_name?: string
  /** 二维码内容 */
  scene_url?: string
}

export interface ReplyContent {
  /** 素材id 回复类型为图片时使用 */
  media_id?: string
  /** 小程序appid */
  miniprogram_appid?: string
  /** 小程序路径 */
  miniprogram_pagepath?: string
  /** 小程序图片素材id */
  miniprogram_thumb_media_id?: string
  /** 小程序标题 */
  miniprogram_title?: string
  /** 文本 回复类型为文本时使用 */
  text?: string
}

export interface RequestItemParams {
  /** QR_STR_SCENE 临时二维码 QR_LIMIT_STR_SCENE 永久二维码 */
  scene_type: string
  /** 二维码名称 */
  scene_name: string
  /** 有效时间  最大不超过2592000（即30天） */
  expire_seconds: number
  /** 1默认回复 2文本 3图片 4小程序卡片  */
  reply_type: number
  /** 1开启2关闭 */
  status: number
  reply_content?: ReplyContent
}
export interface List {
  /** id */
  id?: number,
  /** 有效时间  最大不超过2592000（即30天） */
  expire_seconds?: number
  /** 响应内容 */
  reply_content?: ReplyContent
  /** 1默认回复 2文本 3图片 4小程序卡片  */
  reply_type?: number
  /** 二维码名称 */
  scene_name?: string
  /** QR_STR_SCENE 临时二维码 QR_LIMIT_STR_SCENE 永久二维码 */
  scene_type?: string
  /** 二维码内容 */
  scene_url?: string
  /** 二维码ticket */
  ticket?: string
  create_time?: number,
  update_time?: number,
  status: number
}

export interface RequestSceneParams {
  id: number
  /** 二维码名称 */
  scene_name: string
  /** 1默认回复 2文本 3图片 4小程序卡片  */
  reply_type: number
  reply_content?: ReplyContent
}

export interface ResponseData {
  list?: List[]
  total?: number
}

@Injectable()
export class QrcodeService {
  constructor(private http: Http) {}

  getQrcodeList(params: RequestListParams) {
    return this.http.post<ResponseData>('/wxmp/scene/list', params)
  }

  getWxmpSceneCreate (params: RequestItemParams) {
    return this.http.post<ResponseData>('/wxmp/scene/create', params)
  }

  getWxmpSceneGet (params: {id: number}) {
    return this.http.post<ResponseData>('/wxmp/scene/get', params)
  }

  updateWxmpScene (params: RequestSceneParams) {
    return this.http.post<void>('/wxmp/scene/update', params)
  }
}
