
import { defineComponent, reactive } from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import {
  ConfigService,
  ClassifyLableValue,
  MatConfig
} from './sort-order.service'

const log = console.log.bind(console)

export default defineComponent({
  components: {
    MainLayout,
    TableLayout
  },
  setup() {
    const injector = useReflectiveInjector([ConfigService])
    const configService = injector.get(ConfigService)

    // 获取路由相关id
    const route = useRoute()
    const router = useRouter()
    const product = route.query.product as string
    const id = route.query.custom_mat_sort_id ? Number(route.query.custom_mat_sort_id) : 0
    const id2 = route.query.id ? Number(route.query.id) : 0

    const catSortOrderHandle = {
      classifyList: [] as ClassifyLableValue[],
      matSortToMatList(mat_sort: string): MatConfig[]  {
        // 处理为对象
        const defaultObj = {
          '': ''
        }
        let o = {}
        try {
          o = JSON.parse(mat_sort)
        } catch {
          o = defaultObj
        }
        if (Object.keys(o).length === 0) {
          o = defaultObj
        }

        // 处理为方便处理和操作的结构
        return Object.keys(o)
                .map<MatConfig>((key: string) => (
                  {
                    order: key,
                    matId: o[key],
                    title: ''
                  }
                ))
      },
      matListToMatSort(matList: MatConfig[]): string {
        const o = {}
        matList.forEach((item: MatConfig) => {
          o[item.order] = item.matId
        })
        return JSON.stringify(o)
      },
      getSortList() {
        const req = {
            custom_mat_sort_id: id
        }
        return configService.queryCatSortList(req)
          .then((res) => {
            return res.list.map(item => {
              const o = {
                ...item,
                matList: catSortOrderHandle.matSortToMatList(item.mat_sort),
                isEidt: false
              }
              log('o:', o)
              return o
            })
        })
      },
      getSortOrder() {
        return catSortOrderHandle.getSortList()
          .then((list) => {
            return list.find(e => e.id === Number(id2))
          })
      },
      updateMatConfigTitle(matConfig: MatConfig) {
        const id = Number(matConfig.matId)
        configService.getMatTitleById(id).then(title => {
          matConfig.title = title
        })
      },
      loadClassifyList() {
        return configService.getAllClassifyList().then(list => {
          catSortOrderHandle.classifyList = list.map<ClassifyLableValue>(e => {
              return {
                label: `${e.label} id:${e.value}`,
                value: e.value,
              }
          })
        })
      },
      getCatName(catId: Number) {
        const o = catSortOrderHandle.classifyList.find(item => item.value === catId)
        if (o) {
          return o.label
        }
        return ''
      }
    }

    const searchHandle = reactive({
      isSearched: false,
      id: '',
      name: '',
      searchResultList: [] as MatConfig[],
      search: () => {
        const l = tableHandle.orderConfig.matList.filter(item => {
          const isSameId = item.matId === searchHandle.id
          const isIncludesCat = searchHandle.name && item.title.includes(searchHandle.name)
          return isSameId || isIncludesCat
        })
        searchHandle.searchResultList = l
        searchHandle.isSearched = true
      },
      reset: () => {
        searchHandle.isSearched = false
        searchHandle.searchResultList = tableHandle.orderConfig.matList
      },
      refresh: () => {
        ElMessageBox.alert('正在刷新排序中，5分钟后计算完成，请稍后')
        configService.cdnAndCacheRefresh({
          product,
          classId: String(tableHandle.orderConfig.cat)
        })
      }
    })

    const tableHandle = reactive({
      loading: false,
      orderConfig: {
        cat: 0,
        matList: [],
        mat_sort: '{}'
      },
      title: '',
      getIndexByConfig(matConfig: MatConfig) {
        return tableHandle.orderConfig.matList.findIndex(e => e.order === matConfig.order)
      },
      clickBack() {
        router.back()
      },
      clickSave() {
        // 避免重复数据
        tableHandle.sort()
        for (let i = 0; i < tableHandle.orderConfig.matList.length; i++) {
          const cur = tableHandle.orderConfig.matList[i]
          const next = tableHandle.orderConfig.matList[i + 1]
          if (cur && next) {
            if (cur.order === next.order) {
              ElMessageBox.alert(`请不要使用重复的排序位置：${cur.order}`)
              return
            }
            if (cur.matId === next.matId) {
              ElMessageBox.alert(`请不要使用重复的模板id：${cur.matId}`)
              return
            }
          }
        }

        // 避免空白数据
        const hasBlankMatId = tableHandle.orderConfig.matList
                                .some((mat: MatConfig) => mat.matId === '' || mat.order === '')
        if (hasBlankMatId) {
           ElMessageBox.alert('请不要保存空白数据')
           return
        }

        ElMessageBox.confirm('是否确认保存', '提示', {
          confirmButtonText: '确认保存',
          cancelButtonText: '暂不保存',
          type: 'info',
        })
          .then(() => {
            tableHandle.updateMatSort()
            configService.updateClassifySort(tableHandle.orderConfig).then(() => {
              ElMessage.success('保存成功')
            })
          })
      },
      updateMatSort: () => {
        tableHandle.orderConfig.mat_sort = catSortOrderHandle.matListToMatSort(tableHandle.orderConfig.matList)
      },
      sort: () => {
        const l = tableHandle.orderConfig.matList
        tableHandle.orderConfig.matList = l.sort((a, b) => Number(a.order) - Number(b.order))
      },
      pushMat(matIndex: number) {
        if (searchHandle.isSearched) {
          ElMessageBox.alert('请重置搜索后，再进行新增位置，以防数据混乱')
        } else {
          tableHandle.orderConfig.matList.splice(
            matIndex + 1,
            0,
            {
              order: '',
              matId: ''
            }
          )
        }
      },
      toTop(matConfig: MatConfig) {
        const index = tableHandle.getIndexByConfig(matConfig)
        const l = tableHandle.orderConfig.matList
        const item = l[index]
        item.order = '1'
        l.splice(index, 1)
        l.unshift(item)
        tableHandle.cleanAfterToTop()
      },
      cleanAfterToTop() {
        const l = tableHandle.orderConfig.matList
        l.forEach((cur, index) => {
          const next = l[index + 1]
          if (next) {
            cur.order == next.order
            next.order = String(Number(next.order) + 1)
          }
        })
      },
      moveUp(matConfig: MatConfig) {
        const index = tableHandle.getIndexByConfig(matConfig)
        const l = tableHandle.orderConfig.matList
        const cur = l[index]
        const pre = l[index - 1]
        if (pre) {
          cur.order = String(Number(cur.order) - 1)
          tableHandle.cleanAfterMoveUp()
        }
      },
      cleanAfterMoveUp() {
        const l = tableHandle.orderConfig.matList
        for (let i = l.length - 1; i >= 0; i--) {
          const cur = l[i]
          const pre = l[i - 1]
          if (pre) {
            if (pre.order == cur.order) {
              pre.order = String(Number(pre.order) + 1)
              l[i] = pre
              l[i - 1] = cur
            }
          }
        }
      },
      moveDown(matConfig: MatConfig) {
        const index = tableHandle.getIndexByConfig(matConfig)
        const l = tableHandle.orderConfig.matList
        const cur = l[index]
        const next = l[index + 1]
        if (next) {
          cur.order = String(Number(cur.order) + 1)
          tableHandle.cleanAfterMoveDown()
        }
      },
      cleanAfterMoveDown() {
        const l = tableHandle.orderConfig.matList
        for (let i = 0; i < l.length; i++) {
          const cur = l[i]
          const next = l[i + 1]
          if (next) {
            if (next.order == cur.order) {
              next.order = String(Number(next.order) - 1)

              l[i] = next
              l[i + 1] = cur
            }
          }
        }
      },
      delete(matConfig: MatConfig) {
        ElMessageBox.confirm('是否确认删除？', '提示', {
          confirmButtonText: '确认删除',
          cancelButtonText: '暂不删除',
          type: 'warning',
        })
          .then(() => {
            const index = tableHandle.getIndexByConfig(matConfig)
            tableHandle.orderConfig.matList.splice(index, 1)
            ElMessageBox.alert('删除成功，需点击下方保存才完成生效，可刷新页面撤销删除')
          })
      },
      loadCatSortOrderList() {
        tableHandle.loading = true
        return catSortOrderHandle.getSortOrder()
          .then(config => {
            tableHandle.orderConfig = config
            tableHandle.loading = false
          })
          .then(() => {
            tableHandle.orderConfig.matList.forEach(e => catSortOrderHandle.updateMatConfigTitle(e))
          })
          .then(() => {
            tableHandle.sort()
          })
      }
    })

    tableHandle.loadCatSortOrderList()
          .then(() => {
            return catSortOrderHandle.loadClassifyList()
          })
          .then(() => {
            tableHandle.title = catSortOrderHandle.getCatName(tableHandle.orderConfig.cat)
          })

    return {
      catSortOrderHandle,
      tableHandle,
      searchHandle
    }
  }
})
