import { createApp } from 'vue'
import { Provider, reflectiveInjectorPlugin } from '@tanbo/vue-di-plugin'
import { CMS_URL } from '@/common/config'

import ElementPlus from 'element-plus'

import 'element-plus/theme-chalk/index.css'
import { Menu as MenuIcon } from '@element-plus/icons-vue'
import 'jsoneditor/dist/jsoneditor.min.css';

import App from './App.vue'
import { routes } from './router'

import { createRouter, createWebHistory } from 'vue-router'
import { UserInfoByLogin, UserService } from './services/user.service'
import { Http, HttpConfig, HttpInterceptor } from '@zhfe/common-library'
import { HttpInterceptorService } from './services/http-interceptor.service'
import { ConstService } from './services/const.service'

import verifyLogin from './verifyLogin'

/** elementUI默认属性修改 */
import './elementDefaultProps'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

verifyLogin().then((info) => {
  const providers: Provider[] =  [
    Http,
    UserService,
    ConstService,
    {
      provide: UserInfoByLogin,
      useValue: info
    },
    {
      provide: HttpConfig,
      useValue: {
        baseURL: CMS_URL
      }
    },
    {
      provide: HttpInterceptor,
      useClass: HttpInterceptorService
    }
  ]

  createApp(App)
    .use(reflectiveInjectorPlugin, providers)
    .use(router)
    .use(ElementPlus)
    .component('icon-menu', MenuIcon)
    .mount('#app')
})
