import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export enum DefaultType {
  is_no_default = 0,
  is_default = 1
}

export enum StatusType {
  open = 1,
  close = -1
}

export interface AddSortParams {
  /** 名称 */
  name: string
  /** 是否默认 0非默认1默认 */
  is_default: DefaultType
  /** 投放产品 */
  product_code: string
  /** 状态 -1 禁用 1 启用 */
  status: StatusType
}

export interface UpdateSortParams {
  /** 唯一id */
  id?: string
  /** 名称 */
  name?: string
  /** 是否默认 0非默认1默认 */
  is_default?: DefaultType
  /** 投放产品 */
  product_code?: string
  /** 状态 -1 禁用 1 启用 */
  status: StatusType
}

@Injectable()
export class NewSortItemDialogService {
  constructor(private http: Http) {}

  /** 添加排序列表 */
  addSortMain (params: AddSortParams) {
    return this.http.post('/searchtop/add_search_top', params)
  }

  /** 更新排序列表 */
  updateSortMain (params: UpdateSortParams) {
    return this.http.post('/searchtop/update_search_top', params)
  }
}
