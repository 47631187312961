import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface ClassifyList {
  /** 分类id */
  classify_id: number
  /** 分类名称	*/
  classify_name: string
  /** 分类等级	*/
  classify_level: number
  /** 1级分类名称 */
  cat1_name: string
  /** 2级分类名称 */
  cat2_name: string
  /** 0 禁用 1启用 */
  status: number
  /** 排序值	*/
  order_num: number
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string
}

export interface ClassifySearchRequestParams {
  /**	起始页数 */
  page: number
  /** 页容量	*/
  page_size: number
  /** 分类id,搜索筛选项 */
  classify_id?: number
  /** 分类名,搜索筛选项 */
  classify_name?: string
  /** 上下线状态，0 禁用 1启用,搜索筛选项	*/
  status?: number
  /** 父级分类，用于查询一二级分类 */
  classify_parent_id?: number
}

export interface ClassifyAddRequestParams {
  /** 分类名,搜索筛选项 */
  classify_name?: string
  /** 所属一级分类值 */
  cat1?: number
  /** 所属二级分类值 */
  cat2?: number
  /** 排序数字	*/
  order_num?: number
}

export interface ClassifySearchResponseData {
  list: ClassifyList[],
  total: number
}

export interface ClassifyUpdateRequestParams {
  /** 分类id */
  classify_id?: number
  /** 分类名称 */
  classify_name?: string
  /** 上下线状态，0 禁用 1启用 */
  status?: number
  /** 一级分类值 */
  cat1?: number
  /** 二级分类值 */
  cat2?: number
  /** 排序数字	*/
  order_num?: number
}

@Injectable()
export class ClassifyItemDialogService {
  constructor(private http: Http) {}

  getClassifyList(params: ClassifySearchRequestParams) {
    return this.http.post<ClassifySearchResponseData>('/classify/search', params).then((response: ClassifySearchResponseData) => {
      return { list: response.list }
    })
  }

  addClassifyItem(params: ClassifyAddRequestParams) {
    return this.http.post('/classify/add', params)
  }

  updateClassify(params: ClassifyUpdateRequestParams) {
    return this.http.post('/classify/update', params)
  }
}
