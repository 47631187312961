
import { defineComponent, onMounted, reactive, ref } from 'vue'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import TableLayout from '@/components/layout/tableLayout/tableLayout.vue'

import Editor from '@/components/editor/index.vue'

import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { ContingencyService, BaseData } from './contingency.service';
import { ConstPagination } from '@/common/const';
import { formatDate } from '@shared/utils/base/index'
import { ElMessage } from 'element-plus'

export default defineComponent({
  components: { MainLayout, TableLayout, Editor },

  setup() {
    const injector = useReflectiveInjector([ContingencyService])
    const contingencyService = injector.get(ContingencyService)

    function getList() {
      tableHandle.loading = true
      contingencyService.getList({page_size: paginationHandle.page_size, page: paginationHandle.page}).then(res => {
        tableHandle.list = res.list || []
        paginationHandle.total = res.total || 0
      }).finally(() => {
        tableHandle.loading = false
      })
    }

    onMounted(() => { getList() })

    const headerHandle = reactive({
      loading: false,
      newHandle: () => {
        dialogHandle.isEdit = false
        dialogHandle.formData = Object.assign({}, dialogHandle.defaultFomData) as BaseData
        dialogHandle.editorJson = {}
        dialogHandle.visible = true
      }
    })

    const tableHandle = reactive({
      loading: false,
      list: [],
      clickEdit: (row: any) => {
        let jsonVal
        try {
          jsonVal = JSON.parse(row.value)
        } catch (err) {
          tipFunc('该配置value数据异常，请联系管理员')
          return
        }
        dialogHandle.isEdit = true
        dialogHandle.formData = { ...row } // 拷贝，阻止列表和弹框数据同步
        dialogHandle.editorJson = jsonVal
        dialogHandle.visible = true
      }
    })

    const paginationHandle = reactive({
      total: 0,
      page: 1,
      page_size: ConstPagination.defaultPageSize,
      handleSizeChange: (page: number) => {
        paginationHandle.page_size = page
        getList()
      },
      handleCurrentChange: (page: number) => {
        paginationHandle.page = page
        getList()
      }
    })

    const formRef = ref(null)
    const editorRef = ref(null)

    function tipFunc (msg?: string, type?: string) {
      ElMessage[type || 'warning'](msg || '请填写完整信息')
    }

    const dialogHandle = reactive({
      visible: false,
      isEdit: false,
      editorJson: {}, // 针对editor提供json
      defaultFomData: {
        key: '',
        remarks: '',
        status: -1,
        value: ''
      },
      formData: {} as BaseData,
      updateData: () => {
        const data = dialogHandle.formData
        const editorData = editorRef.value.getData()

        // 后台需要字符串
        const configValue = JSON.stringify(editorData)  // 新建时符合规范，无需三目提供默认值
        const commonData = { value: configValue, status: data.status, remarks: data.remarks }

        if (dialogHandle.isEdit) {
          contingencyService.updateConfig({ ...commonData, id: data.id }).then(() => {
            dialogHandle.visible = false

            getList()
          })
        } else {
          contingencyService.addConfig({ ...commonData,  key: data.key }).then(() => {
            dialogHandle.visible = false

            getList()
          })
        }
      },
      clickSubmit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            if (editorRef.value.validateData()) {
              dialogHandle.updateData()
            } else {
              tipFunc('请检查json是否符合规范')
            }
          } else {
            tipFunc()
            return false
          }
        })
      },
      clickCancel: () => {
        dialogHandle.formData = {} as BaseData
        dialogHandle.visible = false
      }
    })

    return {
      headerHandle,
      tableHandle,
      paginationHandle,
      dialogHandle,

      formatDate,

      formRef,
      editorRef
    }
  },
})
