import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface AdminClassifySearchRequestParams {
  /**	起始页数 */
  page: number
  /** 页容量	*/
  page_size: number
  /** 分类id,搜索筛选项 */
  classify_id?: number
  /** 分类名,搜索筛选项 */
  classify_name?: string
  /** 上下线状态，0 禁用 1启用,搜索筛选项	*/
  status?: number
  /** 父级分类，用于查询一二级分类 */
  classify_parent_id?: number
}

export interface AdminClassifyList {
  /** 分类id */
  classify_id: number
  /** 分类名称	*/
  classify_name: string
  /** 分类等级	*/
  classify_level: number
  /** 1级分类名称 */
  cat1_name: string
  /** 2级分类名称 */
  cat2_name: string
  /** 0 禁用 1启用 */
  status: number
  /** 排序值	*/
  order_num: number
  /** 创建时间	*/
  create_time: string
  /** 更新时间	*/
  update_time: string
}

export interface AdminClassifyListResponseData {
  list: AdminClassifyList[],
  total: number
}

export interface ClassifyAddRequestParams {
  /** 分类id，复用管理分类id */
  class_id?: number
  /** 层级 */
  level?: number
  /** 管理分类id */
  mat_class_id_list?: number[]
  /** 名称 */
  name?: string
  /** 排序号 */
  order_num?: number
  /** 上级展示分类id */
  pid?: number
  /** 产品id 1：web端 2：客户端 3：侧边栏 4：猎豹浏览器 5：可牛pdf 6：小程序端 */
  product_id?: number
}

export interface ClassifyUpdateRequestParams {
  /** 分类id，复用管理分类id */
  class_id?: number
  /** 展示分类id */
  id?: number
  /** 层级 */
  level?: number
  mat_class_id_list?: number[]
  /** 名称 */
  name?: string
  /** 排序号 */
  order_num?: number
  /** 上级展示分类id */
  pid?: number
  /** 产品id 1：web 2：duba 3：ppt 4：liebao 5：pdf */
  product_id?: number
  /** 状态 -1 禁用 1启用 */
  status?: number
}

export interface MapIdList {
  /** 关联id */
  id: number
  /** 展示分类id */
  mat_show_class_id: number
  /** 管理分类id */
  mat_class_id: number
  /** 创建时间 */
  create_time: string
  /** 更新时间 */
  update_time: string
}
export interface MapIdListResponseData {
  list: MapIdList[]
}

@Injectable()
export class ClassifyItemDialogService {
  constructor(private http: Http) {}

  addClassifyItem (params: ClassifyAddRequestParams) {
    return this.http.post('/matshowclass/add', params)
  }

  updateClassify (params: ClassifyUpdateRequestParams) {
    return this.http.post('/matshowclass/update', params)
  }

  getMapIdByShowId(id: number) {
    return this.http.post<MapIdListResponseData>('/matclassrelation/get', { mat_show_class_id: id }).then((res: MapIdListResponseData) => {
      return res.list.map(item => item.mat_class_id)
    })
  }

  getAdminClassifyList(params: AdminClassifySearchRequestParams) {
    return this.http.post<AdminClassifyListResponseData>('/classify/search', params).then((response: AdminClassifyListResponseData) => {
      return response.list
    })
  }
}
