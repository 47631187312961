import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { RANK_URL } from '@/common/config'

export interface SearchSortListParams {
  /** 唯一标示 **/
  id?: number
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码 */
  product_code?: string
  /** 状态码 -1禁用1启用 */
  status?: number
}

export interface UpdateSortParams {
  /** id */
  id?: number
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码	*/
  product_code?: string
  /** 状态码 -1禁用1启用 */
  statue?: number
}

export interface SortDeleteParams {
  id?: number
}

export interface SortItem {
  /** ID */
  id?: number
  /** 名称 */
  name?: string
  /** 优先级	*/
  priority?: number
  /** 产品代码 */
  product_code?: string
  /** 状态码 -1禁用1启用 */
  status?: number
  /** 创建时间	*/
  create_time?: string
  /** 最后更新时间	*/
  update_time?: string
}

export interface SortListResponseData {
  list: SortItem[],
  total: number
}

export interface QuerySortParams {
  /** 分类排序唯一标识	*/
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number
}

export interface CatMatSortItem {
  /** 分类排序唯一标识 */
  id?: number
  /** 排序列表标识 */
  custom_mat_sort_id?: number
  /** 分类标识 */
  cat?: number | string
  /** 分类列表 */
  mat_sort?: string
  /** 创建时间 */
  create_time?: string
  /** 更新时间	*/
  update_time?: string

  // 以下是前端自定义字段
  isEdit?: boolean
  isNew?: boolean
}

export interface CatSortList {
  list: CatMatSortItem[]
  total: number
}

/** 排序状态 */
export const CheckStatusMap = {
  start: 1,
  forbid: -1
}

/** 排序状态对应的中文名 */
export const CheckStatusByLabelMap = {
  [CheckStatusMap.start]: '启用',
  [CheckStatusMap.forbid]: '禁用'
}

/** 渠道名称 */
export const ProductCodeMap = {
  web: 'web端',
  duba: '客户端',
  ppt: '侧边栏'
}

@Injectable()
export class SortService {
  constructor(private http: Http) {}

  /** 获取排序列表 */
  getSortItem(params: SearchSortListParams) {
    return this.http.post<SortListResponseData>('/sort_main/list', params).then((response: SortListResponseData) => {
      return { list: response.list, total: response.total }
    })
  }

  /** 更新排序列表 */
  updateSortItem (params: UpdateSortParams) {
    return this.http.post('/sort_main/update', params)
  }

  /** 删除排序列表 */
  deleteSortItem(params: SortDeleteParams) {
    return this.http.post('/sort_main/delete', params)
  }

  /** 执行素材排序任务 */
  rankingMat () {
    return this.http.post(`${RANK_URL}/mat/ranking`, {})
  }

  /** 执行素材收集排序任务 */
  rankingMatCalculate () {
    return this.http.post(`${RANK_URL}/mat/ranking/calculate`, {})
  }

  queryCatSortList (params: QuerySortParams) {
    return this.http.post<CatSortList>('/cat_mat_sort/list', params).then((response: CatSortList) => {
      return { list: response.list }
    })
  }

  cdnAndCacheRefresh ({ product, classId }: { product: string, classId: string }) {
    return this.http.get(`${RANK_URL}/mat/ranking/one?product=${product}&class=${classId}`, {})
  }
}
