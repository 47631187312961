import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

/** 可用状态 0-全部，1-启用，-1-禁用 */
export enum StatusActivityMap {
  all = 0,
  close = -1,
  open = 1,
}

export interface RequestParamsDelete {
  id?: number
}

export interface RequestParamsList {
  /** 产品形态：web, clent, ... */
  client_type?: string
  /** 活动名称 */
  name?: string
  /** 页码 */
  page?: number
  /** 每页条数，最大100 */
  page_size?: number
  /** 状态：0-全部，1-启用，-1-禁用 */
  status?: StatusActivityMap
  /** 活动模板：前端定义 */
  template?: string
}

export interface Activity {
  id: number
  /** 活动名称 */
  name: string
  /** 产品形态 */
  client_type: string
  /** 活动标识 */
  code: string
  /** 活动模板 */
  template: string
  /** 开始时间，格式：2022-02-10 11:11:34 */
  start_time: string
  /** 结束时间，格式：2022-02-10 11:11:34 */
  end_time: string
  /** 状态：-1-禁用，1-可用 */
  status: StatusActivityMap
  /** 创建时间，格式：2022-02-10 11:11:34 */
  create_time: string
  /** 最后更新时间 */
  update_time: string
}

export interface ResponseDataList {
  /** 总条数 */
  total: number
  list: Activity[]
}

/** 活动状态对应中文名称 */
export const StatusActivityLabelMap = {
  [StatusActivityMap.all]: '',
  [StatusActivityMap.open]: '开启',
  [StatusActivityMap.close]: '关闭'
}

@Injectable()
export class TopicActivity {
  constructor (private http: Http) {}

  getActivityListMock (data: RequestParamsList) {
    const item1 = {
      id: 1,
      /** 活动名称 */
      name: '活动名称',
      /** 产品形态 */
      client_type: '小程序',
      /** 活动标识 */
      code: '活动标识',
      /** 活动模板 */
      template: '活动模板',
      /** 开始时间，格式：2022-02-10 11:11:34 */
      start_time: '2022-02-10 11:11:34',
      /** 结束时间，格式：2022-02-10 11:11:34 */
      end_time: '2022-02-10 11:11:34',
      /** 状态：-1-禁用，1-可用 */
      status: StatusActivityMap.close,
      /** 创建时间，格式：2022-02-10 11:11:34 */
      create_time: '2022-02-10 11:11:34',
      /** 最后更新时间 */
      update_time: '2022-02-10 11:12:00'
    }
    const list = [
      item1
    ]
    const total = 1
    return Promise.resolve<ResponseDataList>({ list, total })
  }

  getActivityList (data: RequestParamsList) {
    return this.http.post<ResponseDataList>('/activity/list', data)
  }

  deleteActivity (data: RequestParamsDelete) {
    return this.http.post<void>('/activity/delete', data)
  }
}
