/** 文件下载 */
export function downloadByUrlAndName (url: string, fileName: string) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export interface ObjectToArrayParams {
  [propName: string]: string|number
}

/** 把字典转换数组 */
export function objectToArray (data: ObjectToArrayParams) {
  return Object.keys(data).map(key => ({ value: key, text: data[key] }))
}

/**
 * 拷贝到剪切板
 * @param string 接收字符串
 * navigator.clipboard不考虑，其一是兼容问题，其二同异步不一致问题
 */
 export const copyToClipboard = (text: string) => {
  const textareDom = document.createElement('textarea')
  textareDom.style.width = '0px'
  textareDom.style.height = '0px'
  document.body.appendChild(textareDom)

  textareDom.value = text
  textareDom.select()

  document.execCommand('copy', true)
  document.body.removeChild(textareDom)
}

export const unique = function (arr: any[]) {
  const array: any[] = []
  for (let i = 0; i < arr.length; i++) {
    if (!array.includes(arr[i])) {
      array.push(arr[i])
    }
  }
  return array
}
