import { CMS_URL } from '@/common/config'

const CACHE_PREFIX = '__cmcm_cms_token__'

export default function () {
  const urlParams = new URLSearchParams(window.location.search.slice(1))
  let token = urlParams.get('token')
  const targetHref = location.origin + location.pathname

  if (token) {
    localStorage.setItem(CACHE_PREFIX, token)
  } else {
    token = localStorage.getItem(CACHE_PREFIX)
  }

  const script = document.createElement('script')
  let verifyLoginUrl = `${CMS_URL}/oa/verify?next=${encodeURIComponent(targetHref)}`
  if (token) {
    verifyLoginUrl += '&token=' + token
  }

  verifyLoginUrl += '&t=' + Number(new Date())

  script.src = verifyLoginUrl

  return new Promise(resolve => {
    script.onload = () => {
      const info = {
        uid: window._cms_uid,
        token: window._cms_token,
        username: window._cms_username
      }

      location.href.replace(/[?&]?token=[^&#]*/, '')
      resolve(info)
    }

    script.onerror = () => {
      resolve({})
    }

    document.head.appendChild(script)
  })
}
