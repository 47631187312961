
import { defineComponent } from 'vue'
import { UploadReturnInfo } from '../uploader.service'
import uploader from './uploader.vue'

export default defineComponent({
  components: { uploader },

  props: {
    showFileName: {
      type: String,
      default: ''
    },

    isShow: {
      type: Boolean,
      default: false
    }
  },

  emits: ['fileInfo'],

  setup(props, ctx) {
    function fileInfo (info: UploadReturnInfo) {
      ctx.emit('fileInfo', info)
    }

    return {
      defaultFileName: props.showFileName,
      defaultShow: props.isShow,

      fileInfo
    }
  },
})
