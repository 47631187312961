import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { BehaviorSubject } from 'rxjs';

export interface AddVipItemParams {
  /** 用户id */
  uid: number
  /** 权益id */
  vip_item_id: number
  /** 1 插入 2 删除 */
  operation_code: number
}

export interface Types {
  label: string,
  value: string
}

export interface TypeList {
  /** 权益id */
  vip_item_id: number
  /** 权益名称 */
  name: string
}

export interface ResponseTypesData {
  /** map 接口 key:value */
  data: {
    list: TypeList[],
    total: number
  }
}

@Injectable()
export class NewVipItemDialogService {
  typesData = new BehaviorSubject<Array<Types>>([])

  constructor(private http: Http) {
    this.updateTypes()
  }

  private updateTypes() {
    this.http.post<ResponseTypesData>('/vip/package/search', {page_params: {page: 1, page_size: 1000}}).then(response => {
      const types = response.data.list.map(type => {
        return { label: `${type.vip_item_id + '(' + type.name + ')'}`, value: String(type.vip_item_id) }
      })
      this.typesData.next(types)
    })
  }

  addVipItem (params: AddVipItemParams) {
    return this.http.post('/vip/item/add', params)
  }
}
