import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'
import { frontScope } from './di-scope'

export interface TreeListItem {
  /** 展示分类id */
  id: number
  /** 管理分类id */
  class_id: number
  /** 产品id */
  product_id: number
  /** 上级展示分类id */
  pid: number
  /** 名称 */
  name: string
  /** 层级 */
  level: number
  /** 排序号 */
  order_num: number
  /** 状态-1禁用1启用 */
  status: number
  /** 下级列表 */
  children: TreeListItem[] | null,
  /** 创建时间 */
  create_time: string
  /** 更新时间 */
  update_time: string
}

export interface TreeListResponseData {
  list: TreeListItem[]
}

export interface CacheData {
  [propName: number]: TreeListItem[]
}

@Injectable({
  provideIn: frontScope
})
export class FrontMessageService {
  private cacheData: CacheData = {}
  private currentTab: string
  currentTreeList = new BehaviorSubject<TreeListItem[]>([])
  constructor(private http: Http) {}

  get getCurrentTreeList () {
    return this.cacheData[this.currentTab]
  }

  initCurrentTabTreeData() {
    return this.http.post<TreeListResponseData>('/matshowclass/tree', { product_id: Number(this.currentTab) }).then((res: TreeListResponseData) => {
      this.cacheData[this.currentTab] = res.list || []
      this.currentTreeList.next(res.list || [])
    })
  }

  setCurrentTab (tab: string) {
    this.currentTab = tab
    this.currentTreeList.next(this.cacheData[tab] || [])
  }

  findSiblingDataById (id: number, sources = this.getCurrentTreeList): TreeListItem[] {
    if (id === 0) return sources

    for (let i = 0; i < sources.length; i++) {
      const item = sources[i];
      if (item.id === id) {
        return sources
      }

      if (item.children && item.children.length) {
        const data = this.findSiblingDataById(id, item.children)
        if (data) return data
      }
    }
  }
}
