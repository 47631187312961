<template>
  <section>
    <router-view :key="$route.fullPath"/>
  </section>
</template>

<script>
</script>

<style scoped>

</style>
