import { isDevOrTest } from '@/common/env';
import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface OfficeplusCategoryItem {
  /** 分类ID */
  id: number;
  /** 分类名称 */
  name: string;
  /** 唯一标识符 */
  guid: string;
  /** 描述 */
  description: string;
  /** 优先级/排序 */
  priority: number;
  /** 是否有子分类 */
  hasChildren: boolean;
}

export interface OfficeplusTagItem {
  /** 分类ID */
  id: number;
  /** 分类名称 */
  name: string;
  /** 唯一标识符 */
  children: OfficeplusTagItem[];
}

@Injectable()
export class OfficeplusHttpService {
  host = 'https://creatorcenter.officeplus.cn'
  constructor(private http: Http) {
    this.http = new Http({ baseURL: this.host}, { response: this.response })
  }

  response (response: AxiosResponse) {
    if (response.status === 200) {
      return response.data
    }
  }

  getCategoryByParentId (parentId: number) {
    return this.http.get<OfficeplusCategoryItem[]>(`/api/template/v1/categories?parentId=${parentId}`)
  }

  getTagsByCategoryId (categoryId: number) {
    return this.http.get<OfficeplusTagItem[]>(`/api/template/v1/categories/${categoryId}/tags`)
  }
}
