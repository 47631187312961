import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';

export interface OrderRequestParams {
  /** 搜索订单id */
  s_order_id?: string
  /** 搜索uid */
  s_uid?: number
  /** 0全部，1未支付，2已支付，3已退款 */
  s_order_status?: number
  /** 页码 */
  page?: number
  /** 每页条数	*/
  page_size?: number
}

export interface updateRefundParams {
  /** 订单id */
  order_id: number
}

export interface orderDetailParams {
  /** 订单id */
  order_id: number
}

export interface OrderList {
  /** 订单id */
  order_id?: string
  /** 订单摘要	*/
  summary?: string
  /** 订单金额 */
  money?: string
  /** 用户id */
  uid?: string
  /** 订单状态：0未支付、1已支付、2已退款 */
  status?: string
  /** 创建时间	*/
  create_time?: string
  /** 最后更新时间	*/
  update_time?: string
}

export interface OrderDetail {
  /** 订单id */
  order_id: string
  /** 订单摘要(套餐名称) */
  summary: string
  /** 订单金额	*/
  money: number
  /** 用户id */
  uid: string
  /** 订单状态：0未支付、1已支付、2已退款 */
  status: number
  /** 来源渠道	*/
  source?: string
  /** 支付方式：1-微信支付，2-支付宝 */
  payment_type?: string
  /** 支付用的商户号 */
  pay_app?: string
  /** 权益id：套餐id或模板id */
  right_id?: string
  /** 创建时间	*/
  create_time: string
  /** 最后更新时间	*/
  update_time: string
}

/** 订单状态 */
export enum CheckStatusMap {
  not_paid = 0,
  has_paid = 1,
  has_refund = 2
}

/** 订单状态对应的中文名 */
export const CheckStatusByLabelMap = {
  [CheckStatusMap.not_paid]: '未支付',
  [CheckStatusMap.has_paid]: '已支付',
  [CheckStatusMap.has_refund]: '已退款'
}

/** 支付方式 */
export enum CheckPayMap {
  we_chat_pay = 1,
  ali_pay = 2
}

/** 支付方式对应的中文名 */
export const CheckPayByLabelMap = {
  [CheckPayMap.we_chat_pay]: '微信支付',
  [CheckPayMap.ali_pay]: '支付宝'
}

export interface OrderResponseData {
  list: OrderList[],
  total: number
}

export interface DetailResponseData {
  data: OrderDetail
}

@Injectable()
export class OrderService {
  constructor(private http: Http) {}

  getOrderList(params: OrderRequestParams) {
    return this.http.post<OrderResponseData>('/order/list', params).then((response: OrderResponseData) => {
      const list = response.list

      return { list: list, total: response.total }
    })
  }

  updateRefundOrder(params: updateRefundParams) {
    return this.http.post('/order/update/refund', params)
  }

  fetchOrderDetail(params: orderDetailParams) {
    return this.http.post<DetailResponseData>('/order/detail', params).then((response: DetailResponseData) => {
      return {
        data: {
          ...response.data,
          status: CheckStatusByLabelMap[response.data.status],
          money: String(response.data.money),
          payment_type: CheckPayByLabelMap[response.data.payment_type]
        },
      }
    })
  }
}
