import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/verify',
    component: () => import(/* webpackChunkName: "verify" */ '../../views/verify/index.vue'),
    children: [
      {
        path: 'first_instance',
        name: 'first_instance',
        component: () => import(/* webpackChunkName: "verify" */ '../../views/verify/verify.vue')
      },
      {
        path: 'second_instance',
        name: 'second_instance',
        component: () => import(/* webpackChunkName: "verify" */ '../../views/verify/verify.vue')
      }
    ]
  }
]

export default routes
