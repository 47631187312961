
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import { NewVipItemDialogService, Types } from '@/views/rights/_components/newVipItemDialog/newVipItemDialog.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

export default defineComponent({
  props: {
    newVipDialog: {
      type: Boolean,
      default: false
    }
  },

  emits: ['clickNewVipDialogClose', 'clickNewVipDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([NewVipItemDialogService])
    const newVipItemDialogService = injector.get(NewVipItemDialogService)
    const formRef = ref(null)

    // 表单句柄
    const dialogHandle = reactive({
      visible: props.newVipDialog,
      loading: false,
      formData: {
        uid: '',
        vip_item_id: ''
      },
      close: () => {
        ctx.emit('clickNewVipDialogClose')
      },
      submit: () => {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            const params = {
              uid: Number(dialogHandle.formData.uid),
              vip_item_id: Number(dialogHandle.formData.vip_item_id),
              operation_code: 1
            }
            newVipItemDialogService.addVipItem(params).then(() => {
              ctx.emit('clickNewVipDialogSubmit')
            })
          }
        })
      }
    })

    const typesOptions = ref<Types[]>([])
    const typesSub = newVipItemDialogService.typesData.subscribe(type => {
      typesOptions.value = type
    })

    onUnmounted(() => typesSub.unsubscribe())

    return {
      formRef,
      dialogHandle,
      typesOptions
    }
  }
})
