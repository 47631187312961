
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { AdminClassifyList, ClassifyItemDialogService } from './classifyItemDialog.service';
import { ElMessage } from 'element-plus'
import { ClassifyAddRequestParams } from '@/views/classify/admin/_components/classifyItemDialog/classifyItemDialog.service';
import { ClassifyUpdateRequestParams } from '@/views/classify/admin/admin.service';

import type { ElForm } from 'element-plus'

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: null
    },

    pid: {
      type: Number,
      default: null
    },

    orderNum: {
      type: Number,
      default: null
    },

    level: {
      type: Number,
      default: null
    },

    productId: {
      type: Number,
      default: null
    },

    detailsData: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['clickClassifyItemDialogClose', 'clickClassifyItemDialogSubmit'],

  setup(props, ctx) {
    const injector = useReflectiveInjector([ClassifyItemDialogService])
    const classifyItemDialogService = injector.get(ClassifyItemDialogService)

    const detailsData = props.detailsData || {}
    const formRef = ref<InstanceType<typeof ElForm>>(null)
    const formRule = reactive({
      name: [
        { required: true, message: '分类名字不能为空' }
      ],
      class_id: [
        { required: true, message: '关联分类id不能为空' },
        {
          trigger: 'change',
          validator: (rule: any, value: any, callback: any) => {
            if (!/\d+/.test(value)) {
              return callback(new Error('关联分类id只能是数字'))
            }
            callback()
          }
        }
      ]
    })

    const dialogHandle = reactive({
      formData: {
        name: detailsData.name || '',
        class_id: detailsData.class_id || '',
        product_id: props.productId,
        mat_class_id_list: []
      }
    })
    const adminClassifySelectHandle = reactive({
      listData: [] as AdminClassifyList[]
    })

    const mapClassifySelectHandle = reactive({
      listData: [] as AdminClassifyList[]
    })

    const classifyTitle = computed(() => {
      if (props.id && props.disabled) {
        return '查看分类'
      }
      if (props.id) {
        return '编辑分类'
      }
      return '新建分类'
    })

    if (props.id) {
      classifyItemDialogService.getMapIdByShowId(props.id as number).then(res => {
        dialogHandle.formData.mat_class_id_list = res
      })
    }

    classifyItemDialogService.getAdminClassifyList({ page_size: 1000000, page: 1, status: 1 }).then((res: AdminClassifyList[]) => {
      adminClassifySelectHandle.listData = res
      mapClassifySelectHandle.listData = res.filter(item => item.classify_level === 3)
    })

    const handleSubmit = () => {
      formRef.value?.validate((status: boolean) => {
        if (!status) return

        const params = {
          ...dialogHandle.formData,
          order_num: props.orderNum,
          pid: props.pid || 0,
          level: props.level
        }
        params.class_id = Number(dialogHandle.formData.class_id)

        if (props.id) {
          delete params.pid
          delete params.order_num
          delete params.level
        }

        if (!props.id) {
          classifyItemDialogService.addClassifyItem(params as ClassifyAddRequestParams).then(() => {
            ElMessage.success('新建成功')
            ctx.emit('clickClassifyItemDialogSubmit')
          })
        } else {
          classifyItemDialogService.updateClassify({ ...params as ClassifyUpdateRequestParams, id: props.id as number, status: Number(props.detailsData?.status) }).then(() => {
            ElMessage.success('更新成功')
            ctx.emit('clickClassifyItemDialogSubmit')
          })
        }
      })
    }

    return {
      formRef,
      formRule,
      isShowMapIdSelect: !detailsData.children?.length,
      dialogHandle,
      classifyTitle,
      handleSubmit,
      adminClassifySelectHandle,
      mapClassifySelectHandle
    }
  }
})

