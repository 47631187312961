import { Injectable } from '@tanbo/vue-di-plugin';
import { Http } from '@zhfe/common-library';
import { PermissionService, TypeAddParams, TypeUpdateParams } from '@/views/members/permission/permission.service';

@Injectable()
export class PermissionDialogService {
  constructor(private http: Http, private permissionService: PermissionService) {}

  getDataById (id: number) {
    const typeData = this.permissionService.typeVipList.find((item) => item.aid === id)
    return {
      name: typeData.name,
      duration: String(typeData.duration),
      status: typeData.status,
      level: String(typeData.level),
      limit_num: String(typeData.limit_num),
      limit_class: typeData.limit_class,
      type: typeData.type
    }
  }

  updateVipType (params: TypeUpdateParams) {
    return this.http.post('/vip/item/type/update', params)
  }

  addVipType (params: TypeAddParams) {
    return this.http.post('/vip/item/type/add', params)
  }
}
