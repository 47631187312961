
import {defineComponent, reactive, watch} from 'vue'
import { formatDate } from '@shared/utils/base'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { VerifyLogsService } from './verifyLogs.service'
import {
  OperateResultByLabelMap,
  OperateResultMap,
  OperateTypeMap,
  OperateTypeByLabelMap
} from '@/common/const'

export default defineComponent({
  props: {
    matId: {
      type: Number,
      default: null
    }
  },

  setup (props) {
    const handle = reactive({
      logs: []
    })
    const injector = useReflectiveInjector([VerifyLogsService])
    const verifyLogsService = injector.get(VerifyLogsService)

    const getLogs = function () {
      const req = {
        mat_id: props.matId as number
      }
      verifyLogsService.getOperatelog(req).then(res => {
        handle.logs = res
      })
    }

    watch(() => props.matId, () => {
      getLogs()
    })

    getLogs()

    return {
      handle,
      formatDate,
      getStringAuditOperateType(operateType: OperateTypeMap) {
        return OperateTypeByLabelMap[operateType] || '未知'
      },
      getStringAuditOperateResult(operateResult: OperateResultMap) {
        return OperateResultByLabelMap[operateResult] || '未知'
      }
    }
  }
})
